type MailConfig = {
  btnBgColor: { [key: string]: string };
  groupHoverTextColor: { [key: string]: string };
  hoverTextColor: { [key: string]: string };
  iconGroupHover: { [key: string]: string };
  textColor: { [key: string]: string };
};

export const mailConfig: MailConfig = {
  btnBgColor: {
    att: "bg-[#0057b8]",
    none: "bg-gradient-to-r from-[#544aff] via-grape-jelly to-[#dd00ff]",
  },
  groupHoverTextColor: {
    att: "group-hover:text-[#0057b8]",
    none: "group-hover:text-grape-jelly",
  },
  hoverTextColor: {
    att: "hover:text-[#0057b8]",
    none: "hover:text-grape-jelly",
  },
  iconGroupHover: {
    att: "group-hover:fill-[#0057b8]",
    none: "group-hover:fill-grape-jelly",
  },
  textColor: {
    att: "text-[#0057b8]",
    none: "text-purple-700",
  },
};
