import React, { type FC, useRef, useEffect, useCallback } from "react";
import { type SearchAssistDropdownItem } from "./SearchAssist/types";

interface Props {
  searchSuggestions: SearchAssistDropdownItem[];
  stopSearchLabelAnimation: () => void;
}

type TimerId = ReturnType<typeof setTimeout>;

export const AnimatedSearchBar: FC<Props> = ({
  searchSuggestions,
  stopSearchLabelAnimation,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const labelRefs = useRef<HTMLDivElement[]>([]);
  const intervalIdRef = useRef<number | TimerId | null>(null);
  const timeoutIdRef = useRef<number | TimerId | null>(null);
  const searchLabelCls = `absolute flex size-full items-center bg-transparent text-left text-base font-normal text-gray-500 transition-transform duration-[0.4s] ease-[ease-out]`;

  const resetLabelPositions = useCallback(() => {
    const uhSearchLabels = labelRefs.current || [];
    uhSearchLabels.forEach((label, index) => {
      label.className = `${searchLabelCls} ${index === 0 ? "visible translate-y-0" : "invisible translate-y-full"}`;
    });
  }, [searchLabelCls]);

  const startLabelAnimation = useCallback(() => {
    let currentIndex = 0;
    intervalIdRef.current = setInterval(() => {
      if (currentIndex >= labelRefs.current.length) {
        clearInterval(intervalIdRef.current!);
        stopSearchLabelAnimation();
        return;
      }

      const currentLabel = labelRefs.current[currentIndex];
      const nextLabel =
        labelRefs.current[(currentIndex + 1) % labelRefs.current.length];

      currentLabel.className = `${searchLabelCls} -translate-y-full`;
      nextLabel.className = `${searchLabelCls} visible translate-y-0`;

      timeoutIdRef.current = setTimeout(() => {
        currentLabel.className = `${searchLabelCls} invisible translate-y-[200%]`;
        currentIndex += 1;
      }, 100);
    }, 4000);
  }, [searchLabelCls, stopSearchLabelAnimation]);

  useEffect(() => {
    resetLabelPositions();
    startLabelAnimation();
    return () => {
      resetLabelPositions();
      clearInterval(intervalIdRef.current!);
      clearTimeout(timeoutIdRef.current!);
    };
  }, [
    resetLabelPositions,
    searchSuggestions,
    startLabelAnimation,
    stopSearchLabelAnimation,
  ]);

  return (
    <div
      id="uh-search-label-container"
      className="absolute -z-10 size-full overflow-hidden"
      ref={containerRef}
    >
      {searchSuggestions?.map(
        (searchSuggest: SearchAssistDropdownItem, index: number) => (
          <div
            data-item="uh-search-label"
            ref={(element: HTMLDivElement | null) => {
              if (element) {
                labelRefs.current[index] = element;
              }
            }}
            key={index}
            className={`absolute flex size-full items-center bg-transparent text-left text-base font-normal text-gray-500 transition-transform duration-[0.3s] ease-[ease-out] ${index === 0 ? "visible translate-y-0" : "invisible translate-y-full"}`}
          >
            {searchSuggest.title}
          </div>
        ),
      )}
    </div>
  );
};
