interface CdsData {
  cds: {
    result: {
      cdsData: {
        children: {
          endTime: string;
          name: string;
          startTime: string;
          trailingIcon: string;
          url: string;
          useTiming: boolean;
        }[];
        name: string;
        url: string;
      }[];
      context: {
        lang: string;
        region: string;
      };
      schemaId: string;
    }[];
  };
}

export const cdsDataAtt: CdsData = {
  cds: {
    result: [
      {
        cdsData: [
          {
            children: [
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "Sign up for free email",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://www.att.com/partners/currently/email-sign-up/?source=EnEmail2020000BDL&wtExtndSource=topnav_More",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "Try ad-free email",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://more.att.com/att-yahoo-mail-plus/?source=En000EMPPTT00000L&wtExtndSource=topnav_More_uh3&ncid=dcm_347189983_538517787_127172993",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "myAT&T",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://www.att.com/buy/phones/?source=EU00AN000U9V0100E&wtExtndSource=Buyphones_MYATT_Currently_More_uh3",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "AT&T deals",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://www.att.com/deals/?source=EU00AN000U9V0100E&wtExtndSource=Attdeals_Attdeals_Currently_More_uh3",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "Phones & devices",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://www.att.com/buy/phones/?source=EPcc000000000000U&wtExtndSource=AQ_CELLPHONE_Currently_Dgen_More_uh3",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "AT&T wireless",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://www.att.com/wireless/?source=EPcc000000000000U&wtExtndSource=AQ_WIRELESS_Currently_Dgen_More_uh3",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "myAT&T app",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://www.att.com/features/myatt-app/?source=EPcc000000000000U&wtExtndSource=MYATTAPP_Currently_Dgen_More_uh3",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "AT&T internet",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://www.att.com/internet/fiber/?source=EPcc000000000000U&wtExtndSource=AQ_FIBER_Currently_Dgen_More_uh3",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "Get AT&T support",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://www.att.com/support/?source=EPcc000000000000U&wtExtndSource=Support_Currently_Dgen_More_uh3",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "Original stories",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://currently.att.yahoo.com/news/originals",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "Play free games",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "http://www.currently.com/games?arkpromo=CurrentlyGamesTopNav_2021_More_uh3",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "Ver en español",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://envivo.att.yahoo.com/",
                useTiming: false,
              },
            ],
            name: "AT&T services",
            url: "",
          },
        ],
        context: { lang: "en-US", region: "US" },
        schemaId: "uh:more-menu",
      },
    ],
  },
};
