import { I13nAnchor, I13nButton } from "@yahoo-creators/i13n";
import classNames from "classnames";
import { useState, useId, type FC } from "react";
import { useIntl } from "react-intl";
import { editionsLinks } from "../../lib/editionspicker-data";
import EditionsCheck from "../../svgs/editions-check.svg";
import GlobeIcon from "../../svgs/globe.svg";
import SectionChevron from "../../svgs/section-chevron.svg";

interface Props {
  setPanelOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  menuLevel?: number;
}

export const EditionsMenu: FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();
  const editions = editionsLinks;
  const { name } = editions[0].selected_edition;
  const menuId = useId();

  return (
    <section className={classNames(props.menuLevel === 1 ? "mb-4" : "hidden")}>
      <div className="inline-flex w-[311px] items-center justify-start gap-2.5 px-5 py-3">
        <div className="grow text-xs font-semibold text-dolphin">
          {intl.formatMessage({ id: `uh.EDITIONS_MENU_LABEL` })}
        </div>
      </div>
      <div
        className={classNames(
          "mx-5 w-[271px] items-center rounded",
          isOpen &&
            "shadow-[0px_0px_1px_0px_rgba(0,0,0,0.10),0px_2px_4px_0px_rgba(0,0,0,0.08)]",
        )}
      >
        <I13nButton
          aria-controls={menuId}
          aria-expanded={isOpen}
          onKeyDown={(e) => {
            if (!isOpen && !e.shiftKey && e.key === "Tab") {
              props.setPanelOpen && props.setPanelOpen(false);
            }
          }}
          className={classNames(
            "group/Button inline-flex h-11 w-[271px] cursor-pointer items-center gap-2.5 rounded border border-[color:dirty-seagull] bg-white p-3 text-left hover:text-grape-jelly focus-visible:text-grape-jelly focus-visible:outline focus-visible:outline-4 focus-visible:outline-[rgb(96_1_210/50%)]",
            isOpen ? "rounded-b-none border-b-0" : "rounded",
          )}
          dataYlk={{
            elm: "navcat",
            itc: 0,
            pkgt: "side-nav",
            sec: "ybar",
            slk: "editions",
            subsec: "navrail",
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <GlobeIcon className="group-hover/Button:fill-grape-jelly group-has-[:focus-visible]/Button:fill-grape-jelly" />
          <span className={"w-[193px]"}>{name}</span>
          <SectionChevron
            className={classNames(
              "relative size-3.5 group-hover/Button:fill-grape-jelly",
              isOpen && "rotate-180",
            )}
          />
        </I13nButton>
        <ul
          id={menuId}
          className={classNames(
            "w-[271px] flex-col rounded border border-t-0 border-[color:dirty-seagull] bg-white [transition:opacity_.2s_ease-in,_height_.2s_ease-in,_visibility_.2s_ease-in]",
            isOpen
              ? "h-[572px] rounded-t-none"
              : "invisible h-0 overflow-hidden opacity-0",
          )}
        >
          {editions[0].links.map((link, index) => {
            const isSelectedEdition = link.selected;
            return (
              <li
                key={`sideNavEditionsMenu_${link.name}+${link.lang}`}
                className={classNames(
                  "group/editions-lang mx-1 h-11 items-center gap-1.5 rounded px-2 py-3 leading-5 hover:bg-grape-jelly has-[:focus-visible]:rounded-sm has-[:focus-visible]:bg-marshmallow has-[:focus-visible]:outline has-[:focus-visible]:outline-4 has-[:focus-visible]:outline-[rgb(96_1_210/50%)]",
                  isSelectedEdition &&
                    "flex items-center justify-between rounded bg-dirty-seagull",
                )}
              >
                <I13nAnchor
                  dataYlk={{
                    elm: "navcat",
                    itc: 0,
                    pkgt: "top",
                    sec: "ybar",
                    slk: link.name + "-" + link.lang,
                    subsec: "more",
                  }}
                  onKeyDown={(e) => {
                    if (
                      e &&
                      !e.shiftKey &&
                      index === editions[0].links.length - 1
                    ) {
                      props.setPanelOpen && props.setPanelOpen(false);
                    }
                  }}
                  href={link.url}
                  className={classNames(
                    "justify-between px-1 py-0 font-normal no-underline outline-none focus-visible:text-grape-jelly",
                  )}
                >
                  <span
                    className={classNames(
                      "text-sm font-normal group-hover/editions-lang:text-white group-has-[:focus-visible]/editions-lang:text-grape-jelly",
                      isSelectedEdition
                        ? "font-semibold text-batcave"
                        : "text-battleship",
                    )}
                  >
                    {link.name}
                    <span className="ml-1.5 text-[11px] font-normal italic leading-4 group-hover/editions-lang:text-white group-has-[:focus-visible]/editions-lang:text-grape-jelly">
                      {link.lang}
                    </span>
                  </span>
                </I13nAnchor>
                {isSelectedEdition && (
                  <EditionsCheck className="group-hover/editions-lang:fill-grape-jelly group-has-[:focus-visible]/editions-lang:fill-grape-jelly" />
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};
