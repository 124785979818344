import { useEffect, useState } from "react";
import { isDefaultProfilePic, isNameCJK } from "./helpers";
import { ProfileState } from "./profile-status";
import { type ProfileData } from "./types";

interface Props {
  crumb: string;
}

export default function useUsersData({ crumb }: Props) {
  const [showSignInButton, setShowSignInButton] = useState(false);
  const [showAccountPicker, setShowAccountPicker] = useState(false);
  const [profileData, setProfileData] = useState<ProfileData | null>(null);
  const [useAlphatar, setUseAlphatar] = useState(false);
  const [seenWithoutCrumb, setSeenWithoutCrumb] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [mailCount, setMailCount] = useState(-1);

  const handleShowAccountPicker = (show: boolean) => {
    setShowAccountPicker(show);
  };

  const handleSetMailCount = (count: number) => {
    setMailCount(count);
  };

  useEffect(() => {
    if (!crumb) {
      if (seenWithoutCrumb) {
        setShowSignInButton(true);
      } else {
        setSeenWithoutCrumb(true);
        setIsComplete(true);
      }
      return;
    }
    const api = `https://jsapi.login.yahoo.com/w/device_users?.crumb=${crumb}`;
    const abortController = new AbortController();
    const init: RequestInit = {
      credentials: "include",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
      signal: abortController.signal,
    };

    fetch(api, init)
      .then((response) => response.json())
      .then((data) => {
        const currentUser = data.users?.[0];
        if (currentUser && currentUser.state === ProfileState.LOGGED_IN) {
          setProfileData({
            currentUser,
            users: data.users,
          });
          setUseAlphatar(
            isDefaultProfilePic(currentUser.profileImage) &&
              !isNameCJK(currentUser.firstName, currentUser.lastName),
          );
        } else {
          setShowSignInButton(true);
        }
        setIsComplete(true);
      })
      .catch((err) => {
        if (err.name !== "AbortError") {
          console.error("Error in fetching Account data:", err);
          setShowSignInButton(true);
          setIsComplete(true);
        }
      });

    return () => abortController.abort();
  }, [crumb, setShowSignInButton, seenWithoutCrumb]);
  return {
    handleSetMailCount,
    handleShowAccountPicker,
    isComplete,
    mailCount,
    profileData,
    setUseAlphatar,
    showAccountPicker,
    showSignInButton,
    useAlphatar,
  };
}
