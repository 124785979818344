import { I13nAnchor } from "@yahoo-creators/i13n";
import { SCREEN_WIDTH } from "@yahoo-news/util";
import classNames from "classnames";
import { useState, useEffect, useId, useRef, type FC } from "react";
import { useIntl } from "react-intl";
import { editionsLinks } from "../../lib/editionspicker-data";
import CloseBtn from "../../svgs/close-btn.svg";
import EditionsCheck from "../../svgs/editions-check.svg";
import GlobeIcon from "../../svgs/globe.svg";

interface Editions {
  lang: string;
  name: string;
  url: string;
  selected: boolean;
}
interface Props {
  setMoreMenuOpen: () => void;
}

export const EditionsPicker: FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();

  const editions = editionsLinks;
  const { lang, name } = editions[0].selected_edition;
  const dialog = useRef<HTMLDialogElement | null>(null);
  const editionsButton = useRef<HTMLButtonElement | null>(null);
  const selectEditionsId = useId();
  useEffect(() => {
    if (!window.HTMLDialogElement) return;
    if (isOpen) {
      dialog.current?.showModal();
    } else {
      dialog.current?.close();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const resizeListener = () => {
      if (window.innerWidth < SCREEN_WIDTH.lg) {
        setIsOpen(false);
      }
    };
    addEventListener("resize", resizeListener);

    const dialogEl = dialog.current;
    const clickListener = (e: MouseEvent) => {
      const domRect = dialogEl?.getBoundingClientRect() as DOMRect;
      if (
        e.clientX < domRect.left ||
        e.clientX > domRect.right ||
        e.clientY < domRect.top ||
        e.clientY > domRect.bottom
      ) {
        setIsOpen(false);
      }
    };
    dialogEl?.addEventListener("click", clickListener);
    return () => {
      removeEventListener("resize", resizeListener);
      dialogEl?.removeEventListener("click", clickListener);
    };
  }, [isOpen]);

  return (
    <li role="group" className="text-sm leading-5 no-underline">
      <div className="mb-[17px] flex cursor-default items-center px-1 font-bold text-batcave">
        <div className="grow">
          {intl.formatMessage({ id: `uh.SELECTED_EDITION_ARIA_LABEL` })}
        </div>
        <GlobeIcon />
      </div>
      <button
        id={selectEditionsId}
        role="menuitem"
        className="group/Button h-11 w-full cursor-pointer gap-3 rounded-md border-[0] bg-marshmallow p-3 text-left text-batcave hover:text-grape-jelly focus-visible:text-grape-jelly focus-visible:outline focus-visible:outline-4 focus-visible:outline-[rgb(96_1_210/50%)]"
        onClick={() => {
          setIsOpen(true);
        }}
        ref={editionsButton}
      >
        <span>
          {name}
          <span className="ml-1.5 text-[12px] font-normal italic leading-4 text-battleship group-hover/Button:text-grape-jelly group-focus-visible/Button:text-grape-jelly">
            {lang}
          </span>
        </span>
      </button>
      <dialog
        className="top-[34px] h-[400px] w-[450px] rounded-md border-[0] bg-white px-[30px] py-5 text-batcave backdrop:bg-[rgba(0,_0,_0,_0.5)]"
        onClose={() => {
          setIsOpen(false);
          props.setMoreMenuOpen(); // keeps more menu open when dialog closed
          editionsButton.current?.focus(); // adds focus to editions button once dialog closes
        }}
        ref={dialog}
        aria-modal="true"
        aria-labelledby={selectEditionsId}
      >
        <header className="flex flex-row justify-between border border-x-transparent border-b-[1px_solid_#e0e4e9] border-t-transparent pb-2 text-sm font-bold">
          <span>
            {intl.formatMessage({ id: `uh.SELECT_EDITION_ARIA_LABEL` })}
          </span>
          <button
            onClick={() => {
              setIsOpen(false);
            }}
            className="group/close-btn -mr-1 px-[5px] focus-visible:rounded-xl focus-visible:outline focus-visible:outline-4 focus-visible:outline-[rgb(96_1_210/50%)]"
          >
            <CloseBtn className="fill-batcave group-hover/close-btn:fill-grape-jelly group-focus-visible/close-btn:fill-grape-jelly" />
          </button>
        </header>
        {
          <ul className="flex h-[308px] w-[377px] list-none flex-col flex-wrap items-start gap-2 p-0 pt-2">
            {editions[0].links.map((link: Editions, idx: number) => {
              const isSelectedEdition = link.selected;
              return (
                <li
                  key={idx}
                  className={classNames(
                    "group/editions-lang inline-flex h-9 w-[49%] items-center gap-1.5 rounded-md p-3 hover:bg-marshmallow",
                    "has-[:focus-visible]:rounded-sm has-[:focus-visible]:bg-marshmallow has-[:focus-visible]:outline has-[:focus-visible]:outline-4 has-[:focus-visible]:outline-[rgb(96_1_210/50%)]",
                    isSelectedEdition && "bg-marshmallow",
                  )}
                >
                  <I13nAnchor
                    dataYlk={{
                      elm: "navcat",
                      itc: 0,
                      pkgt: "top",
                      sec: "ybar",
                      slk: link.name + "-" + link.lang,
                      subsec: "more",
                    }}
                    href={link.url}
                    className={classNames(
                      "justify-between px-1 py-0 font-semibold no-underline outline-none focus-visible:text-grape-jelly",
                      isSelectedEdition &&
                        "flex w-full items-center justify-between",
                    )}
                  >
                    <span className="font-normal text-batcave group-hover/editions-lang:text-grape-jelly group-has-[:focus-visible]/editions-lang:text-grape-jelly">
                      {link.name}
                      <span className="ml-1.5 text-[12px] font-normal italic leading-4 text-battleship group-hover/editions-lang:text-grape-jelly group-has-[:focus-visible]/editions-lang:text-grape-jelly">
                        {link.lang}
                      </span>
                    </span>
                    {isSelectedEdition && (
                      <EditionsCheck className="group-hover/editions-lang:fill-grape-jelly group-has-[:focus-visible]/editions-lang:fill-grape-jelly" />
                    )}
                  </I13nAnchor>
                </li>
              );
            })}
          </ul>
        }
      </dialog>
    </li>
  );
};
