import { refreshRapid, I13nButton } from "@yahoo-creators/i13n";
import classNames from "classnames";
import { type FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import BackArrow from "../../svgs/back-arrow.svg";
import CloseBtn from "../../svgs/close-btn.svg";
import { type ProfileData } from "./types";
import { UniversalProfile } from "./UniversalProfile";

interface Props {
  crumb: string;
  handleProfilePanelHover: (isHovered: boolean) => void;
  handleShowMobileProfilePanel: (show: boolean) => void;
  handleShowAccountPicker: (show: boolean) => void;
  id: string;
  isExperimentalHeader?: boolean;
  isMobileScreenSize: boolean;
  isUH4?: boolean;
  mailCount: number;
  profileData: ProfileData | null;
  profileId: string;
  showAccountPicker: boolean;
  showProfilePanel: boolean;
  showMobileProfilePanel: boolean;
  showPFS: boolean;
}

export const ProfilePanel: FC<Props> = ({
  crumb,
  handleProfilePanelHover,
  handleShowAccountPicker,
  handleShowMobileProfilePanel,
  id,
  isExperimentalHeader,
  isMobileScreenSize,
  isUH4,
  mailCount,
  profileData,
  profileId,
  showAccountPicker,
  showMobileProfilePanel,
  showProfilePanel,
  showPFS,
}) => {
  const intl = useIntl();
  const [isHidden, setIsHidden] = useState(!showProfilePanel);
  const [isOpaque, setIsOpaque] = useState(false);
  let userIdentifier = "";
  if (profileData) {
    const user = profileData.currentUser;
    userIdentifier = user.email || user.alias;
  }
  const mountRef = useRef(false);
  const profilePanelRef = useRef<HTMLDivElement>(null);
  const rapidId = isUH4 ? "module-uh" : "module-uh-rocket";

  // useEffect required for refreshRapid interaction and css hover interaction
  useEffect(() => {
    if (showProfilePanel && !mountRef.current) {
      refreshRapid(rapidId, isUH4 ? "ybar" : undefined);
      mountRef.current = true;
    }
    let showTimeout: NodeJS.Timeout;
    let hideTimeout: NodeJS.Timeout;

    // add/removes hidden class so animation can occur
    if (!showProfilePanel) {
      setIsOpaque(false);

      hideTimeout = setTimeout(() => {
        setIsHidden(true);
      }, 500); // timing allows for opacity transition to complete
    } else {
      setIsHidden(false);
      showTimeout = setTimeout(() => {
        setIsOpaque(true);
      }, 50); // timing allows opacity to apply as soon as hidden is removed
    }

    return () => {
      clearTimeout(showTimeout);
      clearTimeout(hideTimeout);
    };
  }, [rapidId, isUH4, showProfilePanel]);

  useEffect(() => {
    if (showMobileProfilePanel) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [showMobileProfilePanel]);
  return (
    <div
      className={classNames(
        "fixed inset-y-0 z-30 inline-flex h-dvh w-[311px] flex-col items-start justify-between bg-white pb-10 [transition:right_ease-in_.3s,_opacity_ease-in_.3s] lg:absolute lg:right-0 lg:top-[39px] lg:z-20 lg:flex lg:h-fit lg:w-[360px] lg:bg-transparent lg:pt-[12px] lg:opacity-0 lg:[transition:all_.3s_ease-in-out]",
        isExperimentalHeader && "md:top-10 md:pt-[17px]",
        isHidden && "lg:hidden",
        isOpaque && "lg:opacity-100",
        showMobileProfilePanel
          ? "right-0 opacity-100"
          : "right-[-311px] opacity-0",
      )}
      onMouseEnter={() => {
        handleProfilePanelHover(true);
      }}
      onMouseLeave={() => {
        handleProfilePanelHover(false);
      }}
      id={id}
      aria-labelledby={profileId}
      ref={profilePanelRef}
    >
      <div
        className={classNames(
          "flex h-full flex-col overflow-x-hidden overflow-y-scroll rounded-lg bg-white [transition:all_.3s_ease-in-out] [width:inherit] lg:overflow-hidden",
          showProfilePanel &&
            "lg:[box-shadow:0_4px_16px_0_rgb(0_0_0/20%),_0_0_2px_0_rgb(0_0_0/5%)]",
          !isOpaque && "lg:h-0",
          isOpaque && "lg:h-[301px]",
          isOpaque && showPFS && !showAccountPicker && "lg:h-full",
          showAccountPicker && "md:h-full lg:h-fit",
        )}
      >
        {(isMobileScreenSize || showAccountPicker) && (
          // Account Picker/Mobile Profile Panel close/back buttons
          <div
            className={classNames(
              "flex h-12 w-[311px] items-center gap-2.5 px-3.5 py-2 lg:h-0",
              showAccountPicker ? "justify-start lg:h-12" : "justify-end",
            )}
          >
            <I13nButton
              className="group/back-button flex size-8 items-center justify-center gap-2.5 rounded-full hover:fill-[#5409b2] focus-visible:border-none focus-visible:fill-[#5409b2] focus-visible:outline-[rgb(96_1_210/50%)]"
              dataYlk={{
                elm: showAccountPicker ? "back" : "close",
                itc: 1,
                pkgt: "top",
                sec: "ybar",
                slk: "mweb-profile",
                subsec: "profile",
              }}
              aria-label={
                showAccountPicker
                  ? intl.formatMessage({ id: "uh.BACK" })
                  : intl.formatMessage({ id: "uh.CLOSE" })
              }
              onClick={(e) => {
                e.stopPropagation();
                if (!showAccountPicker) {
                  handleShowMobileProfilePanel(false);
                } else {
                  handleShowAccountPicker(false);
                }
              }}
            >
              {!showAccountPicker && (
                <CloseBtn className="group-hover/back-button:fill-grape-jelly group-focus-visible/back-button:fill-grape-jelly" />
              )}
              {showAccountPicker && (
                <BackArrow className="fill-black group-hover/back-button:fill-grape-jelly group-focus-visible/back-button:fill-grape-jelly" />
              )}
            </I13nButton>
          </div>
        )}
        <UniversalProfile
          crumb={crumb}
          handleShowAccountPicker={handleShowAccountPicker}
          isFullHeightPanel={isMobileScreenSize}
          mailCount={mailCount}
          profileData={profileData}
          showAccountPicker={showAccountPicker}
          showPFS={showPFS}
          userIdentifier={userIdentifier}
        />
      </div>
    </div>
  );
};
