export const PARTNERS = ["att", "none", "rogers"] as const;
export type Partner = (typeof PARTNERS)[number];

const Partners: { [key: string]: Partner } = {
  "ATT-EN-US": "att",
  "ATT-ES-US": "att",
  NONE: "none",
  "SBC-EN-US": "att",
  "SBC-ES-US": "att",
};

/* add expection later for mail since partner is not restricted by lang for mail */
export const getPartner = (partner: string, lang: string): Partner => {
  const key = partner.toUpperCase() + "-" + lang.toUpperCase();
  return Partners[key] ? Partners[key] : Partners.NONE;
};
