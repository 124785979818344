export const editionsLinks = [
  {
    links: [
      {
        lang: "English",
        name: "US",
        selected: true,
        url: "https://www.yahoo.com/?p=dnr",
      },
      {
        lang: "Español",
        name: "US y LATAM",
        selected: false,
        url: "https://espanol.yahoo.com/?p=dnr",
      },
      {
        lang: "English",
        name: "Australia",
        selected: false,
        url: "https://au.yahoo.com/?p=dnr",
      },
      {
        lang: "English",
        name: "Canada",
        selected: false,
        url: "https://ca.yahoo.com/?p=dnr",
      },
      {
        lang: "Français",
        name: "Canada",
        selected: false,
        url: "https://qc.yahoo.com/?p=dnr",
      },
      {
        lang: "Deutsch",
        name: "Deutschland",
        selected: false,
        url: "https://de.yahoo.com/?p=dnr",
      },
      {
        lang: "Français",
        name: "France",
        selected: false,
        url: "https://fr.yahoo.com/?p=dnr",
      },
      {
        lang: "繁中",
        name: "香港",
        selected: false,
        url: "https://hk.yahoo.com/?p=dnr",
      },
      {
        lang: "English",
        name: "Malaysia",
        selected: false,
        url: "https://malaysia.yahoo.com/?p=dnr",
      },
      {
        lang: "English",
        name: "New Zealand",
        selected: false,
        url: "https://nz.yahoo.com/?p=dnr",
      },
      {
        lang: "English",
        name: "Singapore",
        selected: false,
        url: "https://sg.yahoo.com/?p=dnr",
      },
      {
        lang: "繁中",
        name: "台灣",
        selected: false,
        url: "https://tw.yahoo.com/?p=dnr",
      },
      {
        lang: "English",
        name: "UK",
        selected: false,
        url: "https://uk.yahoo.com/?p=dnr",
      },
    ],
    name: "Editions",
    selected_edition: {
      lang: "English",
      name: " US",
    },
    text: "Select edition",
    url: "https://www.yahoo.com/everything/world/",
  },
];
