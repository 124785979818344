import { type UrlMapping } from "./types";

export const mailUrls: UrlMapping = {
  yahoo: {
    "ar-JO": "https://mail.yahoo.com/?.intl=xa&.lang=ar-JO",
    "de-DE": "https://mail.yahoo.com/?.intl=de&.lang=de-DE",
    default: "https://mail.yahoo.com/",
    "el-GR": "https://mail.yahoo.com/?.intl=gr&.lang=el-GR",
    "en-AE": "https://mail.yahoo.com/?.intl=XE&.lang=en-AE",
    "en-AU": "https://mail.yahoo.com/?.intl=au&.lang=en-AU",
    "en-CA": "https://mail.yahoo.com/?.intl=ca&.lang=en-CA",
    "en-GB": "https://mail.yahoo.com/?.intl=uk&.lang=en-GB",
    "en-IE": "https://mail.yahoo.com/?.intl=ie&.lang=en-IE",
    "en-IN": "https://mail.yahoo.com/?.intl=in&.lang=en-IN",
    "en-JO": "https://mail.yahoo.com/?.intl=xe&.lang=en-JO",
    "en-MY": "https://mail.yahoo.com/?.intl=my&.lang=en-MY",
    "en-NZ": "https://nz.mail.yahoo.com/",
    "en-PH": "https://mail.yahoo.com/?.intl=ph&.lang=en-PH",
    "en-SG": "https://mail.yahoo.com/?.intl=sg&.lang=en-SG",
    "en-ZA": "https://mail.yahoo.com/?.intl=za&.lang=en-ZA",
    "es-ES": "https://mail.yahoo.com/?.intl=es&.lang=es-ES",
    "es-US": "https://mail.yahoo.com/?.intl=e1&.lang=es-US",
    "fr-BE": "https://mail.yahoo.com/?.intl=b2&.lang=fr-BE",
    "fr-CA": "https://mail.yahoo.com/?.intl=ca&.lang=fr-CA",
    "fr-FR": "https://mail.yahoo.com/?.intl=fr&.lang=fr-FR",
    "id-ID": "https://mail.yahoo.com/?.intl=id&.lang=id-ID",
    "it-IT": "https://mail.yahoo.com/?.intl=it&.lang=it-IT",
    "nl-BE": "https://mail.yahoo.com/?.intl=b3&.lang=nl-BE",
    "pt-BR": "https://mail.yahoo.com/?.intl=br&.lang=pt-BR",
    "ro-RO": "https://mail.yahoo.com/?.intl=ro&.lang=ro-RO",
    "sv-SE": "https://mail.yahoo.com/?.intl=se&.lang=sv-SE",
    "vi-VN": "https://mail.yahoo.com/?.intl=vn&.lang=vi-VN",
    "zh-Hant-HK": "https://hk.mail.yahoo.com/",
    "zh-Hant-TW": "https://tw.mail.yahoo.com/?.intl=tw&.lang=zh-Hant-TW",
  },
};
