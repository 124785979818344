"use client";

import {
  type Dispatch,
  forwardRef,
  type ForwardRefExoticComponent,
  type RefAttributes,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

interface Props {
  ratio?: number;
}

export const ProgressBar: ForwardRefExoticComponent<
  Props & RefAttributes<Dispatch<React.SetStateAction<number>>>
> = forwardRef(({ ratio = 0 }, ref) => {
  const [value, setValue] = useState<number>(ratio);
  useImperativeHandle(ref, () => setValue, [setValue]);

  return (
    <div
      className="absolute bottom-0 left-0 h-0.5 bg-[#430297] md:hidden"
      style={{ width: `${value * 100}%` }}
    />
  );
});

/**
 * Uses the provided callback to calculate the current progress on scroll
 */
export const useScrollProgress = (
  calculateProgressOnScroll: (() => number) | null,
): number => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (typeof calculateProgressOnScroll !== "function") {
      return;
    }

    let frameId: number | null = null;
    const onScroll = () => {
      if (frameId) {
        cancelAnimationFrame(frameId);
      }

      frameId = requestAnimationFrame(() => {
        setProgress(calculateProgressOnScroll());
      });
    };

    window.addEventListener("scroll", onScroll, false);

    return () => {
      if (frameId) {
        cancelAnimationFrame(frameId);
      }

      window.removeEventListener("scroll", onScroll, false);
    };
  }, [calculateProgressOnScroll]);

  return progress;
};
