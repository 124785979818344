import { I13nAnchor } from "@yahoo-creators/i13n";
import classNames from "classnames";
import { type FC, useEffect, useState } from "react";
import { getRolloverMenuForSite } from "../lib/rollover-menu";

interface Props {
  site: string;
  handleHover: (isMenuHovered: boolean) => void;
}

interface RollOverItem {
  title: string;
  url: string;
}

const getRollOverDataYlk = (item: RollOverItem) => {
  const { url } = item;
  return {
    elm: "navcat",
    itc: 0,
    sec: "ybar",
    slk: url,
    subsec: "rolloverMenu",
  };
};

export const RollOverMenu: FC<Props> = ({ handleHover, site }) => {
  const rollOverItemsList = getRolloverMenuForSite(site);
  const [isVisible, setIsVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }, [isVisible]);

  useEffect(() => {
    if (!isFocused) {
      handleHover(false);
    }
  }, [isFocused, handleHover]);

  return (
    <div
      onMouseEnter={() => {
        handleHover(true);
      }}
      onMouseLeave={() => {
        handleHover(false);
      }}
      onFocus={() => {
        handleHover(true);
      }}
      onBlur={() => {
        if (!isFocused) {
          handleHover(false);
        }
      }}
      className={classNames(
        "absolute -left-6 top-8 z-[100] flex flex-col items-start gap-1 rounded-lg bg-white p-6 shadow-[0px_0px_1px_0px_rgba(0,0,0,0.10),0px_4px_8px_0px_rgba(0,0,0,0.10)] [transition:opacity_.100s_ease-in,_height_.100s_ease-in,_visibility_.1s_ease-in]",
        isVisible ? "visible h-auto opacity-100" : "invisible h-0 opacity-0",
      )}
    >
      <ul
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
        className="flex list-none flex-col items-start gap-2 p-0"
      >
        {rollOverItemsList.map((item: RollOverItem, idx: number) => {
          return (
            <li className="group/itemlink h-7 w-full hover:bg-marshmallow has-[:focus-visible]:rounded-sm has-[:focus-visible]:bg-marshmallow has-[:focus-visible]:outline has-[:focus-visible]:outline-4 has-[:focus-visible]:outline-[rgb(96_1_210/50%)]">
              <I13nAnchor
                dataYlk={getRollOverDataYlk(item)}
                key={idx}
                href={item.url}
                className="flex w-max flex-col items-start justify-center gap-2.5 self-stretch whitespace-nowrap p-1 font-yahoobeta text-sm font-normal text-batcave outline-none focus-visible:text-grape-jelly group-hover/itemlink:text-grape-jelly"
              >
                {item.title}
              </I13nAnchor>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
