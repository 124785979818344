const SEARCH_ORIGIN = "https://search.yahoo.com";
import {
  type ResponseItem,
  type SearchAssistDropdownItem,
  type SuggestionsDataFetcherParams,
  GOSSIP_MARKER_SEARCH_HISTORY,
  GOSSIP_MARKER_TRENDING,
} from "../types";
import { fetchOrProxy, generateId } from "./helpers";

/**
 * Helpers for converting each API's response format into a consistent SearchAssist item object structure
 */
export const convertToSA = {
  history: (item: ResponseItem): SearchAssistDropdownItem => ({
    id: generateId({
      subtitle: item.fd?.subtitle,
      title: item.k,
      ykid: item.d?.ykid,
    }),
    marker: item.m,
    subtitle: item.fd?.subtitle,
    title: item.k,
    ykid: item.d?.ykid,
  }),
  suggestions: (item: ResponseItem): SearchAssistDropdownItem => ({
    id: generateId({
      subtitle: item.fd?.subtitle,
      title: item.k,
      ykid: item.d?.ykid,
    }),
    imageUrl: item.fd?.imageUrl,
    marker: item.m,
    subtitle: item.fd?.subtitle,
    title: item.k,
    ykid: item.d?.ykid,
  }),
  trending: (item: ResponseItem): SearchAssistDropdownItem => ({
    id: generateId({
      subtitle: item.fd?.subtitle,
      title: item.k,
      ykid: item.d?.ykid,
    }),
    marker: item.m,
    subtitle: item.fd?.subtitle,
    title: item.k,
    ykid: item.d?.ykid,
  }),
};

export const fetchSuggestions = ({
  crumb,
  query,
}: SuggestionsDataFetcherParams) => {
  const url = new URL("/sugg/gossip/gossip-us-fastbreak", SEARCH_ORIGIN);
  const searchParams = new URLSearchParams();
  searchParams.set(".crumborig", "mbr");
  searchParams.set("appid", "yfp-t");
  searchParams.set("command", query);
  searchParams.set("output", "sd1");
  searchParams.set("f", "1");
  searchParams.set("t_stmp", String(Date.now() / 1000));

  // .crumb is invalid if URL encoded by URLSearchParams
  url.search = searchParams.toString() + "&.crumb=" + crumb;

  const abortController = new AbortController();
  const request = fetchOrProxy(url, {
    credentials: "include",
    signal: abortController.signal,
  });

  return {
    abort: () => abortController.abort(""),
    fetch: request
      .then((res) => res.json())
      .then((dataResponse) => {
        const results = dataResponse.r;
        if (!results) {
          return [];
        }
        const historyItems: SearchAssistDropdownItem[] = [];
        const suggestionsItems: SearchAssistDropdownItem[] = [];
        const trendingItems: SearchAssistDropdownItem[] = [];
        results.forEach((result: ResponseItem) => {
          if (result.m === GOSSIP_MARKER_SEARCH_HISTORY) {
            historyItems.push(convertToSA.history(result));
          } else if (result.m === GOSSIP_MARKER_TRENDING) {
            trendingItems.push(convertToSA.trending(result));
          } else {
            suggestionsItems.push(convertToSA.suggestions(result));
          }
        });
        const gossipSections = [
          {
            items: historyItems,
            sectionName: "history",
            title: "",
            type: "sectionList",
          },
          {
            items: suggestionsItems,
            sectionName: "suggestions",
            title: "",
            type: "sectionList",
          },
          {
            items: trendingItems,
            sectionName: "trending",
            title: "",
            type: "sectionList",
          },
        ];
        return gossipSections.filter((section) => section.items.length > 0);
      }),
  };
};

export function deleteHistoryItem(crumb: string, query: string) {
  const url = new URL("/history", SEARCH_ORIGIN);

  const searchParams = new URLSearchParams();
  searchParams.set(".crumborig", "mbr");
  searchParams.set("action", "del");
  searchParams.set("query", query);
  searchParams.set("sa", "1");

  // _bcrumb is invalid if URL encoded by URLSearchParams
  url.search = searchParams.toString() + "&_bcrumb=" + crumb;

  return fetchOrProxy(url, { credentials: "include" });
}
