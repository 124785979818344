import { I13nButton } from "@yahoo-creators/i13n";
import classNames from "classnames";
import { type FC } from "react";
import { useIntl } from "react-intl";
import { cdsData, type SideNavData } from "../../lib/sidenav-cdsData";
import CloseBtn from "../../svgs/close-btn.svg";
import SectionChevron from "../../svgs/section-chevron.svg";
import FootLogo from "../../svgs/yahoo-footer.svg";

import { EditionsMenu } from "./EditionsMenu";
import { Section } from "./Section";
interface Props {
  isBot: boolean;
  panelOpen: boolean;
  setPanelOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  subNavOpenKey: string;
  setSubNavOpenKey?: React.Dispatch<React.SetStateAction<string>>;
  menuLevel: number;
  setMenuLevel?: React.Dispatch<React.SetStateAction<number>>;
  autoExpandSection: string;
}

export const SideNavPanel: FC<Props> = ({
  isBot,
  panelOpen,
  setPanelOpen,
  setSubNavOpenKey,
  subNavOpenKey,
  menuLevel,
  setMenuLevel,
  autoExpandSection,
}) => {
  const intl = useIntl();
  const getSections = () => {
    const sections = cdsData.cds.result[0].cdsData;
    return sections.map((section: SideNavData, idx: number) => {
      const defaultOpen = section?.name === autoExpandSection ? true : false;
      return (
        <Section
          {...section}
          key={`section${idx}_${section.url}`}
          sectionId={`${idx}!`}
          setSubNavOpenKey={setSubNavOpenKey}
          subNavOpenKey={subNavOpenKey}
          setPanelOpen={setPanelOpen}
          panelOpen={panelOpen}
          menuLevel={menuLevel}
          setMenuLevel={setMenuLevel}
          defaultOpen={defaultOpen}
        />
      );
    });
  };
  const getPanel = () => {
    if (!panelOpen && !isBot) {
      return;
    }
    return (
      <div className="flex flex-col items-start justify-start">
        <div
          className={classNames(
            "flex h-12 w-[311px] items-center justify-end gap-2.5 px-3.5 py-2",
            subNavOpenKey && subNavOpenKey !== "" && "justify-between",
          )}
        >
          {((subNavOpenKey && subNavOpenKey !== "") || menuLevel !== 1) && (
            <I13nButton
              className="flex h-8 shrink basis-0 items-start justify-start gap-2.5 py-1.5"
              dataYlk={{
                elm: "cl",
                itc: 1,
                pkgt: "side-nav",
                rspns: "cl",
                sec: "ybar",
                slk: "close-back-btn",
                subsec: "navrail",
              }}
              aria-label={intl.formatMessage({ id: "uh.BACK" })}
              onClick={() => {
                setSubNavOpenKey &&
                  menuLevel &&
                  menuLevel !== 3 &&
                  setSubNavOpenKey("");
                setMenuLevel && setMenuLevel(menuLevel - 1);
              }}
            >
              <SectionChevron className="rotate-90" height={20} width={20} />
            </I13nButton>
          )}
          <I13nButton
            className="flex size-8 items-center justify-center gap-2.5 rounded-[100px] bg-gray-100 focus-visible:rounded-full focus-visible:outline focus-visible:outline-4 focus-visible:outline-offset-0 focus-visible:outline-[rgb(96_1_210/50%)]"
            dataYlk={{
              elm: "close",
              itc: 1,
              pkgt: "top",
              sec: "ybar",
              slk: "hamburger",
              subsec: "navrail",
            }}
            aria-label={intl.formatMessage({ id: "uh.CLOSE" })}
            onClick={() => {
              setPanelOpen && setPanelOpen(false);
              setSubNavOpenKey && setSubNavOpenKey("");
              setMenuLevel && setMenuLevel(1);
            }}
          >
            <CloseBtn />
          </I13nButton>
        </div>
        {getSections()}
        <EditionsMenu setPanelOpen={setPanelOpen} menuLevel={menuLevel} />
        <div className="inline-flex w-[311px] items-center justify-start gap-1.5 px-5 py-3 text-xs text-gray-600">
          &#169; 2024 <FootLogo height={13} width={47} />{" "}
          {intl.formatMessage({ id: "uh.ALL_RIGHTS_RESERVED" })}
        </div>
      </div>
    );
  };

  return (
    <div
      className={classNames(
        "fixed inset-y-0 z-30 inline-flex h-dvh w-[311px] flex-col items-start justify-start gap-10 overflow-x-hidden overflow-y-scroll bg-white pb-20 shadow [transition:left_ease-in_.3s] md:overflow-y-auto",
        panelOpen ? "left-0 overscroll-contain" : "left-[-311px]",
      )}
    >
      {getPanel()}
    </div>
  );
};
