import { I13nButton, type YlkData } from "@yahoo-creators/i13n";
import classNames from "classnames";
import { type FC, type ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
  tooltipOffset: number;
  tooltipText: string;
  dataYlk: YlkData;
  buttonRef: React.RefObject<HTMLButtonElement>;
}

export const ButtonWithTooltip: FC<Props> = (props) => (
  <I13nButton
    dataYlk={props.dataYlk}
    className={classNames(
      "group relative focus-visible:outline focus-visible:outline-4 focus-visible:outline-[rgb(96_1_210/50%)]",
      props.className,
    )}
    aria-label={props.tooltipText}
    ref={props.buttonRef}
  >
    {props.children}
    <div
      className="absolute left-1/2 hidden -translate-x-1/2 translate-y-full whitespace-nowrap rounded-lg bg-[rgb(35_42_49/80%)] px-3.5 py-2 text-xs text-white [box-shadow:0_4px_8px_0_rgb(0_0_0/10%),_0_0_1px_0_rgb(0_0_0/10%)] group-hover:block group-focus-visible:block"
      style={{ bottom: `${props.tooltipOffset}px` }}
      aria-hidden="true"
    >
      {props.tooltipText}
    </div>
  </I13nButton>
);
