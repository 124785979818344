import { type UrlMapping } from "./types";

export const sportsUrls: UrlMapping = {
  yahoo: {
    default: "https://sports.yahoo.com/",
    "en-au": "https://au.sports.yahoo.com/",
    "en-ca": "https://ca.sports.yahoo.com/",
    "en-gb": "https://uk.sports.yahoo.com/",
    "en-us": "https://sports.yahoo.com/",
  },
};
