import { I13nAnchor } from "@yahoo-creators/i13n";
import { type FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import Logout from "../../svgs/logout.svg";
import { useHeaderContext } from "./HeaderContext";
import { getSignOutURL } from "./helpers";

export const SignOut: FC = () => {
  const intl = useIntl();
  const { site } = useHeaderContext();
  const [doneUrl, setDoneUrl] = useState("");

  useEffect(() => {
    setDoneUrl(window.location.href);
  }, []);

  const signOutURL = getSignOutURL(site || "", doneUrl);
  return (
    <I13nAnchor
      dataYlk={{
        elm: "navcat",
        itc: 0,
        pkgt: "profile-pane",
        sec: "ybar",
        slk: "SignOut",
        subsec: "accounts",
      }}
      href={signOutURL}
      className="group/item my-0 block w-full p-2.5 pl-0 text-left text-sm font-normal leading-4 text-batcave hover:text-grape-jelly focus-visible:rounded-[1px] focus-visible:text-grape-jelly focus-visible:outline focus-visible:outline-4 focus-visible:outline-[rgb(96_1_210/50%)] lg:h-4 focus-visible:lg:h-[35px]"
    >
      <span className="mb-2.5 flex w-full flex-row items-center gap-2 pr-5">
        <Logout className="fill-batcave group-hover/item:fill-grape-jelly group-focus-visible/item:fill-grape-jelly" />
        {intl.formatMessage({ id: "uh.SIGNOUT" })}
      </span>
    </I13nAnchor>
  );
};
