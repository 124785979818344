import classNames from "classnames";
import { type FC, useState } from "react";
import { ProfileAlphatar } from "./ProfileAlphatar";
import { ProfileAvatar } from "./ProfileAvatar";
import { type ProfileData } from "./types";

interface Props {
  profileData: ProfileData | null;
  profileBgColor?: string;
  useAlphatar: boolean;
  transitionEnded: boolean;
  onAvatarError: () => void;
  onTransitionEnd: () => void;
}

export const ProfileIcon: FC<Props> = (props) => {
  const [avatarLoaded, setAvatarLoaded] = useState(false);
  const profileLoaded = avatarLoaded || props.useAlphatar;
  const hideAvatar =
    props.useAlphatar || (props.transitionEnded && !avatarLoaded);
  const hideAlphatar = !props.useAlphatar && props.transitionEnded;
  const firstName = props.profileData?.currentUser.firstName || "";
  return (
    <div
      className={classNames(
        "absolute left-0 top-0 flex size-full cursor-pointer select-none items-center justify-center [transition:all_.06s_ease-in] group-hover:bg-white group-hover:text-grape-jelly",
        props.profileBgColor ? props.profileBgColor : "bg-grey-hair",
        profileLoaded ? "opacity-100" : "opacity-0",
      )}
      onTransitionEnd={props.onTransitionEnd}
    >
      <ProfileAvatar
        hide={hideAvatar}
        profileData={props.profileData}
        onLoad={() => setAvatarLoaded(true)}
        onError={props.onAvatarError}
      />
      <ProfileAlphatar hide={hideAlphatar} profileFirstName={firstName} />
    </div>
  );
};
