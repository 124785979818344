const SEARCH_ORIGIN = "https://search.yahoo.com/";
import {
  type ResponseItem,
  type SearchAssistDropdownItem,
  type SuggestionsDataFetcherParams,
  GOSSIP_MARKER_SEARCH_HISTORY,
  GOSSIP_MARKER_TRENDING,
} from "../types";
import { convertToSA } from "./gossip";
import { fetchOrProxy } from "./helpers";

export const fetchSuggestions = ({
  crumb,
  query,
}: SuggestionsDataFetcherParams) => {
  const abortController = new AbortController();
  if (query.length === 0) {
    return {
      abort: () => abortController.abort(""),
      fetch: Promise.resolve([]),
    };
  }
  const url = new URL("/sugg/gossip/gossip-us-vertical_ss", SEARCH_ORIGIN);
  const searchParams = new URLSearchParams();
  searchParams.set("command", query);
  searchParams.set("output", "sd1");
  searchParams.set("t_stmp", String(Date.now() / 1000));
  searchParams.set("pubid", "1319");
  // .crumb is invalid if URL encoded by URLSearchParams
  url.search = searchParams.toString() + "&.crumb=" + crumb;

  const request = fetchOrProxy(url, {
    credentials: "include",
    signal: abortController.signal,
  });
  return {
    abort: () => abortController.abort(""),
    fetch: request
      .then((res) => res.json())
      .then((dataResponse) => {
        const results = dataResponse.r;
        if (!results) {
          return [];
        }
        const historyItems: SearchAssistDropdownItem[] = [];
        const suggestionsItems: SearchAssistDropdownItem[] = [];
        const trendingItems: SearchAssistDropdownItem[] = [];
        results.forEach((result: ResponseItem) => {
          if (result.m === GOSSIP_MARKER_SEARCH_HISTORY) {
            historyItems.push(convertToSA.history(result));
          } else if (result.m === GOSSIP_MARKER_TRENDING) {
            trendingItems.push(convertToSA.trending(result));
          } else {
            suggestionsItems.push(convertToSA.suggestions(result));
          }
        });
        const gossipSections = [
          {
            items: suggestionsItems,
            sectionName: "suggestions",
            title: "",
            type: "sectionList",
          },
        ];
        return gossipSections.filter((section) => section.items.length > 0);
      }),
  };
};
