import { I13nAnchor } from "@yahoo-creators/i13n";
import { beaconClick } from "@yahoo-creators/i13n/components/Rapid";
import classNames from "classnames";
import { type FC, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { MoreMenu } from "./MoreMenu/MoreMenu";
import { MoreMenuV2 } from "./MoreMenu/MoreMenuV2";
import { RollOverMenu } from "./RolloverMenu";

interface Props {
  lang?: string;
  partner?: string;
  site?: string;
  isBot?: boolean;
  isExperimentalHeader?: boolean;
  isStickyHeader?: boolean;
  uhRolloverMenus?: boolean;
}

interface PrimaryLink {
  [key: string]: Array<PrimaryLinkItem>;
}

interface PrimaryLinkItem {
  site: string;
  url: string;
}
export const primaryLinks: PrimaryLink = {
  "de-DE": [
    {
      site: "news",
      url: "https://de.yahoo.com/",
    },
    {
      site: "finance",
      url: "https://de.finance.yahoo.com/",
    },
  ],
  "en-AU": [
    {
      site: "news",
      url: "https://au.yahoo.com/",
    },
    {
      site: "finance",
      url: "https://finance.yahoo.com/",
    },
  ],
  "en-CA": [
    {
      site: "news",
      url: "https://ca.yahoo.com/",
    },
    {
      site: "finance",
      url: "https://ca.finance.yahoo.com/",
    },
  ],
  "en-GB": [
    {
      site: "news",
      url: "https://uk.yahoo.com/",
    },
    {
      site: "finance",
      url: "https://uk.finance.yahoo.com/",
    },
  ],
  "en-MY": [
    {
      site: "news",
      url: "https://malaysia.yahoo.com/",
    },
    {
      site: "finance",
      url: "https://sg.finance.yahoo.com/",
    },
  ],
  "en-NZ": [
    {
      site: "news",
      url: "https://nz.yahoo.com/",
    },
    {
      site: "finance",
      url: "https://nz.finance.yahoo.com/",
    },
  ],
  "en-SG": [
    {
      site: "news",
      url: "https://sg.yahoo.com/",
    },
    {
      site: "finance",
      url: "https://sg.finance.yahoo.com/",
    },
  ],
  "en-US": [
    {
      site: "news",
      url: "https://www.yahoo.com/",
    },
    {
      site: "finance",
      url: "https://finance.yahoo.com/",
    },
    {
      site: "sports",
      url: "https://sports.yahoo.com/",
    },
  ],
  "es-US": [
    {
      site: "news",
      url: "https://espanol.yahoo.com/",
    },
    {
      site: "finance",
      url: "https://es-us.finance.yahoo.com/",
    },
  ],
  "fr-FR": [
    {
      site: "news",
      url: "https://fr.yahoo.com/",
    },
    {
      site: "finance",
      url: "https://fr.finance.yahoo.com/",
    },
  ],
  "zh-Hant-TW": [
    {
      site: "news",
      url: "https://tw.yahoo.com/",
    },
    {
      site: "finance",
      url: "https://tw.stock.yahoo.com/",
    },
  ],
};

const PrimaryNavLink: FC<{
  link: PrimaryLinkItem;
  isExperimentalHeader: boolean;
  site: string;
  index: number;
}> = ({ link, site, index }) => {
  const intl = useIntl();
  const [isRolloverMenuOpen, setIsRolloverMenuOpen] = useState(false);
  const [isRolloverLinkHovered, setIsRolloverLinkHovered] = useState(false);
  const [isRolloverMenuHovered, setIsRolloverMenuHovered] = useState(false);

  const fireRapidBeacon = (elm: string, slk: string) => {
    const rolloverMenuYlk = {
      elm,
      itc: 1,
      pkgt: "top",
      sec: "ybar",
      slk,
      subsec: "navrail",
    };
    beaconClick(rolloverMenuYlk);
  };

  const handleRollOverMenuHover = useCallback((isMenuHovered: boolean) => {
    if (isMenuHovered) {
      setIsRolloverMenuHovered(true);
    } else {
      setIsRolloverMenuHovered(false);
    }
  }, []);

  useEffect(() => {
    if (!isRolloverLinkHovered && !isRolloverMenuHovered) {
      setIsRolloverMenuOpen(false);
      fireRapidBeacon("close", link.site);
    } else {
      setIsRolloverMenuOpen(true);
      fireRapidBeacon("expand", link.site);
    }
  }, [isRolloverMenuHovered, isRolloverLinkHovered, link.site]);

  return (
    <div
      className="relative flex flex-col items-center justify-start gap-1 rounded"
      key={index}
      onMouseEnter={() => {
        setIsRolloverLinkHovered(true);
      }}
      onMouseLeave={() => {
        setTimeout(() => setIsRolloverLinkHovered(false), 100);
      }}
      onFocus={() => {
        setIsRolloverLinkHovered(true);
      }}
      onBlur={() => {
        setTimeout(() => setIsRolloverLinkHovered(false), 100);
      }}
    >
      <I13nAnchor
        dataYlk={{
          elm: "navcat",
          itc: 0,
          pkgt: "top",
          sec: "ybar",
          slk: link.site,
          subsec: "navrail",
        }}
        className={classNames(
          "py-0.5 text-center hover:text-grape-jelly",
          "focus-visible:rounded-[1px] focus-visible:text-grape-jelly focus-visible:outline focus-visible:outline-4 focus-visible:outline-offset-[12px] focus-visible:outline-[rgb(96_1_210/50%)]",
          "text-base",
          link.site === site || (site === "frontpage" && link.site === "news")
            ? "font-bold text-gray-800"
            : "font-medium text-gray-800",
        )}
        href={link.url}
      >
        {intl.formatMessage({ id: `uh.PROPERTY_${link.site.toUpperCase()}` })}
        {isRolloverMenuOpen && (
          <RollOverMenu
            site={link.site}
            handleHover={handleRollOverMenuHover}
          />
        )}
      </I13nAnchor>
    </div>
  );
};

export const PrimaryNav: FC<Props> = (props) => {
  const intl = useIntl();
  const {
    isBot = false,
    isExperimentalHeader = false,
    uhRolloverMenus = false,
    site = "",
  } = props;
  const isShopping = site === "shopping";

  const getPrimaryLinks = (links: Array<PrimaryLinkItem>, site = "news") => {
    return links.map((link: PrimaryLinkItem, idx) => (
      <div
        className="flex flex-col items-center justify-start gap-1 rounded"
        key={idx}
      >
        <I13nAnchor
          dataYlk={{
            elm: "navcat",
            itc: 0,
            pkgt: "top",
            sec: "ybar",
            slk: link.site,
            subsec: "navrail",
          }}
          className={classNames(
            "py-0.5 text-center hover:text-grape-jelly",
            "focus-visible:rounded-[1px] focus-visible:text-grape-jelly focus-visible:outline focus-visible:outline-4 focus-visible:outline-[rgb(96_1_210/50%)]",
            "text-base",
            link.site === site || (site === "frontpage" && link.site === "news")
              ? "font-bold text-gray-800"
              : "font-medium text-battleship",
            "focus-visible:outline-offset-[12px]",
          )}
          href={link.url}
        >
          {intl.formatMessage({ id: `uh.PROPERTY_${link.site.toUpperCase()}` })}
        </I13nAnchor>
      </div>
    ));
  };

  const getRolloverPrimaryLinks = (
    links: Array<PrimaryLinkItem>,
    site = "news",
  ) => {
    return links.map((link: PrimaryLinkItem, idx) => (
      <PrimaryNavLink
        key={idx}
        link={link}
        site={site}
        isExperimentalHeader={isExperimentalHeader}
        index={idx}
      />
    ));
  };

  return (
    <div
      className={classNames(
        "hidden items-center justify-start gap-5 lg:mr-10 lg:flex lg:gap-4 xl:gap-6",
        isShopping && "lg:!mr-6 lg:!gap-6 xl:mr-10",
      )}
    >
      {uhRolloverMenus ? (
        <>
          {getRolloverPrimaryLinks(
            primaryLinks[props.lang || "en-US"],
            props.site,
          )}
          <MoreMenuV2
            isBot={isBot}
            isStickyHeader={props.isStickyHeader}
            isExperimentalHeader={isExperimentalHeader}
            uhRolloverMenus={props.uhRolloverMenus}
          />
        </>
      ) : (
        <>
          {getPrimaryLinks(primaryLinks[props.lang || "en-US"], props.site)}
          <MoreMenu
            isBot={isBot}
            isStickyHeader={props.isStickyHeader}
            isExperimentalHeader={isExperimentalHeader}
            partner={props.partner}
            lang={props.lang}
            uhRolloverMenus={props.uhRolloverMenus}
          />
        </>
      )}
    </div>
  );
};
