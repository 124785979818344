const isServer = () => typeof window === "undefined";
const isCorsEnabled = () => {
  const { hostname, protocol } = window.location;
  return protocol === "https:" && hostname.endsWith(".yahoo.com");
};

export const fetchOrProxy = (url: URL, init?: RequestInit) => {
  const proxy = isServer() === false && isCorsEnabled() === false;
  if (proxy) {
    url = new URL(url.pathname + url.search, window.location.origin);
  }
  return fetch(url, init);
};

export const generateId = ({
  subtitle,
  title,
  ykid,
}: {
  title: string;
  subtitle?: string;
  ykid?: string;
}) => {
  const key = ykid || `saIdx_${title}_${subtitle ? subtitle : ""}`;
  return key.replace(/\s/g, "__");
};
