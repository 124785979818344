interface CdsData {
  cds: {
    result: {
      cdsData: {
        children: {
          endTime: string;
          name: string;
          startTime: string;
          trailingIcon: string;
          url: string;
          useTiming: boolean;
        }[];
        name: string;
        url: string;
      }[];
      context: {
        lang: string;
        region: string;
      };
      schemaId: string;
    }[];
  };
}

export const cdsData: CdsData = {
  cds: {
    result: [
      {
        cdsData: [
          {
            children: [
              {
                endTime: "2024-02-06T23:50:00.000Z",
                name: "Today's news",
                startTime: "2024-02-05T21:40:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/news/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:42:00.000Z",
                name: "US",
                startTime: "2024-01-25T21:42:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/news/us/",
                useTiming: false,
              },
              {
                endTime: "2024-01-26T21:34:00.000Z",
                name: "Politics",
                startTime: "2024-01-25T21:34:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/news/politics/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:42:00.000Z",
                name: "World",
                startTime: "2024-01-25T21:42:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/news/world/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:42:00.000Z",
                name: "Weather",
                startTime: "2024-01-25T21:42:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/news/weather/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:42:00.000Z",
                name: "Climate change",
                startTime: "2024-01-25T21:42:00.000Z",
                trailingIcon: "none",
                url: "https://yahoo.com/tagged/climate-change/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:42:00.000Z",
                name: "Health",
                startTime: "2024-01-25T21:42:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/news/health/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:49:00.000Z",
                name: "Science",
                startTime: "2024-01-25T21:43:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/news/science/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:49:00.000Z",
                name: "2024 Election",
                startTime: "2024-01-25T21:49:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/election/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:50:00.000Z",
                name: "Originals",
                startTime: "2024-01-25T21:50:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/news/originals/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:50:00.000Z",
                name: "Newsletters",
                startTime: "2024-01-25T21:50:00.000Z",
                trailingIcon: "none",
                url: "https://news.yahoo.com/newsletters/",
                useTiming: false,
              },
            ],
            name: "News",
            url: "https://www.yahoo.com/",
          },
          {
            children: [
              {
                endTime: "2024-01-25T21:50:00.000Z",
                name: "Health",
                startTime: "2024-01-25T21:50:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/lifestyle/tagged/health/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:50:00.000Z",
                name: "Parenting",
                startTime: "2024-01-25T21:50:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/lifestyle/tagged/parenting/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:50:00.000Z",
                name: "Style and beauty",
                startTime: "2024-01-25T21:50:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/lifestyle/style-beauty/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:50:00.000Z",
                name: "Shopping",
                startTime: "2024-01-25T21:50:00.000Z",
                trailingIcon: "none",
                url: "https://shopping.yahoo.com",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:51:00.000Z",
                name: "Food",
                startTime: "2024-01-25T21:50:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/lifestyle/tagged/food/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:51:00.000Z",
                name: "Travel",
                startTime: "2024-01-25T21:51:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/lifestyle/tagged/travel/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:51:00.000Z",
                name: "Autos",
                startTime: "2024-01-25T21:51:00.000Z",
                trailingIcon: "none",
                url: "https://autos.yahoo.com/",
                useTiming: false,
              },
              {
                endTime: "2024-12-03T21:51:00.000Z",
                name: "Black Friday",
                startTime: "2024-11-01T21:50:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/lifestyle/black-friday-2024-everything-you-need-to-know-and-the-best-early-deals-to-shop-144212873.html",
                useTiming: false,
              },
              {
                endTime: "2024-12-03T21:51:00.000Z",
                name: "Cyber Monday",
                startTime: "2024-11-01T21:50:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/topics/cyber-monday/",
                useTiming: false,
              },
              {
                endTime: "2024-10-11T21:51:00.000Z",
                name: "Gift ideas",
                startTime: "2024-01-25T21:50:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/topics/gift-ideas/",
                useTiming: false,
              },
              {
                endTime: "2024-10-11T21:51:00.000Z",
                name: "Buying guides",
                startTime: "2024-01-25T21:50:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/topics/buying-guides/",
                useTiming: false,
              },
            ],
            name: "Life",
            url: "https://www.yahoo.com/lifestyle/",
          },
          {
            children: [
              {
                endTime: "2024-01-25T21:51:00.000Z",
                name: "Celebrity",
                startTime: "2024-01-25T21:51:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/entertainment/celebrity/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:51:00.000Z",
                name: "TV",
                startTime: "2024-01-25T21:51:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/entertainment/tv/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:51:00.000Z",
                name: "Movies",
                startTime: "2024-01-25T21:51:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/entertainment/movies/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:51:00.000Z",
                name: "Music",
                startTime: "2024-01-25T21:51:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/entertainment/music/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "How to Watch",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/entertainment/tagged/how-to-watch/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "Interviews",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/entertainment/tagged/interviews/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "Videos",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/entertainment/tagged/videos/",
                useTiming: false,
              },
            ],
            name: "Entertainment",
            url: "https://www.yahoo.com/entertainment/",
          },
          {
            children: [
              {
                endTime: "2024-01-25T21:51:00.000Z",
                name: "My portfolio",
                startTime: "2024-01-25T21:51:00.000Z",
                trailingIcon: "none",
                url: "https://finance.yahoo.com/portfolios/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:51:00.000Z",
                name: "Watchlists",
                startTime: "2024-01-25T21:51:00.000Z",
                trailingIcon: "none",
                url: "https://finance.yahoo.com/watchlists/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:51:00.000Z",
                name: "Markets",
                startTime: "2024-01-25T21:51:00.000Z",
                trailingIcon: "none",
                url: "https://finance.yahoo.com/calendar/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:51:00.000Z",
                name: "News",
                startTime: "2024-01-25T21:51:00.000Z",
                trailingIcon: "none",
                url: "https://finance.yahoo.com/news/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "Videos",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://finance.yahoo.com/videos/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "Screeners",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://finance.yahoo.com/screener/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "Personal finance",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://finance.yahoo.com/topic/personal-finance/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "Crypto",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://finance.yahoo.com/crypto/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "Industries",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://finance.yahoo.com/screener/predefined/ms_basic_materials/",
                useTiming: false,
              },
            ],
            name: "Finance",
            url: "https://finance.yahoo.com/",
          },
          {
            children: [
              {
                endTime: "2024-01-25T21:51:00.000Z",
                name: "Fantasy",
                startTime: "2024-01-25T21:51:00.000Z",
                trailingIcon: "none",
                url: "https://sports.yahoo.com/fantasy/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:51:00.000Z",
                name: "NFL",
                startTime: "2024-01-25T21:51:00.000Z",
                trailingIcon: "none",
                url: "https://sports.yahoo.com/nfl/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:51:00.000Z",
                name: "NBA",
                startTime: "2024-01-25T21:51:00.000Z",
                trailingIcon: "none",
                url: "https://sports.yahoo.com/nba/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:51:00.000Z",
                name: "MLB",
                startTime: "2024-01-25T21:51:00.000Z",
                trailingIcon: "none",
                url: "https://sports.yahoo.com/mlb/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "NHL",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://sports.yahoo.com/nhl/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "College football",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://sports.yahoo.com/college-football/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "College basketball",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://sports.yahoo.com/college-basketball/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "Soccer",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://sports.yahoo.com/soccer/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "MMA",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://sports.yahoo.com/mma/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "Yahoo Sports AM",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://sports.yahoo.com/newsletters/yahoo-sports-am/",
                useTiming: false,
              },
            ],
            name: "Sports",
            url: "https://sports.yahoo.com/",
          },
          {
            children: [
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "Creators",
                startTime: "2024-10-16T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://creators.yahoo.com/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "Games",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/games/",
                useTiming: false,
              },
              {
                endTime: "2024-01-25T21:52:00.000Z",
                name: "Tech",
                startTime: "2024-01-25T21:52:00.000Z",
                trailingIcon: "none",
                url: "https://www.yahoo.com/tech/",
                useTiming: false,
              },
            ],
            name: "New on Yahoo",
            url: "",
          },
        ],
        context: { lang: "en-US", region: "US" },
        schemaId: "uh:more-menu",
      },
    ],
  },
};
