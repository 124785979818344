/**
 * spacing is assumed to be using tailwinds default spacing config
 * https://tailwindcss.com/docs/customizing-spacing#default-spacing-scale
 */
import { I13nAnchor } from "@yahoo-creators/i13n";
import { useInView } from "@yahoo-news/util/hooks/useIntersectionObserver";
import classNames from "classnames";
import { type FC, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { mailConfig } from "../../configs/mail";
import MailIcon from "../../svgs/mail.svg";
import { fetchMailCount } from "./data";
interface Props {
  crumb?: string;
  handleSetMailCount: (count: number) => void;
  lang: string;
  isExperimentalHeader?: boolean;
  isComplete: boolean;
  mailAppId?: string;
  mailCount: number;
  partner?: string;
}

const langRegion: Record<string, string> = {
  "ar-JO": "https://mail.yahoo.com/?.intl=xa&.lang=ar-JO",
  "de-DE": "https://mail.yahoo.com/?.intl=de&.lang=de-DE",
  "el-GR": "https://mail.yahoo.com/?.intl=gr&.lang=el-GR",
  "en-AE": "https://mail.yahoo.com/?.intl=XE&.lang=en-AE",
  "en-AU": "https://mail.yahoo.com/?.intl=au&.lang=en-AU",
  "en-CA": "https://mail.yahoo.com/?.intl=ca&.lang=en-CA",
  "en-GB": "https://mail.yahoo.com/?.intl=uk&.lang=en-GB",
  "en-IE": "https://mail.yahoo.com/?.intl=ie&.lang=en-IE",
  "en-IN": "https://mail.yahoo.com/?.intl=in&.lang=en-IN",
  "en-JO": "https://mail.yahoo.com/?.intl=xe&.lang=en-JO",
  "en-MY": "https://mail.yahoo.com/?.intl=my&.lang=en-MY",
  "en-NZ": "https://nz.mail.yahoo.com/",
  "en-PH": "https://mail.yahoo.com/?.intl=ph&.lang=en-PH",
  "en-SG": "https://mail.yahoo.com/?.intl=sg&.lang=en-SG",
  "en-ZA": "https://mail.yahoo.com/?.intl=za&.lang=en-ZA",
  "es-ES": "https://mail.yahoo.com/?.intl=es&.lang=es-ES",
  "es-US": "https://mail.yahoo.com/?.intl=e1&.lang=es-US",
  "fr-BE": "https://mail.yahoo.com/?.intl=b2&.lang=fr-BE",
  "fr-CA": "https://mail.yahoo.com/?.intl=ca&.lang=fr-CA",
  "fr-FR": "https://mail.yahoo.com/?.intl=fr&.lang=fr-FR",
  "id-ID": "https://mail.yahoo.com/?.intl=id&.lang=id-ID",
  "it-IT": "https://mail.yahoo.com/?.intl=it&.lang=it-IT",
  "nl-BE": "https://mail.yahoo.com/?.intl=b3&.lang=nl-BE",
  "pt-BR": "https://mail.yahoo.com/?.intl=br&.lang=pt-BR",
  "ro-RO": "https://mail.yahoo.com/?.intl=ro&.lang=ro-RO",
  "sv-SE": "https://mail.yahoo.com/?.intl=se&.lang=sv-SE",
  "vi-VN": "https://mail.yahoo.com/?.intl=vn&.lang=vi-VN",
  "zh-Hant-HK": "https://hk.mail.yahoo.com/",
  "zh-Hant-TW": "https://tw.mail.yahoo.com/?.intl=tw&.lang=zh-Hant-TW",
};

export const Mail: FC<Props> = ({
  crumb,
  handleSetMailCount,
  lang,
  isExperimentalHeader,
  isComplete,
  mailAppId,
  mailCount,
  partner = "none",
}) => {
  const intl = useIntl();
  const mailDivRef = useRef(null);
  const inView = useInView(mailDivRef, { threshold: 1 });

  useEffect(() => {
    if (!crumb || mailCount > -1 || !isComplete || !inView) {
      if (!crumb && isComplete) {
        handleSetMailCount(0); // No crumb found so the user is signed out
      }
      return;
    }
    const abortController = new AbortController();
    const initFetch = async () => {
      const unseenCount = await fetchMailCount(abortController, mailAppId);
      handleSetMailCount(unseenCount);
    };
    initFetch();
    return () => abortController.abort();
  }, [crumb, mailCount, isComplete, inView, handleSetMailCount, mailAppId]);

  return (
    <I13nAnchor
      aria-label={intl.formatMessage({ id: "uh.MAIL_BUTTON" })}
      dataYlk={{
        elm: "btn",
        itc: 0,
        sec: "ybar",
        slk: "Mail",
        subsec: "mailprev",
        tar: "mail.yahoo.com",
      }}
      className={classNames(
        "group hidden h-10 w-auto min-w-10 items-center justify-center gap-2 rounded-full text-sm leading-tight [transition:all_.06s_ease-in] hover:cursor-pointer hover:[box-shadow:0_4px_8px_0_rgb(0_0_0/10%),_0_0_1px_0_rgb(0_0_0/10%)] focus-visible:outline focus-visible:outline-4 focus-visible:outline-offset-1 focus-visible:outline-[rgb(96_1_210/50%)] md:flex",
        mailConfig.textColor[partner] || mailConfig.textColor.none,
        mailConfig.btnBgColor[partner] || mailConfig.btnBgColor.none,
        mailConfig.hoverTextColor[partner] || mailConfig.hoverTextColor.none,
        isExperimentalHeader ? "font-semibold" : "font-medium",
      )}
      href={langRegion[lang] ? langRegion[lang] : `https://mail.yahoo.com/`}
      target="_blank"
    >
      <div
        className={classNames(
          mailCount >= 0 ? "px-3" : "pl-1",
          "mx-0.5 hidden size-full h-[90%] basis-full items-center justify-center gap-1.5 rounded-full bg-white py-0 md:flex",
          mailConfig.textColor[partner] || mailConfig.textColor.none,
          mailConfig.groupHoverTextColor[partner] ||
            mailConfig.groupHoverTextColor.none,
        )}
        ref={mailDivRef}
      >
        <MailIcon
          className={classNames(
            "fill-current",
            mailConfig.iconGroupHover[partner] ||
              mailConfig.iconGroupHover.none,
          )}
        />
        <span
          className={classNames(
            "[transition:all_.3s_ease-in]",
            mailCount !== 0 && "hidden w-0",
            mailCount === 0 && "block min-w-7",
          )}
        >
          {mailCount === 0 && intl.formatMessage({ id: "uh.PROPERTY_MAIL" })}
        </span>
        <span
          className={classNames(
            "[transition:all_.3s_ease-in]",
            mailCount <= 0 && "max-w-0",
            mailCount === 0 && "hidden",
            mailCount > 0 && mailCount < 10 && "min-w-2 max-w-6",
            mailCount > 0 && mailCount > 10 && "min-w-4 max-w-6",
          )}
        >
          {mailCount > 0 && <span>{mailCount > 99 ? "99+" : mailCount}</span>}
        </span>
      </div>
    </I13nAnchor>
  );
};
