import { I13nAnchor } from "@yahoo-creators/i13n";
import { type MouseEvent, type FC } from "react";
import { useIntl } from "react-intl";
import BackBtn from "../svgs/modal-back-btn.svg";

export const ModalBackButton: FC = () => {
  const intl = useIntl();
  const goBack = (e: MouseEvent) => {
    e.preventDefault();
    history.back();
  };

  return (
    <div className="group flex min-w-min shrink grow basis-0 items-center justify-start gap-2.5 rounded-full md:mr-4 lg:hidden">
      <I13nAnchor
        href="/"
        onClick={(e) => goBack(e)}
        aria-label={intl.formatMessage({ id: "uh.BACK" })}
        title={intl.formatMessage({ id: "uh.BACK" })}
        dataYlk={{
          elm: "btn",
          elmt: "btn",
          itc: 0,
          sec: "ybar",
          slk: "back-index",
          subsec: "navrail",
        }}
      >
        <BackBtn />
      </I13nAnchor>
    </div>
  );
};
