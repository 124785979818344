/* eslint-disable jsx-a11y/alt-text */

import classnames from "classnames";
import { type FC, type MutableRefObject } from "react";
import MobileIcon from "../../svgs/mobile-search-open.svg";
import RemoveIcon from "../../svgs/remove.svg";
import { Icon } from "./Icon";
import { GOSSIP_MARKER_SEARCH_HISTORY } from "./types";

interface Props {
  id: string;
  imageUrl?: string;
  isFullPageSearchOpen?: boolean;
  isSearchAssistOpen: boolean;
  itemIndex: number;
  listItemRef: MutableRefObject<HTMLLIElement[]>;
  marker: number;
  noFetch?: boolean;
  onClick: () => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLLIElement>) => void;
  onRemove: (title: string) => void;
  query: string;
  selected: boolean;
  subtitle?: string;
  title: string;
}

export const Item: FC<Props> = ({
  id,
  isFullPageSearchOpen = false,
  marker,
  title,
  query,
  noFetch,
  imageUrl,
  onClick,
  onKeyDown,
  onRemove,
  selected,
  subtitle,
  isSearchAssistOpen,
  listItemRef,
  itemIndex,
}) => {
  /**
   * Refactored from search to (reverse) highlight search terms
   * https://git.ouryahoo.com/searchfe/asset_core/blob/d7f7ec53ed5d7834022d2389ea7364de593f2f2c/dist/js/base/native-base.js#L438
   */
  const htmlEntityEncode = (rawStr: string) => {
    if (!rawStr) {
      return rawStr;
    }
    return rawStr.replace(/[\u00A0-\u9999<>\&]/gim, (i) => {
      return "&#" + i.charCodeAt(0) + ";";
    });
  };

  const escapeRegExp = (str: string) => {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
  };

  const bold = (text: string, keyword: string) => {
    if (text.toLowerCase() === keyword.toLowerCase()) {
      return `<span class="font-normal">${text}</span>`;
    }
    const needles = keyword.split(/[\s|,]+/).filter((e: string) => {
      return e !== "";
    });

    if (!needles.length) {
      return text;
    }

    // Not using toSorted() since that is fairly new
    const match = "(&[^;\\s]*)?(%needles)".replace(
      "%needles",
      needles
        .sort((a, b) => {
          return b.length - a.length;
        })
        .map((a) => {
          return escapeRegExp(a);
        })
        .join("|"),
    );
    const replacer = (match: string, p1: string, p2: string) => {
      return p1 && !/\s/.test(p2)
        ? match
        : `<span class="font-normal">${p2}</span>`;
    };
    return text.replace(new RegExp(match, "gi"), replacer);
  };

  return (
    <li
      aria-selected={selected}
      className={classnames(
        "group flex h-auto items-center border-l-2 border-solid border-transparent py-[5px] [transition:height_.2s_ease-in-out,opacity_.4s_ease-in-out] hover:border-grape-jelly hover:bg-[#efefef] focus:border-grape-jelly focus:bg-[#efefef] focus:outline-none focus:ring-0",
        selected && "border-grape-jelly bg-[#efefef]",
        !isSearchAssistOpen && "h-0 opacity-0",
        isSearchAssistOpen && "opacity-100",
      )}
      key={itemIndex}
      id={id}
      ref={(el: HTMLLIElement) => {
        if (el) {
          listItemRef.current[itemIndex] = el;
        }
      }}
      onClick={onClick}
      onKeyDown={(e: React.KeyboardEvent<HTMLLIElement>) => onKeyDown(e)}
      tabIndex={0}
      role="option"
    >
      <div className="mx-2.5 flex size-7 items-center justify-center">
        {imageUrl && !isFullPageSearchOpen ? (
          <img src={imageUrl} />
        ) : (
          <Icon marker={marker} />
        )}
      </div>
      <div className="flex shrink grow basis-0 flex-col justify-center overflow-hidden">
        <span
          className={classnames(
            marker === GOSSIP_MARKER_SEARCH_HISTORY && "text-grape-jelly",
            "cursor-default group-hover:text-grape-jelly group-focus:text-grape-jelly",
            selected && "text-grape-jelly",
          )}
        >
          {query?.length && !noFetch ? (
            <span
              className="font-bold"
              dangerouslySetInnerHTML={{
                __html: bold(htmlEntityEncode(title), htmlEntityEncode(query)),
              }}
            />
          ) : (
            <span>{title}</span>
          )}
        </span>
        {subtitle && (
          <span
            className={classnames(
              "text-xs text-dolphin group-hover:text-grape-jelly group-focus:text-grape-jelly",
              selected && "text-grape-jelly",
            )}
          >
            {subtitle}
          </span>
        )}
      </div>
      {marker === GOSSIP_MARKER_SEARCH_HISTORY && (
        <div className="mx-2.5 flex size-7 items-center justify-center">
          <RemoveIcon
            className="cursor-pointer"
            onClick={(e: React.MouseEvent<SVGElement, MouseEvent>) => {
              e.stopPropagation();
              onRemove(title);
            }}
          />
        </div>
      )}
      {isFullPageSearchOpen && imageUrl && (
        <div className="mx-2.5 flex size-7 items-center justify-end">
          <img src={imageUrl} />
        </div>
      )}
      {isFullPageSearchOpen && (
        <div className="mx-2.5 flex size-7 items-center justify-end">
          <MobileIcon height={20} width={20} />
        </div>
      )}
    </li>
  );
};
