import { I13nAnchor, I13nButton } from "@yahoo-creators/i13n";
import classNames from "classnames";
import { type FC } from "react";
import { useIntl } from "react-intl";
import Help from "../../svgs/help.svg";
import Mail from "../../svgs/mobile-mail.svg";
import Switch from "../../svgs/switch-accounts.svg";

interface Props {
  crumb: string;
  handleShowAccountPicker: (show: boolean) => void;
  isFullHeightPanel: boolean;
  mailCount: number;
  showPFS: boolean;
  userIdentifier: string;
}

export const ProfileSettings: FC<Props> = (props) => {
  const intl = useIntl();
  const { isFullHeightPanel, mailCount, showPFS, userIdentifier } = props;
  const profileSettingItems = [
    {
      name: userIdentifier,
      svg: (
        <Mail
          width={16}
          height={16}
          className="stroke-[#231f20] stroke-[1.5] group-hover/profile-mail:stroke-grape-jelly group-focus-visible/profile-mail:stroke-grape-jelly"
        />
      ),
      url: "https://mail.yahoo.com/",
    },
    {
      name: intl.formatMessage({ id: "uh.ACCOUNT_HELP" }),
      svg: <Help />,
      url: "https://help.yahoo.com/kb/account",
    },
    {
      name: intl.formatMessage({ id: "uh.ACCOUNT_ADD_OR_SWITCH" }),
      svg: <Switch />,
    },
  ];
  const profileSettingsCls =
    "group/item flex h-10 w-full items-center gap-4 py-2.5 text-sm font-normal leading-4";
  return (
    <ul
      className={classNames(
        "w-full px-5 lg:mb-1.5",
        showPFS ? "mt-2.5 lg:mt-0" : "mt-2.5 lg:mt-5",
      )}
    >
      {profileSettingItems.map((item, idx) => {
        const mail = item.name === userIdentifier;
        if (
          item.name === intl.formatMessage({ id: "uh.ACCOUNT_ADD_OR_SWITCH" })
        ) {
          return (
            <li key={idx} className={classNames(profileSettingsCls)}>
              <I13nButton
                dataYlk={{
                  elm: "btn",
                  itc: 0,
                  pkgt: "profile-pane",
                  sec: "ybar",
                  slk: item.name,
                  subsec: "accounts",
                }}
                onClick={() => {
                  props.handleShowAccountPicker(true);
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                className="flex w-full items-center gap-4 fill-batcave text-batcave [height:inherit] focus-visible:rounded-[1px] focus-visible:fill-grape-jelly focus-visible:text-grape-jelly focus-visible:outline focus-visible:outline-4 focus-visible:outline-offset-2 focus-visible:outline-[rgb(96_1_210/50%)] group-hover/item:fill-grape-jelly group-hover/item:text-grape-jelly"
              >
                {item.svg}
                <span>{item.name}</span>
              </I13nButton>
            </li>
          );
        }
        return (
          <li
            key={idx}
            className={classNames(
              profileSettingsCls,
              !isFullHeightPanel && mail && "hidden",
            )}
          >
            <I13nAnchor
              href={item.url}
              dataYlk={{
                elm: "navcat",
                itc: 0,
                pkgt: "profile-pane",
                sec: "ybar",
                slk: mail ? "Inbox" : item.name,
                subsec: "accounts",
              }}
              data-rapid_p={mail ? "310" : null}
              data-v9y={mail ? "1" : null}
              className={classNames(
                "flex w-full gap-4 fill-batcave text-batcave [height:inherit] hover:fill-grape-jelly hover:text-grape-jelly focus-visible:rounded-[1px] focus-visible:fill-grape-jelly focus-visible:text-grape-jelly focus-visible:outline focus-visible:outline-4 focus-visible:outline-[rgb(96_1_210/50%)]",
                mail ? "group/profile-mail items-start py-2.5" : "items-center",
              )}
            >
              {mail && (
                <div className="flex flex-row gap-[18px]">
                  <div className="mt-[5px]">{item.svg}</div>

                  <div className="w-full">
                    <span className="flex items-center gap-[30px]">
                      <p className="block max-w-[187px] items-center truncate text-sm font-[450] leading-5 group-hover/profile-mail:text-grape-jelly">
                        {userIdentifier}
                      </p>

                      {mailCount > 0 && (
                        <p className="text-sm text-grape-jelly">
                          {mailCount > 99 ? "99+" : mailCount}
                        </p>
                      )}
                    </span>
                  </div>
                </div>
              )}
              {!mail && (
                <>
                  {item.svg}
                  <span>{item.name}</span>
                </>
              )}
            </I13nAnchor>
          </li>
        );
      })}
    </ul>
  );
};
