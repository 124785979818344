import { I13nAnchor } from "@yahoo-creators/i13n";
import classNames from "classnames";
import { type FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHeaderContext } from "./HeaderContext";

interface Props {
  animate?: boolean;
}

export const SignInButton: FC<Props> = (props) => {
  const intl = useIntl();
  const { lang, site, isExperimentalHeader } = useHeaderContext();
  const [href, setHref] = useState("");
  let [animate, setAnimate] = useState(props.animate);

  useEffect(() => setHref(window.location.href), []);

  const url = new URL("https://login.yahoo.com");
  if (lang) {
    url.searchParams.set(".lang", lang);
  }
  // TODO: Discuss the src="creators" nuance with UH team.
  // Setting src="ycreators" for c.y.c because Identity team has
  // logic for creator auth & tooling, based on src="creators".
  if (site) {
    if (site === "creators") {
      url.searchParams.set("src", "ycreators");
      url.searchParams.set("activity", "ybar-signin");
    } else {
      url.searchParams.set("src", site);
    }
  }
  if (href) {
    url.searchParams.set(".done", href);
  }

  const signInUrl = url.toString();

  return (
    <I13nAnchor
      dataYlk={{
        elm: "signin",
        itc: 0,
        sec: "ybar",
        slk: "sign-in",
        subsec: "settings",
        tar: "login.yahoo.com",
      }}
      href={signInUrl}
      className={classNames(
        "block w-[68px] overflow-hidden whitespace-nowrap rounded-3xl border border-dirty-seagull bg-white px-3 py-[3px] text-sm text-batcave transition-[background-color,box-shadow,color,width] duration-[.06s] ease-in hover:bg-white hover:text-grape-jelly hover:[box-shadow:0_4px_8px_0_rgb(0_0_0/10%),_0_0_1px_0_rgb(0_0_0/10%)] md:my-0 md:flex md:h-10 md:w-[76px] md:items-center md:justify-center md:border-2 md:px-4 md:py-1.5",
        "focus-visible:text-grape-jelly focus-visible:outline focus-visible:outline-4 focus-visible:-outline-offset-1 focus-visible:outline-[rgb(96_1_210/50%)] focus-visible:transition-none",
        animate
          ? "animate-expand_button transition-[background-color,box-shadow,color,width]"
          : "transition-[background-color,box-shadow,color]",
        isExperimentalHeader ? "font-semibold" : "font-medium",
      )}
      onAnimationEnd={() => {
        setAnimate(false);
      }}
    >
      <div className="leading-5">
        {intl.formatMessage({ id: "uh.CTA_SIGN_IN" })}
      </div>
    </I13nAnchor>
  );
};
