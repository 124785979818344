import { I13nAnchor, I13nButton } from "@yahoo-creators/i13n";
import classNames from "classnames";
import { useCallback, useRef, useEffect, type FC } from "react";
import { type SideNavChild } from "../../lib/sidenav-cdsData";
import SectionChevron from "../../svgs/section-chevron.svg";
import { SubSectionListItem } from "./SubSectionListItem";

export const SectionListItem: FC<SideNavChild> = ({
  subsectionId,
  setPanelOpen,
  setSubNavOpenKey,
  subNavOpenKey,
  menuLevel,
  setMenuLevel,
  kids,
  name,
  url,
}) => {
  const level2menu = useRef<HTMLUListElement>(null);
  useEffect(() => {
    level2menu.current?.scroll({ top: 0 });
  }, [menuLevel]);
  const getSubSectionListItem = (items: SideNavChild[]) => {
    return items.map((item: SideNavChild, idx: number) => {
      return (
        <SubSectionListItem
          key={`subsectionListItem${idx}_${item.url}`}
          name={item.name}
          url={item.url}
          kids={item.kids}
          idx={idx}
          setPanelOpen={setPanelOpen}
          setSubNavOpenKey={setSubNavOpenKey}
          subNavOpenKey={subNavOpenKey}
          menuLevel={menuLevel}
          setMenuLevel={setMenuLevel}
          subsectionId={subsectionId}
        />
      );
    });
  };

  const decideListClasses = useCallback(() => {
    if (
      subNavOpenKey === `uh_sidenav_${subsectionId}` ||
      (subNavOpenKey?.includes(`uh_sidenav_${subsectionId}`) && menuLevel === 2)
    ) {
      return "absolute bottom-0 left-0 top-[45px] z-10 w-[311px] bg-white overflow-x-hidden overflow-y-scroll";
    } else if (menuLevel === 3) {
      return "";
    } else if (subNavOpenKey === "") {
      return "hidden";
    }
  }, [subNavOpenKey, menuLevel, subsectionId]);
  const tabStyle =
    "focus-visible:rounded-[1px] focus-visible:text-grape-jelly focus-visible:outline focus-visible:outline-4 focus-visible:outline-offset-4 focus-visible:outline-[rgb(96_1_210/50%)]";
  return (
    <li
      key={`sectionListItem_${url}`}
      className={classNames(
        "flex w-[271px] items-center justify-start gap-2",
        kids ? "" : "pr-5",
      )}
    >
      <I13nAnchor
        dataYlk={{
          elm: "navcat",
          itc: 0,
          pkgt: "side-nav",
          sec: "ybar",
          slk: name,
          subsec: "navrail",
        }}
        href={url}
        onClick={() => {
          setPanelOpen && setPanelOpen(false);
          setSubNavOpenKey && setSubNavOpenKey("");
        }}
        className={classNames(
          "flex h-11 shrink grow basis-0 py-3 pl-3 text-base font-normal leading-tight text-gray-600 hover:text-grape-jelly",
          tabStyle,
          { "w-[239px]": kids },
        )}
      >
        {name}
      </I13nAnchor>
      {kids && (
        <I13nButton
          dataYlk={{
            elm: "expand",
            itc: 1,
            pkgt: "side-nav",
            sec: "ybar",
            slk: name,
            subsec: "navrail",
          }}
          className={classNames(
            "flex h-11 w-12 items-center justify-end",
            tabStyle,
          )}
          aria-label={name}
          aria-expanded={subNavOpenKey === `uh_sidenav_${subsectionId}`}
          aria-controls={`uh_sidenav_${subsectionId}`}
          id={`uh_sidenav_btn_${subsectionId}`}
          onClick={() => {
            setSubNavOpenKey && setSubNavOpenKey(`uh_sidenav_${subsectionId}`);
            setMenuLevel && menuLevel && setMenuLevel(menuLevel + 1);
          }}
        >
          <SectionChevron height={16} width={16} className="-rotate-90" />
        </I13nButton>
      )}
      {kids && (
        <ul
          aria-labelledby={`uh_sidenav_btn_${subsectionId}`}
          className={classNames(decideListClasses())}
          id={`uh_sidenav_${subsectionId}`}
          ref={level2menu}
        >
          {getSubSectionListItem(kids)}
        </ul>
      )}
    </li>
  );
};
