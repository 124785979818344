import { createContext, useContext } from "react";
import { type ContextProfile } from "./types";

export const HeaderContext = createContext<ContextProfile>({
  isExperimentalHeader: false,
  lang: "",
  region: "",
  site: "",
  spaceId: undefined,
});

export const useHeaderContext = () => useContext(HeaderContext);
