import { beaconClick, buildRapidParams } from "@yahoo-creators/i13n";
import { useInView } from "@yahoo-news/util";
import { type FC, useEffect, useState, useRef } from "react";
import { useIntl } from "react-intl";

const cmdInterpreter = (raw: string) => {
  let cmd;

  try {
    cmd = JSON.parse(raw);
    return cmd;
  } catch (e) {
    console.error("Error:", e);
    return { cmd: "noop" };
  }
};

const getIframeMaxHeight = () => {
  let MAX_HEIGHT = 720;
  let MIN_HEIGHT = 300;

  let browserHeight = window.innerHeight;
  browserHeight = browserHeight - 40 /* bottom space */ - 80 /* top space */;
  if (browserHeight < MIN_HEIGHT) {
    return MIN_HEIGHT;
  }

  if (browserHeight > MAX_HEIGHT) {
    return MAX_HEIGHT;
  }

  return browserHeight;
};

const postMessageHandler = (
  cmd: string,
  params: {
    height?: number;
    maxAccounts?: number;
    enabledAnimation?: boolean;
    animationDuration?: string;
    animationTimingFunction?: string;
  },
  targetOrigin: string,
  targetSource: WindowProxy | null,
) => {
  if (!targetSource || !targetOrigin) {
    return;
  }
  targetSource.postMessage(
    JSON.stringify({
      cmd: cmd,
      params: params || {},
    }),
    targetOrigin,
  );
};

export const PFS: FC = () => {
  const intl = useIntl();
  const [doneUrl, setDoneUrl] = useState("");
  const [iframeHeight, setIframeHeight] = useState(0);
  const [iframeWidth, setIframeWidth] = useState(0);
  const [targetSource, setTargetSource] = useState<WindowProxy | null>(null);
  const [targetOrigin, setTargetOrigin] = useState<string>("");
  const ref = useRef<HTMLIFrameElement>(null);
  const inView = useInView(ref);
  const pfsURL = "https://pfs.yahoo.com";
  useEffect(() => {
    setDoneUrl(window.location.href);
  }, []);

  useEffect(() => {
    const messageListener = (event: MessageEvent) => {
      // Check if the message is coming from the correct origin
      if (event.origin !== pfsURL) {
        return;
      }
      const cmdRaw = cmdInterpreter(event.data);
      const { cmd, params } = cmdRaw;
      if (
        !(event.source instanceof MessagePort) &&
        !(event.source instanceof ServiceWorker)
      ) {
        switch (cmd) {
          case "iframeready":
            setTargetOrigin(event.origin);
            setTargetSource(event.source);
            postMessageHandler("initialize", {}, event.origin, event.source);
            break;
          case "show":
            if (!targetSource || !targetOrigin) {
              return;
            }
            setIframeHeight(parseInt(params.height));
            setIframeWidth(parseInt(params.width));
            break;
          case "redirect":
            if (ref.current?.contentWindow === targetSource) {
              beaconClick(buildRapidParams(params["data-ylk"]));
              window.location = params.url;
            }
            break;
          case "popup":
            if (ref.current?.contentWindow === targetSource) {
              beaconClick(buildRapidParams(params["data-ylk"]));
              window.open(params.url, "_blank");
            }
            break;
        }
      }
    };

    window.addEventListener("message", messageListener);
    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, [targetOrigin, targetSource]);

  useEffect(() => {
    if (!targetSource || !targetOrigin) {
      return;
    }
    if (inView && ref.current) {
      postMessageHandler(
        "resize",
        { height: getIframeMaxHeight() },
        targetOrigin,
        ref.current.contentWindow,
      );
    }
  }, [targetOrigin, targetSource, inView]);

  const url = new URL(pfsURL);
  url.searchParams.set("activity", "ybar");
  url.searchParams.set("intl", "us");
  url.searchParams.set("src", "homepage");
  url.searchParams.set("version", "2");
  url.searchParams.set("lang", "en-us");
  url.searchParams.set("theme", "light");
  url.searchParams.set(".done", doneUrl);
  return (
    <div className="mt-5">
      <iframe
        className="[transition:height_.3s_ease]"
        sandbox="allow-forms allow-scripts allow-same-origin allow-popups"
        ref={ref}
        title={intl.formatMessage({ id: "uh.PERSONAL_FEED_SYSTEM" })}
        height={iframeHeight}
        width={iframeWidth}
        src={url.toString()}
        scrolling="no"
      ></iframe>
    </div>
  );
};
