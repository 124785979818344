"use client";

import { useEsiVars } from "@yahoo-creators/edge";
import { UH3 } from "@yahoo-creators/uh";
import { type Item } from "@yahoo-creators/uh/lib/l2-navigation-data";
import { type EdgeSideIncludes } from "@/configs/esi";
import { creatorsL2NavData, creatorsMailAppId } from "@/configs/header";

export const SiteHeader = ({
  l2NavData = creatorsL2NavData,
  mailAppId = creatorsMailAppId,
  site = "creators",
}: {
  mailAppId?: string;
  l2NavData?: Array<Item>;
  site?: string;
}) => {
  const { crumb } = useEsiVars<Pick<EdgeSideIncludes, "crumb">>({ crumb: "" });
  return (
    <UH3
      mailAppId={mailAppId}
      site={site}
      lang="en-US"
      region="US"
      crumb={crumb}
      showL2Navigation={l2NavData.length > 0}
      subnavData={l2NavData}
    />
  );
};
