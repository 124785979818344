"use client";

import {
  createContext,
  useState,
  useContext,
  type FC,
  type ReactNode,
} from "react";

interface UHContextType {
  headerHeight: number;
  removeShadow: boolean;
  setHeaderHeight: React.Dispatch<React.SetStateAction<number>>;
  setRemoveShadow: React.Dispatch<React.SetStateAction<boolean>>;
}

const DEFAULT_UH_CONTEXT: UHContextType = {
  headerHeight: 0,
  removeShadow: false,
  setHeaderHeight: () => {},
  setRemoveShadow: () => {},
};

/**
 * UH context helps in managing the following states on client side:
 * 1. removeShadow - For toggling (on/off) UH shadow.
 * 2. headerHeight - Height of UH (px value).
 */
const UHContext = createContext<UHContextType>(DEFAULT_UH_CONTEXT);

export const UHProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [removeShadow, setRemoveShadow] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);

  return (
    <UHContext.Provider
      value={{
        headerHeight,
        removeShadow,
        setHeaderHeight,
        setRemoveShadow,
      }}
    >
      {children}
    </UHContext.Provider>
  );
};

export const useUHContext = (): UHContextType => useContext(UHContext);
