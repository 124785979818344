import classNames from "classnames";
import { type FC, type ReactNode, useEffect, useRef } from "react";

interface Props {
  customSubNavigation?: ReactNode;
  setAnimationRunning?: React.Dispatch<React.SetStateAction<boolean>>;
  showSecondLvl: boolean;
}

export const CustomSubNavigation: FC<Props> = ({
  customSubNavigation,
  setAnimationRunning,
  showSecondLvl,
}) => {
  const subNavRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const el = subNavRef.current;
    if (!el) {
      return;
    }

    const transitionListener = (e: TransitionEvent) => {
      if (e.propertyName === "transform" && setAnimationRunning) {
        setAnimationRunning(true);
      }
    };
    el.addEventListener("transitionstart", transitionListener);
    return () => el.removeEventListener("transitionstart", transitionListener);
  }, [setAnimationRunning]);

  const maxWidthCls = "max-w-uh-custom";
  return (
    <div
      className={classNames(
        showSecondLvl
          ? "pointer-events-auto translate-y-0 opacity-100"
          : "pointer-events-none -translate-y-full opacity-0",
        "hidden w-full items-center justify-around transition-[transform_0.9s_ease-out,opacity_0.2s_ease-out] md:inline-flex",
      )}
      onTransitionEnd={(e) => {
        if (e.propertyName === "transform" && setAnimationRunning) {
          setAnimationRunning(false);
        }
      }}
    >
      <div
        ref={subNavRef}
        className={classNames(
          "mx-auto hidden h-10 w-full items-center md:flex",
          maxWidthCls,
        )}
      >
        {customSubNavigation}
      </div>
    </div>
  );
};
