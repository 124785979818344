import classNames from "classnames";
import { type FC, useRef, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { cdsData } from "../../lib/moremenu-cdsData";
import { cdsDataAtt } from "../../lib/moremenu-cdsData-att";
import { EditionsPicker } from "./EditionsPicker";
import { MoreMenuColumn } from "./MoreMenuColumn";

interface Props {
  btnId: string;
  id: string;
  isExperimentalHeader?: boolean;
  isMenuHidden: boolean;
  isOpen: boolean;
  isStickyHeader?: boolean;
  lang?: string;
  partner?: string;
  setIsHovered: React.Dispatch<React.SetStateAction<boolean>>;
  setIsMenuHidden: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  uhRolloverMenus?: boolean;
}

// more menu dropdown
export const MoreMenuDropDown: FC<Props> = (props) => {
  const intl = useIntl();
  const {
    btnId,
    isMenuHidden,
    isOpen,
    isStickyHeader,
    partner,
    setIsHovered,
    setIsMenuHidden,
    setIsOpen,
    uhRolloverMenus,
  } = props;
  const displayedColumns = isStickyHeader
    ? ["News", "Finance", "Sports"]
    : ["News", "Finance", "Sports", "Games", "Entertainment"];

  const setMoreMenuOpen = () => {
    if (isMenuHidden) {
      setIsMenuHidden(false);
    }
    setIsOpen(true);
  };

  const modifyColumnData = (data: Array<Object>) => {
    const attColumnData = structuredClone(data);
    attColumnData.pop();
    attColumnData.push(cdsDataAtt.cds.result[0].cdsData[0]);
    return attColumnData;
  };

  const moreMenuColumnData = uhRolloverMenus
    ? cdsData.cds.result[0].cdsData.filter((column) => {
        return !displayedColumns.includes(column.name);
      })
    : partner !== "att"
      ? cdsData.cds.result[0].cdsData
      : modifyColumnData(cdsData.cds.result[0].cdsData);

  const moreMenuListCls = classNames(
    uhRolloverMenus
      ? "flex max-h-[320px] flex-col flex-wrap justify-start gap-6"
      : "inline-grid grid-cols-6 grid-rows-[auto_1fr] gap-x-3 gap-y-8 [flex-flow:wrap_column] min-[1440px]:gap-x-8",
  );

  const menuParentRef = useRef<HTMLUListElement>(null);
  const [menuHeight, setMenuHeight] = useState(0);

  useEffect(() => {
    isOpen && menuParentRef.current?.offsetHeight
      ? // ul content height plus paddings of parent div
        setMenuHeight(menuParentRef.current?.offsetHeight + 48)
      : setMenuHeight(0);
  }, [isOpen]);

  return (
    <div
      id={props.id}
      aria-label={intl.formatMessage({ id: "uh.A11Y_MOREMENU_DROPDOWN" })}
      aria-labelledby={btnId}
      role="menu"
      className={classNames(
        "z-20 mx-3 h-0 overflow-hidden rounded-lg bg-white p-6 opacity-0 [box-shadow:0px_4px_16px_0px_rgba(0,_0,_0,_0.20),_0px_0px_2px_0px_rgba(0,_0,_0,_0.05)] [transition:opacity_.3s_ease-in,_height_.3s_ease-in,_visibility_.3s_ease-in]",
        isOpen && "opacity-100",
      )}
      onTransitionEnd={(e: React.TransitionEvent<HTMLDivElement>) => {
        // remove drop down menu from the DOM after closing animation
        const target = e.target as HTMLDivElement;
        if (
          !isOpen &&
          !isMenuHidden &&
          target &&
          target.nodeName === "DIV" &&
          target.role === "menu"
        ) {
          setIsMenuHidden(true);
        }
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsOpen(false);
        setIsHovered(false);
      }}
      style={{ height: `${menuHeight}px` }}
      tabIndex={-1}
    >
      <ul className={moreMenuListCls} ref={menuParentRef}>
        {moreMenuColumnData.map((section: any, idx: number) => {
          const { name, children, url } = section;
          return (
            <MoreMenuColumn key={idx} name={name} links={children} url={url} />
          );
        })}
        {partner !== "att" && (
          <EditionsPicker setMoreMenuOpen={setMoreMenuOpen} />
        )}
      </ul>
    </div>
  );
};
