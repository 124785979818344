import { type UrlMapping } from "./types";

export const entertainmentUrls: UrlMapping = {
  yahoo: {
    default: "https://www.yahoo.com/entertainment/",
    "en-au": "https://au.lifestyle.yahoo.com/entertainment/",
    "en-ca": "https://ca.news.yahoo.com/celebrity/",
    "en-gb": "https://uk.yahoo.com/video",
    "en-sg": "https://sg.style.yahoo.com/tagged/celebrity/",
    "fr-fr": "https://fr.news.yahoo.com/people/",
  },
};
