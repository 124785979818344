import { type UrlMapping } from "./types";

export const lifestyleUrls: UrlMapping = {
  yahoo: {
    default: "https://www.yahoo.com/lifestyle",
    "en-au": "https://au.lifestyle.yahoo.com/",
    "en-in": "https://in.style.yahoo.com/",
    "en-my": "https://malaysia.news.yahoo.com/lifestyle/",
    "en-nz": "https://nz.news.yahoo.com/lifestyle/",
    "en-sg": "https://sg.style.yahoo.com/",
    "en-us": "https://www.yahoo.com/lifestyle/",
    "fr-fr": "https://fr.style.yahoo.com/",
    "id-id": "https://id.berita.yahoo.com/lifestyle/",
  },
};
