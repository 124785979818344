import { type FC } from "react";
import History from "../../svgs/history.svg";
import Search from "../../svgs/search.svg";
import Trending from "../../svgs/trending.svg";
import { GOSSIP_MARKER_SEARCH_HISTORY, GOSSIP_MARKER_TRENDING } from "./types";

interface Props {
  marker: number;
}

const getComponent = (marker: number) => {
  switch (marker) {
    case GOSSIP_MARKER_SEARCH_HISTORY:
      return History;
    case GOSSIP_MARKER_TRENDING:
      return Trending;
    default:
      return Search;
  }
};

export const Icon: FC<Props> = ({ marker }) => {
  const Component = getComponent(marker);
  return (
    <Component
      className="fill-dolphin group-hover:fill-grape-jelly group-focus:fill-grape-jelly"
      height={16}
      width={16}
    />
  );
};
