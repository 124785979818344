import { type FC, type ReactNode } from "react";

interface Props {
  className?: string;
  children: ReactNode;
}

export const Toolbar: FC<Props> = ({
  className = "flex min-w-min min-h-8 md:min-h-10 shrink grow basis-0 items-center justify-end md:gap-3",
  children,
}) => <div className={className}>{children}</div>;
