import { useEffect, type FC, useRef, useState } from "react";
import { onKeyDown } from "./events";
import { deleteHistoryItem } from "./plugins/gossip";
import { Tray } from "./Tray";
import {
  type SearchAssistDropdownItem,
  type SearchAssistDropdownSection,
  type SuggestionsDataFetcher,
} from "./types";

export type GossipSAProps = {
  activeDescendantId?: string;
  clearButtonRef: React.RefObject<HTMLButtonElement>;
  crumb: string;
  inputRef: React.RefObject<HTMLInputElement>;
  isFullPageSearchOpen?: boolean;
  isSearchAssistOpen: boolean;
  lang?: string;
  listItemRef: React.MutableRefObject<HTMLLIElement[]>;
  noFetch: boolean;
  onSuggestionSelect: (query: string, marker: number) => void;
  query: string;
  setSearchSuggestions: (suggestions: SearchAssistDropdownItem[]) => void;
  searchAssistDataFetcher: SuggestionsDataFetcher;
};

export const SearchAssist: FC<GossipSAProps> = ({
  activeDescendantId,
  clearButtonRef,
  crumb,
  inputRef,
  isFullPageSearchOpen = false,
  isSearchAssistOpen,
  lang = "en-US",
  listItemRef,
  noFetch,
  onSuggestionSelect,
  query,
  setSearchSuggestions,
  searchAssistDataFetcher,
}) => {
  const searchAssistTrayRef = useRef<HTMLDivElement>(null);
  const [suggestions, setSuggestions] = useState<SearchAssistDropdownSection[]>(
    [],
  );
  // When we make an API call to delete a search suggestion, the gossip
  // API will continue to return the deleted history item for a few
  // seconds. Keep track of the removed items so we can filter them out.
  const [removed, setRemoved] = useState(new Set<string>());
  useEffect(() => {
    if (!isSearchAssistOpen) {
      return;
    }
    // no fetch if query changes due to item focus
    if (noFetch) {
      return;
    }
    const { abort, fetch } = searchAssistDataFetcher({ crumb, lang, query });

    fetch
      .then((suggestions) => {
        if (!suggestions || suggestions.length === 0) {
          return;
        }
        setSuggestions(suggestions);
        const flattenedSuggestions = suggestions.flatMap(
          (section) => section.items,
        );
        // setSearchSugggestions is used to pass up the suggestions to Search
        setSearchSuggestions(flattenedSuggestions);
      })
      .catch(console.error);
    return abort;
  }, [
    crumb,
    searchAssistDataFetcher,
    isSearchAssistOpen,
    lang,
    noFetch,
    query,
    removed,
    setSearchSuggestions,
  ]);

  const onRemove = async (query: string) => {
    deleteHistoryItem(crumb, query)
      .then(() => setRemoved((removed) => new Set(removed.add(query))))
      .catch(console.error);
  };

  if (!suggestions?.length) {
    return;
  }

  return (
    <div
      ref={searchAssistTrayRef}
      className="md:max-h-[calc(100vh_-_120px)] md:overflow-auto"
    >
      <Tray
        isFullPageSearchOpen={isFullPageSearchOpen}
        activeDescendantId={activeDescendantId}
        onSuggestionSelect={onSuggestionSelect}
        query={query}
        noFetch={noFetch}
        isSearchAssistOpen={isSearchAssistOpen}
        listItemRef={listItemRef}
        removed={removed}
        onKeyDown={onKeyDown.bind(null, {
          clearButtonRef,
          inputRef,
          listItemRef,
          onSuggestionSelect,
        })}
        onRemove={onRemove}
        searchAssitItems={suggestions}
      />
    </div>
  );
};

export default SearchAssist;
