const rolloverMenu = {
  entertainment: [
    {
      title: "Celebrity",
      url: "https://www.yahoo.com/entertainment/celebrity/",
    },
    {
      title: "TV",
      url: "https://www.yahoo.com/entertainment/tv/",
    },
    {
      title: "Movies",
      url: "https://www.yahoo.com/entertainment/movies/",
    },
    {
      title: "Music",
      url: "https://www.yahoo.com/entertainment/music/",
    },
    {
      title: "How To Watch",
      url: "https://www.yahoo.com/entertainment/tagged/how-to-watch/",
    },
    {
      title: "Interviews",
      url: "https://www.yahoo.com/entertainment/tagged/interviews/",
    },
    {
      title: "Videos",
      url: "https://www.yahoo.com/entertainment/tagged/videos/",
    },
    {
      title: "Shopping",
      url: "https://www.yahoo.com/lifestyle/tagged/shopping/",
    },
  ],
  finance: [
    {
      title: "News",
      url: "https://finance.yahoo.com/news/",
    },
    {
      title: "Markets",
      url: "https://finance.yahoo.com/calendar/",
    },
    {
      title: "Sectors",
      url: "https://finance.yahoo.com/sectors/",
    },
    {
      title: "Screeners",
      url: "https://finance.yahoo.com/screener/",
    },
    {
      title: "Personal Finance",
      url: "https://finance.yahoo.com/personal-finance/",
    },
    {
      title: "Video",
      url: "https://finance.yahoo.com/videos/",
    },
    {
      title: "My Portfolio",
      url: "https://finance.yahoo.com/portfolios/",
    },
  ],
  games: [
    {
      title: "Popular",
      url: "https://www.yahoo.com/games/",
    },
    {
      title: "Mahjong",
      url: "https://www.yahoo.com/games/play/mahjongg-solitaire/",
    },
    {
      title: "Solitaire",
      url: "https://www.yahoo.com/games/play/solitaire-classic/",
    },
    {
      title: "Crossword",
      url: "https://www.yahoo.com/games/play/crossword/",
    },
    {
      title: "Card",
      url: "https://www.yahoo.com/games/category/card/",
    },
    {
      title: "Word",
      url: "https://www.yahoo.com/games/category/word/",
    },
    {
      title: "Board",
      url: "https://www.yahoo.com/games/category/board/",
    },
    {
      title: "Puzzle",
      url: "https://www.yahoo.com/games/category/puzzle/",
    },
  ],
  news: [
    {
      title: "Today's News",
      url: "https://www.yahoo.com/news/",
    },
    {
      title: "US",
      url: "https://www.yahoo.com/news/us/",
    },
    {
      title: "Politics",
      url: "https://www.yahoo.com/news/politics/",
    },
    {
      title: "World",
      url: "https://www.yahoo.com/news/world/",
    },
    {
      title: "Weather",
      url: "https://www.yahoo.com/news/weather/",
    },
    {
      title: "Climate Change",
      url: "https://yahoo.com/tagged/climate-change/",
    },
    {
      title: "Health",
      url: "https://www.yahoo.com/news/health/",
    },
    {
      title: "Science",
      url: "https://www.yahoo.com/news/science/",
    },
    {
      title: "2024 Elections",
      url: "https://www.yahoo.com/election/",
    },
    {
      title: "Originals",
      url: "https://www.yahoo.com/news/originals/",
    },
    {
      title: "Newsletters + Email Alerts",
      url: "https://news.yahoo.com/newsletters/",
    },
  ],
  sports: [
    {
      title: "Fantasy",
      url: "https://sports.yahoo.com/fantasy/",
    },
    {
      title: "NFL",
      url: "https://sports.yahoo.com/nfl/",
    },
    {
      title: "NBA",
      url: "https://sports.yahoo.com/nba/",
    },
    {
      title: "MLB",
      url: "https://sports.yahoo.com/mlb/",
    },
    {
      title: "NHL",
      url: "https://sports.yahoo.com/nhl/",
    },
    {
      title: "College football",
      url: "https://sports.yahoo.com/college-football/",
    },
    {
      title: "College basketball",
      url: "https://sports.yahoo.com/college-basketball/",
    },
    {
      title: "Soccer",
      url: "https://sports.yahoo.com/soccer/",
    },
    {
      title: "MMA",
      url: "https://sports.yahoo.com/mma/",
    },
    {
      title: "Yahoo Sports AM",
      url: "https://sports.yahoo.com/newsletters/yahoo-sports-am/",
    },
  ],
};

function hasKey<T extends object>(obj: T, key: PropertyKey): key is keyof T {
  return key in obj;
}

export const getRolloverMenuForSite = (site: string) => {
  if (hasKey(rolloverMenu, site)) {
    return rolloverMenu[site];
  }
  return [];
};
