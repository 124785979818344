import { I13nAnchor } from "@yahoo-creators/i13n";
import classNames from "classnames";
import { type FC, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import Keyline from "../../svgs/keyline.svg";
import { AccountPicker } from "./AccountPicker";
import { useHeaderContext } from "./HeaderContext";
import { formatURL, isNameCJK } from "./helpers";
import { PFS } from "./PFS";
import { ProfileAlphatar } from "./ProfileAlphatar";
import { ProfileSettings } from "./ProfileSettings";
import { SignOut } from "./SignOut";
import { type ProfileData } from "./types";

interface Props {
  crumb: string;
  handleShowAccountPicker: (show: boolean) => void;
  isFullHeightPanel: boolean;
  mailCount: number;
  profileData: ProfileData | null;
  userIdentifier: string;
  showAccountPicker: boolean;
  showPFS: boolean;
}

const createUserName = (
  firstName: string,
  isCJK: boolean,
  lastName: string,
) => {
  const userNameCls =
    "w-full overflow-x-clip overflow-y-visible text-ellipsis whitespace-nowrap";
  isCJK = isNameCJK(firstName, lastName);
  return (
    <>
      <p className={classNames(userNameCls)}>{isCJK ? lastName : firstName}</p>
      <p className={classNames(userNameCls)}>{isCJK ? firstName : lastName}</p>
    </>
  );
};

export const UniversalProfile: FC<Props> = (props) => {
  const intl = useIntl();
  const [doneUrl, setDoneUrl] = useState("");

  let isCJK = false;
  let isDefaultProfile = false;
  let firstName = "";
  let lastName = "";
  const { lang, region, site, spaceId } = useHeaderContext();
  const {
    crumb,
    handleShowAccountPicker,
    isFullHeightPanel,
    mailCount,
    profileData,
    showAccountPicker,
    showPFS,
    userIdentifier,
  } = props;
  useEffect(() => {
    setDoneUrl(window.location.href);
  }, []);

  if (profileData) {
    isDefaultProfile = profileData.currentUser.profileImage.includes(
      "default_user_profile_pic",
    );
    firstName = profileData.currentUser.firstName;
    lastName = profileData.currentUser.lastName;
  }

  const overViewurl = new URL("https://login.yahoo.com/myaccount/overview");

  const params = {
    ".crumb": crumb,
    ".done": encodeURIComponent(doneUrl),
    activity: "ybar-acctinfo",
    intl: region,
    lang,
    login: userIdentifier,
    pspid: spaceId?.toString(),
    src: site,
  };

  const formattedOverviewURL = formatURL(overViewurl, params);

  return (
    <div
      className={classNames(
        "flex w-[360px] flex-col p-0 font-yahoobeta md:h-full lg:h-fit lg:min-h-[285px] lg:pb-4 lg:[transition:all_.3s_ease-in-out]",
        showAccountPicker && "lg:h-fit lg:pt-0",
        isFullHeightPanel && "h-[90%] max-w-[311px] justify-between",
        !showAccountPicker && "lg:pt-5",
      )}
    >
      <>
        <div
          className={classNames(
            "flex flex-col justify-start",
            showAccountPicker && "hidden",
          )}
        >
          <div
            className={classNames(
              "flex max-h-[124px] max-w-[360px] gap-x-[15px] px-5",
              isDefaultProfile && "max-w-[324px]",
              isFullHeightPanel && "w-[360px]",
            )}
          >
            {isDefaultProfile ? (
              <div className="flex size-[72px] items-center justify-center rounded-[8px] bg-hendrix text-[44px] font-light">
                <ProfileAlphatar
                  hide={!isDefaultProfile}
                  profileFirstName={firstName}
                />
              </div>
            ) : (
              <img
                alt="" // decorative
                className="size-[72px] rounded-[8px]"
                src={profileData?.currentUser.profileImage}
              />
            )}
            <div className="flex flex-col justify-center">
              <div className="flex w-[230px] flex-col items-start gap-1 p-0 text-2xl font-bold leading-[22px] text-batcave">
                {createUserName(firstName, isCJK, lastName)}
                <p
                  className={classNames(
                    "w-[232px] overflow-hidden text-ellipsis text-xs text-dolphin",
                    isFullHeightPanel && "max-w-[187px]",
                  )}
                >
                  {profileData?.currentUser.email}
                </p>
              </div>
            </div>
          </div>
          <I13nAnchor
            dataYlk={{
              elm: "btn",
              itc: 0,
              pkgt: "profile-pane",
              sec: "ybar",
              slk: "Manage account",
              subsec: "accounts",
            }}
            href={formattedOverviewURL}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            className={classNames(
              "ml-5 mt-2.5 flex h-10 w-[320px] items-center justify-center rounded-full border border-dirty-seagull px-9 py-0 text-sm font-[450] text-batcave hover:bg-[#5409b2] hover:text-white focus-visible:text-grape-jelly focus-visible:outline focus-visible:outline-4 focus-visible:outline-offset-2 focus-visible:outline-[rgb(96_1_210/50%)]",
              isFullHeightPanel && "max-w-[275px]",
            )}
          >
            <p>{intl.formatMessage({ id: "uh.ACCOUNT_MANAGE" })}</p>
          </I13nAnchor>
          {showPFS && !isFullHeightPanel && <PFS />}
          <ProfileSettings
            crumb={crumb}
            handleShowAccountPicker={handleShowAccountPicker}
            isFullHeightPanel={isFullHeightPanel}
            mailCount={mailCount}
            showPFS={showPFS}
            userIdentifier={userIdentifier}
          />
        </div>
        <div className={classNames("h-10 px-5", showAccountPicker && "hidden")}>
          <Keyline
            className={classNames(
              "mb-[5px] w-[320px]",
              isFullHeightPanel && "max-w-[275px]",
            )}
          />
          <SignOut />
        </div>
      </>

      {showAccountPicker && (
        <AccountPicker
          crumb={crumb}
          isFullHeightPanel={isFullHeightPanel}
          profileData={profileData}
          showAccountPicker={showAccountPicker}
        />
      )}
    </div>
  );
};
