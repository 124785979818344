import { type UrlMapping } from "./types";

export const frontpageUrls: UrlMapping = {
  yahoo: {
    default: "https://www.yahoo.com/",
    "en-au": "https://au.yahoo.com/",
    "en-ca": "https://ca.yahoo.com/",
    "en-gb": "https://uk.yahoo.com",
    "en-sg": "https://sg.yahoo.com/",
    "fr-fr": "https://fr.yahoo.com/",
  },
};
