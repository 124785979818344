type SearchConfig = {
  btnColor: { [key: string]: string };
  hoverColor: { [key: string]: string };
};

export const searchConfig: SearchConfig = {
  btnColor: {
    att: "bg-[#0057b8]",
    none: "bg-uh-purple",
  },
  hoverColor: {
    att: "hover:bg-[#00396d]",
    none: "hover:bg-[#5409b2]",
  },
};
