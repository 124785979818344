import classNames from "classnames";
import { type FC } from "react";
import IntermediateLoadingAvatarIcon from "../../svgs/intermediate-loading-avatar.svg";

interface Props {
  hide: boolean;
}

export const IntermediateLoadingAvatar: FC<Props> = ({ hide }) => {
  return (
    <div
      className={classNames(
        hide && "hidden",
        "absolute left-0 top-0 flex size-full items-center justify-center",
      )}
    >
      <IntermediateLoadingAvatarIcon
        className={classNames("size-4 animate-pulse md:size-[18px]")}
      />
    </div>
  );
};
