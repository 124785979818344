import classNames from "classnames";
import { type FC, useCallback, useEffect, useState, useId } from "react";
import { useIntl } from "react-intl";
import DownArrow from "../../svgs/moremenu-down-arrow.svg";
import { MoreMenuDropDownV2 } from "./MoreMenuDropDownV2";

interface Props {
  isBot?: boolean;
  isStickyHeader?: boolean;
  isExperimentalHeader?: boolean;
  uhRolloverMenus?: boolean;
}

export const MoreMenuV2: FC<Props> = ({
  isBot,
  isStickyHeader,
  uhRolloverMenus,
}) => {
  const intl = useIntl();
  const menuId = useId();
  const [isMenuHidden, setIsMenuHidden] = useState(true);
  const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false);
  const [isMoreMenuHovered, setIsMoreMenuHovered] = useState(false);
  const [isMoreMenuDropDownHovered, setIsMoreMenuDropDownHovered] =
    useState(false);

  const handleMoreMenuHover = useCallback((isMenuHovered: boolean) => {
    if (isMenuHovered) {
      setIsMoreMenuDropDownHovered(true);
    } else {
      setIsMoreMenuDropDownHovered(false);
    }
  }, []);

  useEffect(() => {
    if (!isMoreMenuDropDownHovered && !isMoreMenuHovered) {
      setIsMoreMenuOpen(false);
    } else {
      if (isMenuHidden) {
        setIsMenuHidden(false);
      }
      setIsMoreMenuOpen(true);
    }
  }, [isMenuHidden, isMoreMenuHovered, isMoreMenuDropDownHovered]);

  return (
    <div
      className="relative flex justify-center hover:text-grape-jelly focus-visible:text-grape-jelly"
      onMouseEnter={() => setIsMoreMenuHovered(true)}
      onMouseLeave={() => {
        setTimeout(() => {
          setIsMoreMenuHovered(false);
        }, 100);
      }}
      onFocus={() => setIsMoreMenuHovered(true)}
      onBlur={() => {
        setTimeout(() => {
          setIsMoreMenuHovered(false);
        }, 100);
      }}
    >
      <button
        className="relative flex items-center text-center font-medium leading-none hover:text-grape-jelly focus-visible:rounded-[1px] focus-visible:text-grape-jelly focus-visible:outline focus-visible:outline-4 focus-visible:outline-offset-[12px] focus-visible:outline-[rgb(96_1_210/50%)] group-hover:top-3 group-hover:text-grape-jelly"
        onClick={() => {
          if (isMenuHidden && !isMoreMenuOpen) {
            setIsMenuHidden(false);
          }
          setIsMoreMenuOpen(!isMoreMenuOpen);
        }}
      >
        {intl.formatMessage({ id: `uh.PROPERTY_MORE` })}
        <DownArrow
          className={classNames(
            "group relative top-px fill-current group-hover:top-px group-hover:fill-grape-jelly group-hover:text-grape-jelly",
            isMoreMenuOpen && "rotate-180",
            isMoreMenuDropDownHovered && "fill-grape-jelly text-grape-jelly",
          )}
        />
      </button>
      {((!isMenuHidden && isMoreMenuOpen) || isBot) && (
        <>
          <div
            className={classNames(
              "visible absolute top-[15px] z-10 flex h-6 w-[400%]",
            )}
          ></div>
          <MoreMenuDropDownV2
            handleMoreMenuHover={handleMoreMenuHover}
            id={menuId}
            isMenuHidden={isMenuHidden}
            isStickyHeader={isStickyHeader}
            setIsMenuHidden={setIsMenuHidden}
            uhRolloverMenus={uhRolloverMenus}
          />
        </>
      )}
    </div>
  );
};
