import { I13nAnchor, I13nButton } from "@yahoo-creators/i13n";
import classNames from "classnames";
import { type FC, useEffect, useRef, useState } from "react";
import { type SideNavChild, type SideNavData } from "../../lib/sidenav-cdsData";
import SectionChevron from "../../svgs/section-chevron.svg";
import { SectionListItem } from "./SectionListItem";

export const Section: FC<SideNavData> = ({
  subNavOpenKey,
  setSubNavOpenKey,
  setPanelOpen,
  panelOpen,
  menuLevel,
  setMenuLevel,
  sectionId,
  url,
  name,
  kids,
  defaultOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const sectionList = useRef<HTMLUListElement>(null);
  useEffect(() => {
    if (defaultOpen) {
      setIsOpen(true);
    }
    return;
  }, [defaultOpen]);
  const getListItems = (items: SideNavChild[]) => {
    return items.map((item: SideNavChild, idx) => {
      return (
        <SectionListItem
          {...item}
          subNavOpenKey={subNavOpenKey}
          setSubNavOpenKey={setSubNavOpenKey}
          subsectionId={`${sectionId}${idx}!`}
          key={`sectionItem${idx}_${item.url}`}
          setPanelOpen={setPanelOpen}
          panelOpen={panelOpen}
          menuLevel={menuLevel}
          setMenuLevel={setMenuLevel}
        />
      );
    });
  };

  const tabStyle =
    "focus-visible:rounded-[1px] focus-visible:text-grape-jelly focus-visible:outline focus-visible:outline-4 focus-visible:outline-offset-4 focus-visible:outline-[rgb(96_1_210/50%)]";

  return (
    <section
      className={classNames(subNavOpenKey && subNavOpenKey !== "" && "h-0")}
    >
      <div className="inline-flex w-[311px] items-center justify-start gap-2.5 px-5 py-3">
        {/*
         * TODO: Determine how to include this for the "selected property."
         * pr-5 and gap[18px] should replace px-5 gap-2.5 above
         * <div className="w-0.5 self-stretch rounded-sm bg-gray-800" />
         */}
        <div className="flex shrink grow basis-0 flex-col items-start justify-start gap-0.5">
          <h3>
            <I13nAnchor
              className={classNames(
                "text-xl font-bold leading-normal text-batcave hover:text-grape-jelly hover:underline",
                tabStyle,
              )}
              href={url}
              dataYlk={{
                elm: "navcat",
                itc: 0,
                pkgt: "side-nav",
                sec: "ybar",
                slk: name,
                subsec: "navrail",
              }}
            >
              {name}
            </I13nAnchor>
          </h3>
        </div>
        <I13nButton
          className={classNames("relative size-5", tabStyle)}
          dataYlk={{
            elm: isOpen ? "close" : "expand",
            itc: 1,
            pkgt: "side-nav",
            sec: "ybar",
            slk: name,
            subsec: "navrail",
          }}
          onClick={() => setIsOpen(!isOpen)}
          aria-label={name}
          aria-expanded={isOpen}
          aria-controls={`uh_sidenav_${sectionId}`}
          id={`uh_sidenav_btn_${sectionId}`}
        >
          <SectionChevron className={classNames(isOpen ? "rotate-180" : "")} />
        </I13nButton>
      </div>
      {/* TODO: Fix the transistion to make it smoother */}
      <ul
        aria-labelledby={`uh_sidenav_btn_${sectionId}`}
        className={classNames(
          "flex w-[311px] flex-col items-center justify-start overflow-hidden px-5",
          isOpen ? "mb-3 opacity-100" : "opacity-0",
          menuLevel === 1 ? "[transition:all_.2s_ease-in]" : "transition-none",
        )}
        id={`uh_sidenav_${sectionId}`}
        ref={sectionList}
        style={{
          maxHeight:
            sectionList.current && isOpen && menuLevel === 1
              ? `${sectionList.current.scrollHeight}px`
              : "0",
        }}
      >
        {getListItems(kids)}
      </ul>
    </section>
  );
};
