import { beaconClick } from "@yahoo-creators/i13n/components/Rapid";
import { useInView } from "@yahoo-news/util/hooks/useIntersectionObserver";
import classNames from "classnames";
import { useId, useEffect, useState, useRef, type FC } from "react";
import { useIntl } from "react-intl";
import DownArrow from "../../svgs/moremenu-down-arrow.svg";
import { MoreMenuDropDown } from "./MoreMenuDropDown";

interface Props {
  isBot?: boolean;
  isStickyHeader?: boolean;
  isExperimentalHeader?: boolean;
  lang?: string;
  partner?: string;
  uhRolloverMenus?: boolean;
}

// more menu button
export const MoreMenu: FC<Props> = ({
  isBot,
  isStickyHeader,
  isExperimentalHeader,
  lang,
  partner,
  uhRolloverMenus,
}) => {
  const intl = useIntl();
  const [isMenuHidden, setIsMenuHidden] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const menuId = useId();
  const btnId = useId();
  const moreMenuRef = useRef(null);
  const isVisible = useInView(moreMenuRef, { threshold: 1 });
  let moreMenuDataYlk = {
    elm: "",
    itc: 1,
    pkgt: "top",
    sec: "ybar",
    slk: "More",
    subsec: "navrail",
  };

  const fireRapidBeacon = (elm: string) => {
    moreMenuDataYlk.elm = elm;
    beaconClick(moreMenuDataYlk);
  };

  useEffect(() => {
    if (!isVisible) {
      setIsHovered(false);
      setIsOpen(false);
    }
  }, [isVisible, isHovered, isOpen, setIsHovered, setIsOpen]);

  const getMoreMenuCls = () => {
    const baseCls = classNames(
      "absolute inset-x-0 flex justify-center font-yahoobeta",
      isOpen ? "visible z-20" : "invisible",
    );
    if (isExperimentalHeader) {
      if (uhRolloverMenus) {
        return isStickyHeader
          ? classNames(baseCls, "left-[-300px] top-[55px] w-max")
          : classNames(baseCls, "left-[-225px] top-[55px] w-max");
      }
      return isStickyHeader
        ? classNames(baseCls, "top-[4.75rem] w-full")
        : classNames(baseCls, "top-[83px] w-full");
    }
    return classNames(baseCls, "top-[4.75rem] w-full");
  };

  return (
    <div
      onMouseEnter={() => {
        if (!isOpen) {
          fireRapidBeacon("expand");
        }
        if (isMenuHidden) {
          setIsMenuHidden(false);
        }
        setIsOpen(true);
      }}
      onMouseLeave={() => {
        if (!isHovered) {
          setIsOpen(false);
          fireRapidBeacon("close");
        }
      }}
      onFocus={() => {
        if (isMenuHidden) {
          setIsMenuHidden(false);
        }
        setIsOpen(true);
        fireRapidBeacon("expand");
      }}
      onBlur={() => {
        setIsOpen(false);
      }}
      className={classNames(
        "group flex flex-col items-center justify-start gap-1 rounded pb-1 pt-[0.3rem]",
        isOpen && "after:absolute after:left-0 after:block after:w-full",
        isOpen &&
          !isExperimentalHeader &&
          "after:top-[calc(100%_-_30px)] after:h-8",
        isOpen &&
          isExperimentalHeader &&
          "after:top-[calc(100%_-_120px)] after:h-10",
        uhRolloverMenus && "relative",
      )}
      ref={moreMenuRef}
    >
      <button
        className={classNames(
          "items-center font-medium tracking-tight focus-visible:outline-offset-[12px]",
          isStickyHeader
            ? "text-base text-battleship"
            : "text-[1.06rem] text-gray-800",
          "relative top-0 flex text-center leading-none hover:text-grape-jelly focus-visible:rounded-[1px] focus-visible:text-grape-jelly focus-visible:outline focus-visible:outline-4 focus-visible:outline-[rgb(96_1_210/50%)] group-hover:text-grape-jelly",
        )}
        aria-haspopup="menu"
        aria-expanded={isOpen}
        aria-controls={menuId}
        id={btnId}
      >
        {intl.formatMessage({ id: `uh.PROPERTY_MORE` })}
        <DownArrow
          className={classNames(
            "group relative left-1 fill-current group-hover:top-px group-hover:fill-grape-jelly group-hover:text-grape-jelly",
            isOpen && "top-px rotate-180",
            "top-[0.075rem]",
          )}
        />
      </button>
      {isOpen && (
        <div
          className={classNames(
            "visible absolute z-10 flex h-9 w-1/4",
            isStickyHeader ? "top-12" : "top-[63px]",
          )}
        ></div>
      )}
      {(!isMenuHidden || isBot) && (
        <div className={getMoreMenuCls()}>
          <MoreMenuDropDown
            btnId={btnId}
            id={menuId}
            isExperimentalHeader={isExperimentalHeader}
            isMenuHidden={isMenuHidden}
            isOpen={isOpen}
            isStickyHeader={isStickyHeader}
            lang={lang}
            partner={partner}
            setIsHovered={setIsHovered}
            setIsMenuHidden={setIsMenuHidden}
            setIsOpen={setIsOpen}
            uhRolloverMenus={uhRolloverMenus}
          />
        </div>
      )}
    </div>
  );
};
