import { beaconClick } from "@yahoo-creators/i13n";
import { SCREEN_WIDTH } from "@yahoo-news/util";
import classNames from "classnames";
import { type FC, useEffect, useId, useState } from "react";
import { useIntl } from "react-intl";
import { IntermediateLoadingAvatar } from "./IntermediateLoadingAvatar";
import { ProfileIcon } from "./ProfileIcon";
import { ProfilePanel } from "./ProfilePanel";
import { SignInButton } from "./SignInButton";
import { type ProfileData } from "./types";

interface Props {
  crumb: string;
  enablePFS: boolean;
  handleShowAccountPicker: (show: boolean) => void;
  isExperimentalHeader?: boolean;
  profileBgColor?: string;
  isUH4?: boolean;
  mailCount: number;
  profileData: ProfileData | null;
  setUseAlphatar: (useAlphatar: boolean) => void;
  showAccountPicker: boolean;
  showSignInButton: boolean;
  useAlphatar: boolean;
}

export const Profile: FC<Props> = ({
  crumb,
  isExperimentalHeader,
  profileBgColor,
  isUH4,
  profileData,
  setUseAlphatar,
  showAccountPicker,
  showSignInButton,
  useAlphatar,
  handleShowAccountPicker,
  mailCount,
  enablePFS,
}) => {
  const intl = useIntl();
  const mobileMenuId = useId();
  const profileId = useId();
  const profileButtonId = useId();
  const [transitionEnded, setTransitionEnded] = useState(false);
  const [showProfilePanel, setShowProfilePanel] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);
  const [showMobileProfilePanel, setShowMobileProfilePanel] = useState(false);
  const [profilePanelHover, setProfilePanelHover] = useState(false);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize, false);
    return () => {
      window.removeEventListener("resize", handleResize, false);
    };
  }, []);

  const profileExpandYlk = {
    elm: "expand",
    itc: 1,
    pkgt: "profile-pane",
    sec: "ybar",
    slk: "profile",
    subsec: "settings",
  };
  const isMobileScreenSize = screenWidth < SCREEN_WIDTH.lg;
  const isDeskTopScreenSize = screenWidth >= SCREEN_WIDTH.lg;

  if (isDeskTopScreenSize && showMobileProfilePanel) {
    setShowMobileProfilePanel(false);
  }

  const handleMobileKeyNavigation = (
    e: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    if (e.key === "Enter") {
      setShowMobileProfilePanel(true);
    } else if (e.key === "Escape" && showMobileProfilePanel) {
      setShowMobileProfilePanel(false);
    }
  };

  const handleProfilePanelHover = (isHovered: boolean) => {
    setProfilePanelHover(isHovered);
  };

  const handleShowMobileProfilePanel = (show: boolean) => {
    setShowMobileProfilePanel(show);
  };

  return showSignInButton ? (
    <SignInButton animate={true} />
  ) : (
    <div
      id={profileButtonId}
      className={classNames(
        "group relative focus-visible:rounded-[15px] focus-visible:outline focus-visible:outline-4 focus-visible:outline-offset-0 focus-visible:outline-[rgb(96_1_210/50%)] md:focus-visible:rounded-[19px]",
        showProfilePanel &&
          "after:absolute after:left-[-0.70rem] after:top-0 after:block after:h-full after:w-[133%]",
      )}
      onMouseEnter={() => {
        if (!profilePanelHover && isDeskTopScreenSize) {
          setTimeout(() => {
            setShowProfilePanel(true);
          }, 200);
        }
      }}
      onMouseLeave={() => {
        if (isDeskTopScreenSize) {
          setShowProfilePanel(false);
        }
      }}
      onClick={() => {
        if (isMobileScreenSize) {
          setShowMobileProfilePanel(true);
        }
        beaconClick(profileExpandYlk);
      }}
      onKeyUp={(e) => {
        if (e.key === "Tab") {
          setShowProfilePanel(true);
        }
        if (e.key === "Escape") {
          setShowProfilePanel(false);
        }
      }}
      onKeyDown={(e) => {
        if (isMobileScreenSize) {
          handleMobileKeyNavigation(e);
        }
        if (e.shiftKey && e.key === "Tab") {
          setShowProfilePanel(false);
        }
      }}
      aria-controls={isMobileScreenSize ? mobileMenuId : profileId}
      aria-label={intl.formatMessage({ id: "uh.PROFILE" })}
      aria-expanded={
        isMobileScreenSize ? showMobileProfilePanel : showProfilePanel
      }
      aria-haspopup="true"
      role={isMobileScreenSize ? "button" : ""}
      tabIndex={0}
    >
      {mailCount > 0 && screenWidth < SCREEN_WIDTH.md && (
        <div className="absolute -top-px left-5 z-30 size-2 rounded-full bg-grape-jelly"></div>
      )}
      <div className="relative size-7 overflow-hidden rounded-3xl bg-grey-hair transition-shadow duration-[.3s] ease-in group-hover:[box-shadow:0_6px_4px_0_rgb(0_0_0/10%),_0_0_1px_0_rgb(0_0_0/10%)] md:z-10 md:size-10">
        <IntermediateLoadingAvatar hide={transitionEnded} />
        <ProfileIcon
          profileData={profileData}
          profileBgColor={profileBgColor}
          useAlphatar={useAlphatar}
          transitionEnded={transitionEnded}
          onAvatarError={() => setUseAlphatar(true)}
          onTransitionEnd={() => setTransitionEnded(true)}
        />
      </div>
      <ProfilePanel
        crumb={crumb}
        id={profileId}
        isExperimentalHeader={isExperimentalHeader}
        isMobileScreenSize={isMobileScreenSize}
        isUH4={isUH4}
        mailCount={mailCount}
        profileData={profileData}
        profileId={profileButtonId}
        handleProfilePanelHover={handleProfilePanelHover}
        handleShowAccountPicker={handleShowAccountPicker}
        handleShowMobileProfilePanel={handleShowMobileProfilePanel}
        showAccountPicker={showAccountPicker}
        showMobileProfilePanel={showMobileProfilePanel}
        showPFS={enablePFS}
        showProfilePanel={showProfilePanel}
      />
      {/* Mobile background overlay */}
      {showMobileProfilePanel && (
        <div
          aria-hidden="true"
          className="fixed inset-0 z-20 overscroll-auto bg-midnight opacity-60"
          onClick={(e) => {
            e.stopPropagation();
            setShowMobileProfilePanel(false);
          }}
        />
      )}
    </div>
  );
};
