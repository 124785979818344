interface urlParamsObj {
  activity?: string;
  crumb?: string;
  doneLink?: string;
  lang?: string;
  region?: string;
  site?: string;
  spaceId?: number;
  userIdentifier?: string;
}

export const isDefaultProfilePic = (imageUrl: string): boolean =>
  (imageUrl ?? "").includes("default_user_profile_pic");

// Check if all characters are in the CJK range
// CJK: Chinese, Japanese and Korean
export const isNameCJK = (firstName: string, lastName: string) => {
  const checkingStr = firstName + lastName;

  for (let i = 0; i < checkingStr.length; ++i) {
    const charCodeAt = checkingStr.charCodeAt(i);

    // CJK unicode range : [ 0x4E00 ~ 0x9FFF, 0x3400 ~ 0x4DBF ];
    if (
      (charCodeAt < 0x4e00 || charCodeAt > 0x9fff) &&
      (charCodeAt < 0x3400 || charCodeAt > 0x4dbf) &&
      (charCodeAt < 0xac00 || charCodeAt > 0xd7af) // Hangul Syllables unicode range
    ) {
      return false;
    }
  }

  return true;
};

export const getSignOutURL = (site: string, doneUrl: string) => {
  const url = new URL("https://login.yahoo.com/account/logout?logout_all=1");

  if (site) {
    url.searchParams.set("src", site);
  }

  if (doneUrl) {
    url.searchParams.set(".done", doneUrl);
  }
  const signOutURL = url.toString();
  return signOutURL;
};

export const formatURL = (url: URL, urlObject: urlParamsObj) => {
  const {
    site,
    lang,
    region,
    crumb,
    userIdentifier,
    activity,
    spaceId,
    doneLink,
  } = urlObject;
  if (site) {
    url.searchParams.set("src", site);
  }
  if (lang) {
    url.searchParams.set("lang", lang);
  }
  if (region) {
    url.searchParams.set("intl", region);
  }
  if (crumb) {
    url.searchParams.set(".crumb", crumb);
  }
  if (userIdentifier) {
    url.searchParams.set("login", userIdentifier);
  }
  if (activity) {
    url.searchParams.set("activity", activity);
  }
  if (spaceId) {
    url.searchParams.set("pspid", spaceId.toString());
  }
  if (doneLink) {
    url.searchParams.set(".done", doneLink);
  }
  return url.toString();
};
