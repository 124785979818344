import classNames from "classnames";
import { type FC, lazy } from "react";
import { useIntl } from "react-intl";
import { type Partner } from "../../lib/partners";

type SVG = React.FC<React.SVGAttributes<SVGElement>>;

interface Props {
  imgClassnames?: string;
  lang: string;
  partner?: Partner;
  stacked?: boolean;
  site: string;
}

const Logos: {
  [key: string]: SVG;
} = {
  "att-en-us": lazy(() => import("../../svgs/att.svg")),
  "att-es-us": lazy(() => import("../../svgs/att-es-us.svg")),
  "creators-en-us": lazy(() => import("../../svgs/yahoo-creators.svg")),
  "entertainment-en-us": lazy(
    () => import("../../svgs/yahoo-entertainment.svg"),
  ),
  "entertainment-stacked-en-us": lazy(
    () => import("../../svgs/yahoo-entertainment-stacked.svg"),
  ),
  "entertainment-stacked-es-us": lazy(
    () => import("../../svgs/yahoo-entertainment-stacked-es-us.svg"),
  ),
  "frontpage-en-us": lazy(() => import("../../svgs/yahoo.svg")),
  "lifestyle-en-us": lazy(() => import("../../svgs/yahoo-life.svg")),
  "lifestyle-stacked-en-us": lazy(
    () => import("../../svgs/yahoo-life-stacked.svg"),
  ),
  "local-en-us": lazy(() => import("../../svgs/yahoo.svg")),
  "mail-en-us": lazy(() => import("../../svgs/yahoo-mail.svg")),
  "news-en-us": lazy(() => import("../../svgs/yahoo-news-logo.svg")),
  "news-stacked-en-us": lazy(() => import("../../svgs/yahoo-news-stacked.svg")),
  "news-stacked-es-us": lazy(
    () => import("../../svgs/yahoo-news-stacked-es-us.svg"),
  ),
  "shopping-en-us": lazy(() => import("../../svgs/yahoo-shopping.svg")),
  "sports-en-au": lazy(() => import("../../svgs/yahoo-sport.svg")),
  "sports-en-ca": lazy(() => import("../../svgs/yahoo-sports.svg")),
  "sports-en-gb": lazy(() => import("../../svgs/yahoo-sport.svg")),
  "sports-en-us": lazy(() => import("../../svgs/yahoo-sports.svg")),
  "sports-stacked-en-us": lazy(
    () => import("../../svgs/yahoo-sports-stacked.svg"),
  ),
  "sports-stacked-es-us": lazy(
    () => import("../../svgs/yahoo-sports-stacked-es-us.svg"),
  ),
  "tech-en-us": lazy(() => import("../../svgs/yahoo-tech.svg")),
};

const getLogo = (
  partner: Partner,
  site: string,
  lang: string,
  stacked: boolean,
): SVG => {
  const partnerNeedle = `${partner}-${lang}`;
  const stackedNeedle = `${site}-stacked-${lang}`;
  const nonStackedNeedle = `${site}-${lang}`;
  const fallbackNeedle = `news-en-us`;
  if (partner !== "none" && Logos[partnerNeedle]) {
    return Logos[partnerNeedle];
  }
  if (stacked && Logos[stackedNeedle]) {
    return Logos[stackedNeedle];
  }
  if (Logos[nonStackedNeedle]) {
    return Logos[nonStackedNeedle];
  }
  return Logos[fallbackNeedle];
};

export const LogoImg: FC<Props> = ({
  imgClassnames = "h-6 md:h-7",
  lang = "en-US",
  partner = "none",
  site = "creators",
  stacked = false,
}) => {
  const intl = useIntl();
  lang = lang.toLowerCase();
  site = site.toLowerCase();

  const Logo: SVG = getLogo(partner, site, lang, stacked);
  const ariaLabelKey =
    partner !== "none"
      ? `uh.PARTNER_${partner.toUpperCase()}_LOGO_LABEL`
      : `uh.SITE_${site.toUpperCase()}`;
  return (
    <Logo
      className={classNames("fill-uh-purple", imgClassnames)}
      aria-hidden={false}
      aria-label={intl.formatMessage({
        defaultMessage: "Yahoo",
        id: `${ariaLabelKey}`,
      })}
      role="img"
    />
  );
};
