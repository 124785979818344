import { I13nAnchor } from "@yahoo-creators/i13n";
import { beaconClick } from "@yahoo-creators/i13n/components/Rapid";
import classNames from "classnames";
import { type FC, useEffect, useState, useCallback } from "react";
import { useIntl } from "react-intl";
import { Logo } from "../Logo/Logo";
import { MoreMenu } from "../MoreMenu/MoreMenu";
import { MoreMenuV2 } from "../MoreMenu/MoreMenuV2";
import { RollOverMenu } from "../RolloverMenu";

const tabAndHover =
  "active:font-bold hover:text-grape-jelly focus-visible:rounded-[1px] focus-visible:text-grape-jelly focus-visible:outline focus-visible:outline-4 focus-visible:outline-offset-8 focus-visible:outline-[rgb(96_1_210/50%)]";

type TopLevelLinkProps = {
  slk: string;
  href: string;
  widthAuto?: boolean;
  hideOn?: "sm" | "lg";
  uhRolloverMenus?: boolean;
  site?: string;
};

const navItemsLeft: TopLevelLinkProps[] = [
  {
    href: "https://creators.yahoo.com/",
    slk: "creators",
  },
  { href: "https://finance.yahoo.com/", slk: "finance" },
  {
    hideOn: "sm",
    href: "https://sports.yahoo.com/",
    slk: "sports",
  },
];

const navItemsRight: TopLevelLinkProps[] = [
  {
    hideOn: "sm",
    href: "https://www.yahoo.com/entertainment/",
    slk: "entertainment",
    widthAuto: true,
  },
  {
    hideOn: "sm",
    href: "https://www.yahoo.com/games/",
    slk: "games",
  },
  {
    hideOn: "lg",
    href: "https://sports.yahoo.com/",
    slk: "sports",
  },
];

const TopLevelLink: FC<TopLevelLinkProps> = ({
  slk,
  site,
  href,
  widthAuto = false,
  hideOn,
  uhRolloverMenus = false,
}) => {
  const intl = useIntl();
  const [isRolloverMenuOpen, setIsRolloverMenuOpen] = useState(false);
  const [isRolloverLinkHovered, setIsRolloverLinkHovered] = useState(false);
  const [isRolloverMenuHovered, setIsRolloverMenuHovered] = useState(false);

  const fireRapidBeacon = (elm: string, slk: string) => {
    const rolloverMenuYlk = {
      elm,
      itc: 1,
      pkgt: "top",
      sec: "ybar",
      slk,
      subsec: "navrail",
    };
    beaconClick(rolloverMenuYlk);
  };

  const handleRollOverMenuHover = useCallback(
    (isMenuHovered: boolean) => {
      if (isMenuHovered) {
        setIsRolloverMenuHovered(true);
        fireRapidBeacon("close", slk);
      } else {
        setIsRolloverMenuHovered(false);
        fireRapidBeacon("expand", slk);
      }
    },
    [slk],
  );

  useEffect(() => {
    if (!isRolloverLinkHovered && !isRolloverMenuHovered) {
      setIsRolloverMenuOpen(false);
    } else {
      setIsRolloverMenuOpen(true);
    }
  }, [isRolloverMenuHovered, isRolloverLinkHovered]);

  return (
    <div
      className={classNames(
        "flex h-10 items-center justify-center gap-2 py-2",
        widthAuto ? "w-auto" : "w-16",
        {
          "max-[1279px]:hidden": hideOn == "sm",
          "min-[1280px]:hidden": hideOn == "lg",
        },
      )}
    >
      <div
        className="relative inline-flex flex-col items-start justify-center gap-0.5"
        onMouseEnter={() => {
          setIsRolloverLinkHovered(true);
        }}
        onMouseLeave={() => {
          setTimeout(() => setIsRolloverLinkHovered(false), 100);
        }}
        onFocus={() => {
          setIsRolloverLinkHovered(true);
        }}
        onBlur={() => {
          setTimeout(() => setIsRolloverLinkHovered(false), 100);
        }}
      >
        <I13nAnchor
          dataYlk={{
            elm: "navcat",
            itc: 0,
            pkgt: "top",
            sec: "ybar",
            slk,
            subsec: "navrail",
          }}
          href={href}
          className={classNames(
            tabAndHover,
            slk === site || (site === "frontpage" && slk === "creators")
              ? "font-bold"
              : "font-medium",
            "text-[1.06rem] tracking-tight text-gray-800",
          )}
        >
          {intl.formatMessage({ id: `uh.PROPERTY_${slk.toUpperCase()}` })}
        </I13nAnchor>
        {uhRolloverMenus && isRolloverMenuOpen && (
          <RollOverMenu site={slk} handleHover={handleRollOverMenuHover} />
        )}
      </div>
    </div>
  );
};

const TopLevelLinkSet: FC<{
  links: TopLevelLinkProps[];
  site?: string;
  uhRolloverMenus: boolean;
}> = ({ links, site, uhRolloverMenus }) => {
  return links.map(({ slk, href, widthAuto, hideOn }) => (
    <TopLevelLink
      slk={slk}
      href={href}
      site={site}
      widthAuto={widthAuto}
      hideOn={hideOn}
      uhRolloverMenus={uhRolloverMenus}
      key={`TopLevelLink-${slk}`}
    />
  ));
};

export const TopNavWithLogo: FC<{
  lang: string;
  isBot: boolean;
  site: string | undefined;
  uhRolloverMenus: boolean;
}> = ({ lang, isBot, site, uhRolloverMenus }) => {
  return (
    <div className="flex h-[62] shrink grow basis-0 items-center justify-center gap-4">
      <div className="flex h-10 shrink grow basis-0 items-center justify-end gap-8">
        <TopLevelLinkSet
          links={navItemsLeft}
          site={site}
          uhRolloverMenus={uhRolloverMenus}
        />
      </div>
      <div className="ml-8 mr-7 inline-flex min-h-[60px] w-[172px] flex-col items-center justify-center gap-2.5">
        <Logo
          className={classNames(
            "inline-flex w-[172px] items-center justify-center",
            tabAndHover,
          )}
          imgClassnames="h-12"
          lang={lang}
          partner="none"
          site={site}
        />
      </div>
      <div className="flex h-10 shrink grow basis-0 items-center justify-start gap-7">
        <TopLevelLinkSet
          links={navItemsRight}
          site={site}
          uhRolloverMenus={uhRolloverMenus}
        />

        <div className="flex items-center justify-start gap-1">
          <div className="flex w-16 items-center justify-center gap-2 border-gray-800 py-2">
            <div className="inline-flex flex-col items-start justify-center gap-0.5">
              {uhRolloverMenus ? (
                <MoreMenuV2
                  isBot={isBot}
                  isExperimentalHeader={true}
                  uhRolloverMenus={uhRolloverMenus}
                />
              ) : (
                <MoreMenu
                  isBot={isBot}
                  isExperimentalHeader={true}
                  uhRolloverMenus={uhRolloverMenus}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
