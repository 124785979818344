import { type FC } from "react";

interface Props {
  hide: boolean;
  profileFirstName: string;
}

export const ProfileAlphatar: FC<Props> = (props) => {
  const firstInitial = props.profileFirstName?.[0]?.toUpperCase() ?? "";

  return props.hide ? null : firstInitial;
};
