/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { I13nButton } from "@yahoo-creators/i13n";
import { beaconClick } from "@yahoo-creators/i13n/components/Rapid";
import { SCREEN_WIDTH, useInView } from "@yahoo-news/util";
import classNames from "classnames";
import { useState, type FC, useRef, useEffect, useCallback } from "react";
import { useIntl } from "react-intl";
import CloseButton from "../svgs/close-btn.svg";
import SearchBackButton from "../svgs/search-back-btn.svg";
import SearchIcon from "../svgs/search.svg";
import { AnimatedSearchBar } from "./AnimatedSeachBar";
import { fetchSuggestions } from "./SearchAssist/plugins/gossip";
import { fetchSuggestions as fetchShoppingSuggestions } from "./SearchAssist/plugins/shopping";
import { SearchAssist } from "./SearchAssist/SearchAssist";
import {
  GOSSIP_MARKER_TRENDING,
  GOSSIP_MARKER_SEARCH_HISTORY,
  type SearchAssistDropdownItem,
  type SuggestionsDataFetcher,
} from "./SearchAssist/types";
import { SearchButton } from "./SearchButton";

interface Props {
  animateSearchBar?: boolean;
  crumb: string;
  customSuggestionsDataFetcher?: SuggestionsDataFetcher;
  hasSearchGradientStyle?: boolean;
  initialSearchInputFocus?: boolean;
  isFullPageSearchOpen: boolean;
  isModal?: boolean;
  isStickyHeader: boolean;
  lang?: string;
  noSearchAssistFetch: boolean;
  onQueryChange: (newquery: string, noFetch?: boolean) => void;
  onSearchGradientStyleChange?: (hasSearchGradientStyle: boolean) => void;
  overrideOpen?: typeof window.open;
  partner?: string;
  query: string;
  setIsFullPageSearchOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  site?: string;
  uhSearchGradientStyle?: boolean;
}

const fr2CodeDeviants: { [key: string]: string } = {
  entertainment: "ent",
  frontpage: "fp",
};

export const Search: FC<Props> = ({
  animateSearchBar,
  crumb,
  customSuggestionsDataFetcher,
  hasSearchGradientStyle,
  isFullPageSearchOpen,
  isModal = false,
  initialSearchInputFocus,
  isStickyHeader,
  lang,
  noSearchAssistFetch,
  onQueryChange,
  onSearchGradientStyleChange,
  overrideOpen,
  partner,
  query,
  setIsFullPageSearchOpen,
  site = "news",
  uhSearchGradientStyle,
}) => {
  const intl = useIntl();
  const ref = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const isShopping = site === "shopping";
  const placeHolderId = isShopping
    ? "uh.SHOPPING_SEARCH_PLACEHOLDER"
    : "uh.WEB_SEARCH_PLACEHOLDER";
  const [isSearchAssistOpen, setIsSearchAssistOpen] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [searchSuggestions, setSearchSuggestions] =
    useState<SearchAssistDropdownItem[]>();
  const listItemRef = useRef<Array<HTMLLIElement>>([]);
  const [activeDescendantIndex, setActiveDescendantIndex] = useState(-1);
  const searchButtonRef = useRef<HTMLButtonElement>(null);
  const clearButtonRef = useRef<HTMLButtonElement>(null);
  const inView = useInView(ref);
  const searchfocusRef = useRef(initialSearchInputFocus);
  const currScrollRef = useRef(0);
  const getSearchHost = () => {
    lang = lang?.toLowerCase();
    const defaultHost = "search.yahoo.com";
    let host = site !== "shopping" ? defaultHost : "shopping.yahoo.com";
    if (!partner || partner === "none") {
      return host;
    }
    switch (partner) {
      case "att":
        host =
          lang === "es-us"
            ? "espanol.search.yahoo.com/yhs"
            : "search.yahoo.com/yhs";
        break;
    }
    return host;
  };
  const searchHost = getSearchHost();
  const frCode = site === "frontpage" ? "yfp-t" : `uh3_${site}_web`;
  const fr2Code = fr2CodeDeviants[site]
    ? `p:${fr2CodeDeviants[site]}`
    : `p:${site}`;
  const tsrc = site === "frontpage" ? "yfp-hrtab" : frCode;
  const [showSearchLabelAnimation, setShowSearchLabelAnimation] =
    useState(animateSearchBar);

  const typeRegex = /[?&]type=([^&#]*)/;
  const getTypeFromQueryString = () => {
    const queryString = typeRegex.exec(window.location.search);
    return queryString ? queryString[1] : null;
  };

  const frCodeRegex = /[?&]fr=([^&#]*)/;
  const getFrCodeFromQueryString = () => {
    const queryString = frCodeRegex.exec(window.location.search);
    return queryString ? queryString[1] : null;
  };
  const appendModalAndOrSAToFRCode = (
    rv: string,
    isModal: boolean,
    isSuggestionClick: boolean,
  ) => {
    return `${rv}${isModal ? "-m" : ""}${isSuggestionClick ? "-s" : ""}`;
  };
  const getFinalFrCode = (m: number, isSuggestionClick: boolean) => {
    if (site !== "frontpage") {
      return appendModalAndOrSAToFRCode(frCode, isModal, isSuggestionClick);
    }
    const frCodeFromQueryString = getFrCodeFromQueryString();
    if (m === GOSSIP_MARKER_TRENDING) {
      if (frCodeFromQueryString) {
        return `${appendModalAndOrSAToFRCode(frCodeFromQueryString, isModal, isSuggestionClick)}-tn`;
      }
      return "fp-tts-sa";
    }

    if (frCodeFromQueryString) {
      return appendModalAndOrSAToFRCode(
        frCodeFromQueryString,
        isModal,
        isSuggestionClick,
      );
    }
    return appendModalAndOrSAToFRCode(frCode, isModal, isSuggestionClick);
  };
  const getFinalFr2Code = (
    m: number,
    isSuggestionClick: boolean,
    returnValue: string,
  ) => {
    if (m === GOSSIP_MARKER_TRENDING) {
      return `${returnValue},ct:tn,kt:organic,sa_mk:${GOSSIP_MARKER_TRENDING}`;
    }
    if (m === GOSSIP_MARKER_SEARCH_HISTORY) {
      return `${returnValue},ct:history,kt:none,sa_mk:${GOSSIP_MARKER_SEARCH_HISTORY}`;
    }
    if (m !== 0) {
      return `${returnValue}${isSuggestionClick ? ",ct:sa,kt:none" : ""},sa_mk:${m}`;
    }

    return `${returnValue}${isSuggestionClick ? ",ct:sa,kt:none" : ""}`;
  };
  const getFinaleTsrc = (
    m: number,
    isSuggestionClick: boolean,
    returnValue: string,
  ) => {
    if (site === "frontpage") {
      returnValue = "yfp-hrmob";
    }
    return `${returnValue}${isSuggestionClick ? "-s" : ""}`;
  };
  function onSubmit(
    e?:
      | React.FormEvent<HTMLFormElement>
      | React.KeyboardEvent<HTMLInputElement>,
    marker = 0,
  ) {
    e?.preventDefault();
    setIsSearchAssistOpen(false);
    const fr2 =
      e &&
      (e.type === "submit" ||
        ((e as React.KeyboardEvent<HTMLInputElement>).key &&
          (e as React.KeyboardEvent<HTMLInputElement>).key === "Enter"))
        ? ",m:sb"
        : ",m;sa";

    const isSuggestionClick = fr2.indexOf(",m;sa") > -1;
    if (
      !e ||
      (e &&
        (e as React.KeyboardEvent<HTMLInputElement>).key &&
        (e as React.KeyboardEvent<HTMLInputElement>).key === "Enter")
    ) {
      const dataYlk = {
        elm: "search",
        elmt: isSuggestionClick ? "srch-asst" : "kybrd",
        itc: 0,
        sec: "ybar",
        slk: "websrch",
        subsec: "searchbox",
        tar: "search.yahoo.com",
      };
      beaconClick(dataYlk);
    }
    // As a test seam, we need to separate the window.open call into a separate function
    // so that we can assert that we're calling window.open with the correct arguments.
    const open = overrideOpen || window.open;

    // Two race conditions can occur here:
    // 1. The user may type a query before react is booted up - this is why we
    //    have to pass ref.current.value to the initial query state.
    // 2. Even with a 100ms timeout, the react query state is not updated
    //    with the clicked selection from the search assist.
    const p = ref.current?.value || query;
    const searchUrl = new URL(`https://${searchHost}/search`);
    searchUrl.searchParams.set("p", p);
    if (site !== "shopping") {
      searchUrl.searchParams.set(
        "fr",
        getFinalFrCode(marker, isSuggestionClick),
      );
      searchUrl.searchParams.set(
        "fr2",
        getFinalFr2Code(marker, isSuggestionClick, fr2Code + fr2),
      );
      if (isFullPageSearchOpen && marker !== GOSSIP_MARKER_TRENDING) {
        searchUrl.searchParams.set(
          ".tsrc",
          getFinaleTsrc(marker, isSuggestionClick, tsrc),
        );
      }
      if (site === "frontpage") {
        const type = getTypeFromQueryString();
        if (type) {
          searchUrl.searchParams.set("type", type);
        }
        searchUrl.searchParams.set("fp", "1");
      }
      if (partner === "att") {
        searchUrl.searchParams.set("hspart", "att");
        searchUrl.searchParams.set("type", "sbc_dsl");
        searchUrl.searchParams.set("hsimp", "yhs-att_001");
      }
    } else {
      searchUrl.searchParams.set("renderBySimilarity", "1");
    }
    const searchString = searchUrl.toString();
    if (!isFullPageSearchOpen) {
      open(searchString, "_blank");
    } else {
      open(searchString, "_parent");
      onQueryChange("");
      setIsFullPageSearchOpen?.(false);
      setIsSearchAssistOpen(false);
    }
    return false;
  }
  function handleInputKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (!event || !searchSuggestions) {
      return;
    }
    const inputEvent = event as React.KeyboardEvent<HTMLInputElement>;
    let lastIndex = activeDescendantIndex;
    switch (inputEvent.key) {
      case "Backspace":
        setActiveDescendantIndex(-1);
        break;
      case "ArrowUp":
        inputEvent.preventDefault();
        // set last search item query to searchbar input
        setActiveDescendantIndex((prev) => {
          lastIndex = Math.max(prev - 1, 0);
          if (lastIndex === 0 && prev <= 0) {
            lastIndex = searchSuggestions.length - 1;
          }
          return lastIndex;
        });
        onQueryChange(
          (searchSuggestions &&
            searchSuggestions[lastIndex] &&
            searchSuggestions[lastIndex].title) ||
            "",
          true,
        );
        break;
      case "ArrowDown":
        inputEvent.preventDefault();
        setActiveDescendantIndex((prev) => {
          lastIndex = Math.min(prev + 1, searchSuggestions.length - 1);
          /* set to loop back to first search item */
          if (prev === lastIndex) {
            lastIndex = 0;
          }
          return lastIndex;
        });
        // set first search item query to searchbar input
        onQueryChange(
          (searchSuggestions &&
            searchSuggestions[lastIndex] &&
            searchSuggestions[lastIndex].title) ||
            "",
          true,
        );
        break;
      case "Tab":
        // set focus to the close button or search button
        inputEvent.preventDefault();
        // if there is a search query then there is a clear button
        if (ref.current && ref.current.value) {
          clearButtonRef.current && clearButtonRef.current.focus();
          setActiveDescendantIndex(-1);
        } else {
          searchButtonRef.current && searchButtonRef.current.focus();
        }
        break;
    }
  }
  // Cleanup list item array ref
  useEffect(() => {
    return () => {
      listItemRef.current = [];
    };
  }, []);
  // mobile search focus on faux search click
  useEffect(() => {
    if (isFullPageSearchOpen && ref.current) {
      ref.current.focus({ preventScroll: true });
    }
  }, [isFullPageSearchOpen]);

  // gives desktop search initial focus on load
  useEffect(() => {
    if (ref.current && searchfocusRef.current && inView) {
      ref.current.focus({ preventScroll: true });
      // set to false, to give focus only on initial load
      searchfocusRef.current = false;
    } else if (ref.current && !searchfocusRef.current && !inView) {
      ref.current?.blur();
    }
  }, [isStickyHeader, inView, searchfocusRef]);

  useEffect(() => {
    const isMobile = window.innerWidth < SCREEN_WIDTH.md;
    if (isSearchAssistOpen) {
      document.body.classList.add("overflow-hidden");
      if (isFullPageSearchOpen && isMobile) {
        currScrollRef.current = window.scrollY;
        document.body.classList.add("fixed");
      }
    } else {
      document.body.classList.remove("overflow-hidden");
      if (!isFullPageSearchOpen && isMobile) {
        document.body.classList.remove("fixed");
        window.scrollTo(0, currScrollRef.current);
      }
    }
  }, [isSearchAssistOpen, isFullPageSearchOpen]);

  const closeSearch = useCallback(
    (event: KeyboardEvent | React.MouseEvent) => {
      event.preventDefault();
      if (setIsFullPageSearchOpen && isFullPageSearchOpen) {
        setIsFullPageSearchOpen(false);
      }
      if (isSearchAssistOpen) {
        setIsSearchAssistOpen(false);
        setActiveDescendantIndex(-1);
      }
    },
    [isFullPageSearchOpen, isSearchAssistOpen, setIsFullPageSearchOpen],
  );

  const clearSearch = () => {
    ref.current && ref.current.focus({ preventScroll: true });
    onQueryChange("");
  };

  const handleSearchFocus = (e: React.KeyboardEvent<HTMLFormElement>) => {
    stopSearchLabelAnimation();
    let element = e.target as HTMLElement;
    let eventKey = e.key;
    if (element.tagName === "INPUT" && eventKey === "Tab") {
      setIsSearchFocused(true);
    }
    if (element.tagName === "BUTTON" && eventKey === "Enter") {
      clearSearch();
    }
  };

  const tabStyle =
    "focus-visible:rounded-full focus-visible:text-grape-jelly focus-visible:outline focus-visible:outline-4 focus-visible:outline-[rgb(96_1_210/50%)]";
  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        closeSearch(event);
      }
    };

    document.addEventListener("keyup", handleEscapeKey, false);
    return () => {
      document.removeEventListener("keyup", handleEscapeKey, false);
    };
  }, [closeSearch]);

  const handleSearchSuggestionsFromSearchAssist = useCallback(
    (suggestions: string | any[]) => {
      if (!suggestions.length) {
        setIsSearchAssistOpen(false);
        setActiveDescendantIndex(-1);
      }
      /* TODO: Determine if we really need to loop through the suggestions
       * and push them into a new array. We should be able to just set the
       * state to the suggestions array directly.
       */
      const items = [];
      for (let item = 0; item < suggestions.length; item++) {
        items.push(suggestions[item]);
      }
      setSearchSuggestions(items);
    },
    [],
  );

  const stopSearchLabelAnimation = () => {
    // Save the time to local storage
    // set the state to false to stop the animation
    setShowSearchLabelAnimation(false);
    try {
      localStorage.setItem(
        "uh.searchLabelAnimationEndTime",
        Date.now().toString(),
      );
    } catch (_) {}
  };

  useEffect(() => {
    const handleResize = () => {
      if (isSearchAssistOpen && window.innerWidth >= SCREEN_WIDTH.md) {
        setIsSearchAssistOpen(false);
        setActiveDescendantIndex(-1);
        setIsFullPageSearchOpen?.(false);
      }
    };

    window.addEventListener("resize", handleResize, false);
    return () => {
      window.removeEventListener("resize", handleResize, false);
    };
  }, [isFullPageSearchOpen, setIsFullPageSearchOpen, isSearchAssistOpen]);

  useEffect(() => {
    let timeSinceAnimationPlayed = 0;
    try {
      timeSinceAnimationPlayed =
        Date.now() -
        Number(localStorage.getItem("uh.searchLabelAnimationEndTime"));
    } catch (_) {}

    // Do not render the search label animation if it has been played in the last 4 hours
    if (timeSinceAnimationPlayed < 3600 * 4 * 1000) {
      setShowSearchLabelAnimation(false);
    }
  }, []);

  // isStickyHeader is used to determine the sticky header. If it is true, the header is sticky.
  // isSearchFocused is used to determine if the search bar is focused. This happens when tab is used to select the search bar.
  // isSearchAssistOpen is used to determine if the search assist is open.
  // isFullPageSearchOpen is true for smaller screen sizes (mobile) where screen height is greater than screen width.

  const getSearchAssist = () => {
    return (
      <SearchAssist
        activeDescendantId={
          searchSuggestions && searchSuggestions[activeDescendantIndex]
            ? searchSuggestions[activeDescendantIndex].id
            : undefined
        }
        crumb={crumb}
        isFullPageSearchOpen={isFullPageSearchOpen}
        onSuggestionSelect={(query: string, marker: number) => {
          onQueryChange(query);
          setIsSearchAssistOpen(false);
          setTimeout(() => onSubmit(undefined, marker), 100);
        }}
        lang={lang}
        query={query}
        noFetch={noSearchAssistFetch}
        isSearchAssistOpen={isSearchAssistOpen}
        setSearchSuggestions={handleSearchSuggestionsFromSearchAssist}
        inputRef={ref}
        clearButtonRef={clearButtonRef}
        listItemRef={listItemRef}
        searchAssistDataFetcher={
          customSuggestionsDataFetcher
            ? customSuggestionsDataFetcher
            : site === "shopping"
              ? fetchShoppingSuggestions
              : fetchSuggestions
        }
      />
    );
  };

  const getSearchForm = () => {
    return (
      <form
        ref={formRef}
        method="get"
        className={classNames(
          "z-10 h-10 justify-start gap-2.5 py-1 pl-6 pr-1 font-yahoobeta caret-purple-700 outline outline-1 outline-pebble [transition:height_.2s_ease-in-out] md:inline-flex md:h-[52px]",
          hasSearchGradientStyle
            ? "hover:outline-0"
            : "[transition:outline_.3s_ease-in-out] hover:outline-2 hover:drop-shadow-md",
          isStickyHeader &&
            !hasSearchGradientStyle &&
            "w-full md:mr-6 xl:mr-10",
          isStickyHeader && hasSearchGradientStyle && "w-full",
          !isStickyHeader && "mr-0 w-[48vw] min-w-[600px] max-w-[712px]",
          isShopping && "lg:mr-7 xl:!mr-8 2xl:mr-10",
          isSearchFocused &&
            (!hasSearchGradientStyle
              ? "has-[input:focus-visible]:box-content has-[input:focus-visible]:border-4 has-[input:focus-visible]:border-[rgb(96_1_210/50%)] has-[input:focus-visible]:outline-0 md:rounded-t-[26px]"
              : "md:rounded-t-[26px]"),
          isSearchAssistOpen
            ? "md:z-30 md:bg-white md:pl-4"
            : "md:rounded-[100px] md:bg-marshmallow",
          isSearchAssistOpen &&
            (hasSearchGradientStyle ? "outline-0" : "outline-2 outline-pebble"),
          isSearchAssistOpen &&
            !isSearchFocused &&
            (hasSearchGradientStyle
              ? "md:rounded-t-[26px]"
              : isStickyHeader
                ? "md:rounded-t-[23px]"
                : "md:rounded-t-[26px]"),
          isFullPageSearchOpen
            ? "fixed inset-0 flex h-auto max-w-[767px] items-start bg-white"
            : "relative hidden max-w-[620px] items-center",
          !isSearchFocused &&
            isSearchAssistOpen &&
            hasSearchGradientStyle &&
            (isStickyHeader
              ? "outline-transparent md:rounded-t-[23px]"
              : "outline-transparent md:rounded-t-[26px]"),
        )}
        role="search"
        onSubmit={onSubmit}
        onKeyUp={(e) => handleSearchFocus(e)}
        onKeyDown={() => setIsSearchFocused(false)}
        onBlur={(e) => {
          if (
            isSearchAssistOpen &&
            formRef.current &&
            e.relatedTarget &&
            !formRef.current.contains(e.relatedTarget as Node)
          ) {
            setIsSearchAssistOpen(false);
            setActiveDescendantIndex(-1);
            // only remove the gradient style if it is enabled
            if (hasSearchGradientStyle) {
              onSearchGradientStyleChange?.(false);
            }
          }
          setIsSearchFocused(false);
        }}
      >
        <label
          className="pointer-events-none absolute -left-full -top-full opacity-0"
          htmlFor="uh-sbq"
        >
          {intl.formatMessage({ id: "uh.SEARCH_LABEL" })}
        </label>
        <div className="flex h-14 shrink grow basis-0 items-center justify-start md:h-5">
          {
            // Only render these labels if animateSearchBar feature is enabled
            // and the animation has not been played in the last 4 hours
            showSearchLabelAnimation && (
              <AnimatedSearchBar
                searchSuggestions={searchSuggestions || []}
                stopSearchLabelAnimation={stopSearchLabelAnimation}
              />
            )
          }
          <I13nButton
            dataYlk={{
              elm: "btn",
              itc: 1,
              sec: "ybar",
              slk: "back-close-srch",
              subsec: "searchbox",
            }}
            type="button"
            onClick={closeSearch}
            className={classNames(isFullPageSearchOpen ? "-ml-2" : "hidden")}
            aria-label={intl.formatMessage({ id: "uh.BACK" })}
          >
            <SearchBackButton />
          </I13nButton>
          {isSearchAssistOpen && !isFullPageSearchOpen && (
            <SearchIcon
              height={16}
              width={16}
              aria-hidden={true}
              className="fill-dolphin"
            />
          )}
          <input
            aria-autocomplete="both"
            aria-activedescendant={
              searchSuggestions && activeDescendantIndex > -1
                ? searchSuggestions[activeDescendantIndex].id
                : undefined
            }
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            className={classNames(
              "shrink grow basis-0 border-none bg-transparent text-base font-normal leading-tight text-gray-800 outline-none [transition:margin-left_.2s_ease-in-out] placeholder:text-battleship focus-visible:outline-none md:h-[52px] [&::-webkit-search-cancel-button]:hidden",
              { "ml-[18px]": isSearchAssistOpen && !isFullPageSearchOpen },
              { "ml-4": isFullPageSearchOpen },
            )}
            id="uh-sbq"
            onChange={(e) => {
              onQueryChange(e.target.value);
              if (!isSearchAssistOpen) {
                setIsSearchAssistOpen(true);
                stopSearchLabelAnimation();
              }
            }}
            onKeyUp={(e) => {
              if (e.key === "Tab") {
                setIsSearchAssistOpen(true);
                stopSearchLabelAnimation();
              }
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              // allows page scroll by space key if input is focused, searchAssist is not open, and no search query
              if (!isSearchAssistOpen && e.key === " " && query.length === 0) {
                ref.current?.blur();
              }
              if (e.key === "Enter") {
                e.preventDefault();
                onSubmit(e);
              } else if (isSearchAssistOpen) {
                handleInputKeyDown(e);
              }
            }}
            onClick={() => {
              setIsSearchAssistOpen(isShopping ? query.length > 0 : true);
              stopSearchLabelAnimation();
            }}
            placeholder={
              showSearchLabelAnimation
                ? ""
                : intl.formatMessage({
                    id: placeHolderId,
                  })
            }
            onFocus={() => {
              if (isFullPageSearchOpen) {
                setIsSearchAssistOpen(
                  site === "shopping" ? query.length > 0 : true,
                );
                stopSearchLabelAnimation();
              }
            }}
            ref={ref}
            type="search"
            value={query}
          />
          <I13nButton
            dataYlk={{
              elm: "btn",
              itc: 1,
              sec: "ybar",
              slk: "clear-srch",
              subsec: "searchbox",
            }}
            type="button"
            ref={clearButtonRef}
            onClick={(e) => {
              e.preventDefault();
              clearSearch();
            }}
            onKeyDown={(e) => {
              e.preventDefault();
              if (e.key === "Tab") {
                searchButtonRef.current &&
                  searchButtonRef.current.focus({ preventScroll: true });
              }
            }}
            className={classNames(
              "group flex size-6 items-center justify-center rounded-[25px] hover:bg-grape-jelly",
              query !== "" ? "visible" : "invisible hidden",
              tabStyle,
            )}
            aria-label={intl.formatMessage({ id: "uh.CLOSE" })}
          >
            <CloseButton className="group-hover:fill-[white]" />
          </I13nButton>
        </div>
        <div
          className={classNames(
            "absolute inset-x-0 top-0 mt-uh-min1280 rounded-b-[26px]",
            hasSearchGradientStyle && !isFullPageSearchOpen
              ? "bg-gradient-to-r from-[#c938e3] to-[#4941fb] px-0.5 pb-0.5"
              : "border-pebble bg-white px-0 pb-5 [transition:height_.3s_ease-in-out] md:border-x-2 md:border-b-2 md:border-t-0",
            isSearchAssistOpen
              ? "h-auto opacity-100 [transition:opacity_.3s_ease-in-out] md:mt-[50px]"
              : "hidden h-0 opacity-0",
            !isSearchAssistOpen &&
              hasSearchGradientStyle &&
              "md:drop-shadow-md",
            (isSearchAssistOpen || hasSearchGradientStyle) && "-mx-0.5",
          )}
        >
          {hasSearchGradientStyle ? (
            <div
              className={classNames(
                "inset-x-0 top-0 rounded-b-[26px] bg-white pb-5 [transition:height_.3s_ease-in-out]",
                !isSearchAssistOpen && "hidden h-0 opacity-0",
                isSearchAssistOpen && "h-auto opacity-100",
              )}
            >
              {getSearchAssist()}
            </div>
          ) : (
            getSearchAssist()
          )}
        </div>
        <div
          className={classNames(
            "rounded-tr-[26px] bg-transparent",
            isStickyHeader && !hasSearchGradientStyle && "-mr-px",
          )}
        >
          <SearchButton
            buttonRef={searchButtonRef}
            isStickyHeader={isStickyHeader}
            partner={partner}
            uhSearchGradientStyle={uhSearchGradientStyle}
          />
        </div>
      </form>
    );
  };

  return (
    <>
      {hasSearchGradientStyle ? (
        <div
          className={classNames(
            "z-10 justify-start py-0 pr-0.5 font-yahoobeta caret-purple-700 [transition:background_.3s_ease-in-out] hover:bg-gradient-to-r hover:from-[#c938e3] hover:to-[#4941fb] hover:drop-shadow-md md:inline-flex md:h-[56px]",
            isStickyHeader
              ? "w-full md:mr-6 xl:mr-10"
              : "mr-0 w-[48vw] min-w-[604px] max-w-[716px]",
            isSearchFocused &&
              "has-[input:focus-visible]:outline-4 has-[input:focus-visible]:outline-thanos md:rounded-t-[26px]",
            isSearchAssistOpen
              ? "bg-gradient-to-r from-[#c938e3] to-[#4941fb] px-0.5 pt-0 outline-0 drop-shadow md:z-30 md:bg-white"
              : "p-0.5 md:rounded-[100px]",
            isSearchAssistOpen && !isSearchFocused && "md:rounded-t-[26px]",
            isFullPageSearchOpen
              ? "fixed inset-0 flex h-auto max-w-[767px] items-start bg-white"
              : "relative hidden max-w-[620px] items-center",
            !isSearchFocused &&
              isSearchAssistOpen &&
              (isStickyHeader
                ? "outline-transparent md:rounded-t-[23px]"
                : "outline-transparent md:rounded-t-[26px]"),
          )}
        >
          {getSearchForm()}
        </div>
      ) : (
        getSearchForm()
      )}
      {isSearchAssistOpen && !isFullPageSearchOpen && (
        <I13nButton
          dataYlk={{
            elm: "btn",
            itc: 1,
            sec: "ybar",
            slk: "ovrly-close-srch",
            subsec: "searchbox",
          }}
          className="fixed inset-0 z-20 w-full bg-midnight opacity-60"
          onClick={() => {
            setIsSearchAssistOpen(false);
            setActiveDescendantIndex(-1);
          }}
          aria-label="close"
          onBlur={() => {
            setIsSearchAssistOpen(false);
            setActiveDescendantIndex(-1);
          }}
        />
      )}
    </>
  );
};
