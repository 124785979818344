import classNames from "classnames";
import { type FC, useEffect, useState, useCallback } from "react";
import { useIntl } from "react-intl";
import { cdsData } from "../../lib/moremenu-cdsData";
import { EditionsPicker } from "./EditionsPicker";
import { MoreMenuColumn } from "./MoreMenuColumn";

interface Props {
  id: string;
  handleMoreMenuHover: (isMenuHovered: boolean) => void;
  isMenuHidden: boolean;
  isStickyHeader?: boolean;
  setIsMenuHidden: React.Dispatch<React.SetStateAction<boolean>>;
  uhRolloverMenus?: boolean;
}

export const MoreMenuDropDownV2: FC<Props> = (props) => {
  const intl = useIntl();
  const {
    handleMoreMenuHover,
    isMenuHidden,
    isStickyHeader,
    setIsMenuHidden,
    uhRolloverMenus,
    id,
  } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (isMenuHidden) {
        setIsMenuHidden(false);
      }
      if (!isVisible) {
        setIsVisible(true);
      }
    }, 100);
  }, [isVisible, isMenuHidden, setIsMenuHidden]);

  useEffect(() => {
    if (!isFocused) {
      handleMoreMenuHover(false);
    }
  }, [isFocused, handleMoreMenuHover]);

  const displayedColumns = isStickyHeader
    ? ["News", "Finance", "Sports"]
    : ["News", "Finance", "Sports", "Games", "Entertainment"];

  const moreMenuColumnData = uhRolloverMenus
    ? cdsData.cds.result[0].cdsData.filter((column) => {
        return !displayedColumns.includes(column.name);
      })
    : cdsData.cds.result[0].cdsData;

  const handleMoreMenuOpen = useCallback(() => {
    handleMoreMenuHover(true);
  }, [handleMoreMenuHover]);

  return (
    <div
      id={id}
      aria-label={intl.formatMessage({ id: "uh.A11Y_MOREMENU_DROPDOWN" })}
      role="menu"
      className={classNames(
        "absolute top-10 z-20 mx-3 flex flex-col gap-1 rounded-lg bg-white p-6 font-yahoobeta [box-shadow:0px_4px_16px_0px_rgba(0,_0,_0,_0.20),_0px_0px_2px_0px_rgba(0,_0,_0,_0.05)] [transition:opacity_.300s_ease-in,_height_.300s_ease-in,_visibility_.3s_ease-in]",
        isVisible ? "visible h-auto opacity-100" : "invisible h-0 opacity-0",
        isStickyHeader && uhRolloverMenus ? "w-[648px]" : "w-[440px]",
      )}
      tabIndex={-1}
      onMouseEnter={() => handleMoreMenuHover(true)}
      onMouseLeave={() => {
        setIsVisible(false);
        setTimeout(() => handleMoreMenuHover(false), 100);
      }}
      onFocus={() => {
        handleMoreMenuHover(true);
      }}
      onBlur={() => {
        if (!isFocused) {
          handleMoreMenuHover(false);
        }
      }}
      onTransitionEnd={(e) => {
        // remove drop down menu from the DOM after closing animation
        const target = e.target as HTMLDivElement;
        if (
          !isVisible &&
          !isMenuHidden &&
          target &&
          target.nodeName === "DIV" &&
          target.role === "menu"
        ) {
          setIsMenuHidden(true);
        }
      }}
    >
      <ul
        className="flex max-h-[400px] flex-col flex-wrap justify-start gap-6"
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
      >
        {moreMenuColumnData.map((section: any, idx: number) => {
          const { name, children, url } = section;
          return (
            <MoreMenuColumn key={idx} name={name} links={children} url={url} />
          );
        })}
        <EditionsPicker setMoreMenuOpen={handleMoreMenuOpen} />
      </ul>
    </div>
  );
};
