type onKeyDownProps = {
  clearButtonRef: React.RefObject<HTMLButtonElement>;
  inputRef: React.RefObject<HTMLInputElement>;
  listItemRef: React.MutableRefObject<HTMLLIElement[]>;
  onSuggestionSelect: (query: string, marker: number) => void;
};

export const onKeyDown = (
  { clearButtonRef, inputRef, listItemRef, onSuggestionSelect }: onKeyDownProps,
  event: React.KeyboardEvent<HTMLLIElement>,
) => {
  if (!event) {
    return;
  }
  const listItemEvent = event as React.KeyboardEvent<HTMLLIElement>;
  let itemIndex = -1;
  const items = listItemRef.current;
  if (!items.length) {
    return;
  }
  let numItems = items.length;
  let item;
  // find current focused search item list element
  for (let i = 0; i < numItems; i++) {
    if (items[i] === document.activeElement) {
      itemIndex = i;
      break;
    }
  }
  switch (listItemEvent.key) {
    case "Enter":
      listItemEvent.preventDefault();
      if (items[itemIndex] instanceof HTMLLIElement) {
        onSuggestionSelect(
          (inputRef.current && inputRef.current.value) || "",
          parseInt(items[itemIndex]?.dataset.marker || ""),
        );
      }
      break;
    case "ArrowUp":
    case "ArrowDown":
      listItemEvent.preventDefault();
      // handle moving search item focus up or down
      do {
        if (listItemEvent.key == "ArrowDown") {
          if (itemIndex < 0 || itemIndex >= numItems - 1) {
            itemIndex = 0;
          } else {
            itemIndex++;
          }
        } else {
          if (itemIndex <= 0) {
            itemIndex = numItems - 1;
          } else {
            itemIndex--;
          }
        }
        item = items[itemIndex] as HTMLLIElement;
      } while (!item.parentNode);
      break;
    case "Tab":
      listItemEvent.preventDefault();
      // set focus to clear button
      clearButtonRef.current && clearButtonRef.current.focus();
      break;
  }
};
