import { I13nAnchor } from "@yahoo-creators/i13n";
import classNames from "classnames";
import { type FC, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import AccountSwitch from "../../svgs/account-switch-alt.svg";
import Plus from "../../svgs/add-account.svg";
import Keyline from "../../svgs/keyline.svg";
import { useHeaderContext } from "./HeaderContext";
import { formatURL, isNameCJK, getSignOutURL } from "./helpers";
import { ProfileState } from "./profile-status";
import { ProfileAlphatar } from "./ProfileAlphatar";
import { type ProfileData, type User } from "./types";

interface Props {
  crumb: string;
  isFullHeightPanel?: boolean;
  profileData: ProfileData | null;
  showAccountPicker: boolean;
}

const accountPickSettingsCls =
  "flex w-full flex-row h-10 items-center rounded-none pr-5 pl-0 text-xs text-batcave hover:text-[#5409b2] md:mt-0 md:rounded-b-lg font-[450] font-yahoobeta ";
const accountPickerSettingsFocusCls =
  "focus-visible:rounded-[1px] focus-visible:fill-grape-jelly focus-visible:text-grape-jelly focus-visible:outline focus-visible:outline-4 focus-visible:outline-offset-2 focus-visible:outline-[rgb(96_1_210/50%)]";
const accountSettingsSVGCls =
  "mr-2.5 fill-batcave group-hover/settings:fill-[#5409b2] group-hover/add-account:fill-[#5409b2] group-focus-visible/settings:fill-[#5409b2] group-focus-visible/add-account:fill-[#5409b2]";

export const AccountPicker: FC<Props> = (props) => {
  const intl = useIntl();
  const [doneUrl, setDoneUrl] = useState("");
  const { lang, region, site, spaceId } = useHeaderContext();
  const { crumb, isFullHeightPanel, profileData, showAccountPicker } = props;

  useEffect(() => {
    setDoneUrl(window.location.href);
  }, []);
  const manageAccountUrl = new URL("https://login.yahoo.com/manage_account?");

  const params = {
    ".crumb": crumb,
    ".done": encodeURIComponent(doneUrl),
    activity: "ybar-acctinfo",
    intl: region,
    lang,
    login: profileData?.currentUser.email || profileData?.currentUser.alias,
    pspid: spaceId?.toString(),
    src: site,
  };
  const accountParams = {
    ".crumb": crumb,
    ".done": encodeURIComponent(doneUrl),
    activity: "ybar-select-account",
    intl: region,
    lang,
    login: profileData?.currentUser.email || profileData?.currentUser.alias,
    pspid: spaceId?.toString(),
    src: site,
  };
  const formattedManageAcctURL = formatURL(manageAccountUrl, params);
  const formattedAddAcctURL = formatURL(manageAccountUrl, accountParams);

  const signOutURL = getSignOutURL(site || "", doneUrl);

  const accountPickerAcctList = profileData?.users.map((currentUser: User) => {
    const userImage = currentUser.profileImage;
    const fullName = isNameCJK(currentUser.firstName, currentUser.lastName)
      ? `${currentUser.lastName[0]}${currentUser.firstName}`
      : `${currentUser.firstName} ${currentUser.lastName[0]}`;
    const userEm = currentUser.email || currentUser.alias;
    const loggedInAcc =
      currentUser.state === ProfileState.LOGGED_IN ? true : false;
    const loginURL = new URL("https://login.yahoo.com/");
    let isDefaultProfilePic = false;
    if (site) {
      loginURL.searchParams.set("src", site);
      loginURL.searchParams.set("login", userEm);
    }
    loginURL.searchParams.set(".crumb", crumb);
    loginURL.searchParams.set(".done", encodeURIComponent(doneUrl));
    isDefaultProfilePic = userImage.includes("default_user_profile_pic");
    return (
      <I13nAnchor
        dataYlk={{
          elm: "navcat",
          itc: 0,
          pkgt: "profile-pane",
          sec: "ybar",
          slk: "Account picker",
          subsec: "accounts",
        }}
        aria-label={intl.formatMessage({ id: "uh.PROFILE" })}
        key={userEm}
        className={classNames(
          "group/profile-button flex h-[70px] w-full flex-row items-start gap-3 px-5 pt-[5px] hover:bg-dirty-seagull focus-visible:mx-5 focus-visible:w-[271px] focus-visible:rounded-[1px] focus-visible:bg-dirty-seagull focus-visible:px-0 focus-visible:text-grape-jelly focus-visible:outline focus-visible:outline-4 focus-visible:outline-offset-1 focus-visible:outline-[rgb(96_1_210/50%)] lg:focus-visible:w-[320px]",
          isFullHeightPanel && "max-w-[320px]",
        )}
        href={loginURL.toString()}
      >
        <div className="flex size-[56px] items-center">
          <div className="flex size-[56px] items-center justify-center rounded-md bg-hendrix text-2xl font-medium text-black">
            {isDefaultProfilePic ? (
              <ProfileAlphatar
                hide={!isDefaultProfilePic}
                profileFirstName={currentUser.firstName}
              />
            ) : (
              <img
                src={userImage}
                className="size-[56px] rounded-md"
                alt="" // decorative
              />
            )}
          </div>
        </div>
        <span className="flex h-[56px] flex-col items-start group-hover/profile-button:text-[#5409b2]">
          <p className="max-w-60 overflow-x-clip overflow-y-visible text-ellipsis whitespace-nowrap text-base font-[585] not-italic leading-5 text-batcave group-hover/profile-button:text-[#5409b2]">
            {fullName}
          </p>
          <p
            className={classNames(
              "overflow-hidden text-ellipsis text-xs text-dolphin group-hover/profile-button:text-[#5409b2]",
              isFullHeightPanel ? "max-w-[205px]" : "max-w-[250px]",
            )}
          >
            {userEm}
          </p>
          <span
            className={classNames(
              "mt-[5px] flex items-center gap-[5px] rounded-[3px] py-0",
            )}
          >
            <p
              className={classNames(
                "text-[10px] group-hover/profile-button:text-[#5409b2]",
                loggedInAcc ? "font-medium text-batcave" : "text-battleship",
              )}
            >
              {loggedInAcc
                ? intl.formatMessage({ id: "uh.ACCOUNT_SIGNED_IN" })
                : intl.formatMessage({ id: "uh.ACCOUNT_SIGNED_OUT" })}
            </p>
          </span>
        </span>
      </I13nAnchor>
    );
  });

  return (
    <div className={classNames("flex h-full flex-col justify-between")}>
      <>
        <p
          className={classNames(
            "mb-2.5 flex flex-col items-start pl-5 text-sm font-[450] not-italic leading-6 text-black lg:h-0",
            showAccountPicker && "lg:h-fit",
          )}
        >
          {intl.formatMessage({ id: "uh.ACCOUNT_PICKER_GREETING" })}
        </p>
        <div className="flex h-fit flex-col">{accountPickerAcctList}</div>
        <div
          className={classNames(
            "px-5",
            isFullHeightPanel && "flex h-[90%] flex-col justify-start",
          )}
        >
          <Keyline
            className={classNames(
              "mb-[5px] w-[320px]",
              isFullHeightPanel && "max-w-[275px]",
            )}
          />
          <I13nAnchor
            dataYlk={{
              elm: "navcat",
              itc: 0,
              pkgt: "profile-pane",
              sec: "ybar",
              slk: "View accounts",
              subsec: "accounts",
            }}
            href={formattedManageAcctURL}
            className={classNames(
              accountPickSettingsCls,
              accountPickerSettingsFocusCls,
              "group/settings py-3",
            )}
          >
            <AccountSwitch className={classNames(accountSettingsSVGCls)} />
            {intl.formatMessage({ id: "uh.ACCOUNT_PICKER_VIEW_ACCOUNTS" })}
          </I13nAnchor>
          <I13nAnchor
            dataYlk={{
              elm: "navcat",
              itc: 0,
              pkgt: "profile-pane",
              sec: "ybar",
              slk: "add acount",
              subsec: "accounts",
            }}
            href={formattedAddAcctURL}
            className={classNames(
              accountPickSettingsCls,
              accountPickerSettingsFocusCls,
              "group/add-account py-2.5",
            )}
          >
            <Plus className={classNames(accountSettingsSVGCls)} />
            {intl.formatMessage({ id: "uh.ACCOUNT_PICKER_ADD_ACCOUNT" })}
          </I13nAnchor>
        </div>
      </>
      <div
        className={classNames(
          "group/sign-out-all flex w-full flex-row items-center rounded-none px-5 py-2.5 md:mt-0 md:rounded-b-lg",
        )}
      >
        <I13nAnchor
          dataYlk={{
            elm: "navcat",
            itc: 0,
            pkgt: "profile-pane",
            sec: "ybar",
            slk: "SignOut",
            subsec: "accounts",
          }}
          href={signOutURL}
          className="m-0 flex h-10 w-full justify-center rounded-[100px] border-2 border-dirty-seagull py-2.5 text-left text-xs font-normal leading-4 text-batcave no-underline focus-visible:rounded-full focus-visible:border-transparent focus-visible/sign-out-all:bg-[#5409b2] focus-visible/sign-out-all:text-white focus-visible:outline focus-visible:outline-4 focus-visible:outline-offset-2 focus-visible:outline-[rgb(96_1_210/50%)] group-hover/sign-out-all:border-transparent group-hover/sign-out-all:bg-[#5409b2] group-hover/sign-out-all:text-white"
        >
          <p>{intl.formatMessage({ id: "uh.ACCOUNT_PICKER_SIGNOUT" })}</p>
        </I13nAnchor>
      </div>
    </div>
  );
};
