"use client";

import { useRapid } from "@yahoo-creators/i13n";
import { SCREEN_WIDTH } from "@yahoo-news/util";
import classNames from "classnames";
import { type FC, useCallback, useEffect, useRef, useState } from "react";
import { HeaderContext } from "../Account/HeaderContext";
import { Profile } from "../Account/Profile";
import useUsersData from "../Account/useUsersData";
import { FauxSearch } from "../FauxSearch";
import { Logo } from "../Logo/Logo";
import { Mail } from "../Mail/Mail";
import { PrimaryNav } from "../PrimaryNav";
import { Search } from "../Search";
import { SideNav } from "../SideNav";
import { Toolbar } from "../Toolbar/Toolbar";

type Props = {
  aboveHeaderAd?: boolean;
  altQuery?: string;
  altQueryChangeHandler?: (query: string, noFetch?: boolean) => void;
  animateSearchBar?: boolean;
  crumb: string;
  enablePFS?: boolean;
  isBot?: boolean;
  isHiddenByDefault?: boolean;
  isModal?: boolean;
  isVisible?: boolean;
  lang?: string;
  mailAppId?: string;
  altNoSearchAssistFetch: boolean;
  pageScrollOffset?: number;
  region?: string;
  scrollThreshold?: number;
  site?: string;
  spaceId?: number;
  uhRolloverMenus?: boolean;
  uhSearchGradientStyle?: boolean;
  altHasSearchGradientStyle?: boolean;
  altSearchGradientStyleChange?: (hasSearchGradientStyle: boolean) => void;
};

const wrapperClassnames = [
  "font-centra",
  "sticky",
  "top-0",
  "z-20",
  "w-full",
  "sm:max-h-[96px]",
  "md:max-h-[175px]",
];

const innerClassnames = [
  "bg-white",
  "z-20",
  "flex",
  "flex-col",
  "px-5",
  "pt-2.5",
  "sm:z-20",
  "sm:w-full",
  "md:min-h-[76px]",
  "justify-center",
  "md:flex",
  "md:h-0",
  "md:w-full",
  "md:flex-col",
  "md:items-start",
  "md:bg-white",
  "md:px-5",
  "md:pt-2.5",
  "md:[transition:height_.2s_ease-in,top_.2s_ease-in,opacity_.2s_ease-in]",
  "md:h-[76px]",
  "lg:fixed",
  "lg:px-0",
];

export const UH4: FC<Props> = ({
  aboveHeaderAd,
  altQuery,
  altQueryChangeHandler,
  animateSearchBar,
  crumb,
  enablePFS = false,
  isBot = false,
  isHiddenByDefault = false,
  isModal = false,
  isVisible,
  lang = "",
  mailAppId,
  altNoSearchAssistFetch,
  pageScrollOffset = 44,
  region = "",
  scrollThreshold = 5,
  site,
  spaceId,
  uhRolloverMenus,
  uhSearchGradientStyle,
  altHasSearchGradientStyle,
  altSearchGradientStyleChange,
}) => {
  const {
    profileData,
    isComplete,
    setUseAlphatar,
    showAccountPicker,
    showSignInButton,
    useAlphatar,
    handleSetMailCount,
    handleShowAccountPicker,
    mailCount,
  } = useUsersData({ crumb });
  const [query, setQuery] = useState("");
  const [noSearchAssistFetch, setNoSearchAssistFetch] = useState(false);
  const [hasSearchGradientStyle, setSearchGradientStyle] = useState(
    altHasSearchGradientStyle || uhSearchGradientStyle,
  );
  const handleQueryChange = useCallback(
    (newquery: string, noFetch: boolean = false) => {
      setNoSearchAssistFetch(noFetch);
      setQuery(newquery);
    },
    [],
  );
  const handleSearchGradientStyleChange = useCallback(
    (useGradient: boolean = false) => {
      setSearchGradientStyle(useGradient);
    },
    [],
  );
  const [isFullPageSearchOpen, setIsFullPageSearchOpen] = useState(false);
  const [showFauxSearch, setShowFauxSearch] = useState(true);
  const [animationRunning, setAnimationRunning] = useState(false);
  const lastScrollTop = useRef(0);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [screenWidth, setScreenWidth] = useState(0);
  const id = "module-uh";
  useRapid(id, "ybar");
  /* handle scrolling behavior */
  useEffect(() => {
    const docEl = document.documentElement;
    const checkOffset = () => {
      const isDialogOpen = document.body.classList.contains("dialog-open");
      if (isDialogOpen) {
        return;
      }
      const scrollTop = docEl.scrollTop;
      if (animationRunning && screenWidth < SCREEN_WIDTH.md) {
        lastScrollTop.current = scrollTop;

        return;
      }

      const afterThreshold = scrollTop > pageScrollOffset; // updated scrollOffset to pageScrollOffset to match uh3
      const beforeThreshold = scrollTop < pageScrollOffset; // updated scrollOffset to pageScrollOffset to match uh3
      const scrollingDown = scrollTop > lastScrollTop.current + scrollThreshold;
      const scrollingUp = scrollTop < lastScrollTop.current - scrollThreshold;
      /* Created scrollAdSpace to handle the ad on top of the header
       * 361 was determine by 250 (ad height) + 175 (header height) - 64 (slim header height)
       */
      const scrollAdSpace = aboveHeaderAd ? 361 : 0;
      const showStickyHeader =
        !isVisible && scrollingDown && scrollTop > scrollAdSpace;
      const hideStickyHeader = isVisible || window.scrollY === 0;

      if (screenWidth >= SCREEN_WIDTH.md) {
        setShowFauxSearch(false);
        if (showStickyHeader) {
          lastScrollTop.current = scrollTop;
        } else if (hideStickyHeader) {
          lastScrollTop.current = 0;
        }
      } else {
        if (
          !showFauxSearch &&
          (beforeThreshold || (afterThreshold && scrollingUp))
        ) {
          setShowFauxSearch(true);
          setAnimationRunning(true);
        } else if (showFauxSearch && afterThreshold && scrollingDown) {
          setShowFauxSearch(false);
          setAnimationRunning(true);
        }
        lastScrollTop.current = scrollTop;
      }
      setHasScrolled(lastScrollTop.current > 0);
    };

    window.addEventListener("scroll", checkOffset, { passive: true });
    return () => {
      window.removeEventListener("scroll", checkOffset);
    };
  }, [
    animationRunning,
    aboveHeaderAd,
    pageScrollOffset,
    scrollThreshold,
    showFauxSearch,
    screenWidth,
    isVisible,
  ]);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    const handleResize = () => {
      if (window.innerWidth === screenWidth) {
        return;
      }

      setScreenWidth(window.innerWidth);
      if (window.innerWidth < SCREEN_WIDTH.md && !showFauxSearch) {
        setShowFauxSearch(true);
      }
    };

    window.addEventListener("resize", handleResize, false);
    return () => {
      window.removeEventListener("resize", handleResize, false);
    };
  }, [showFauxSearch, screenWidth]);

  return (
    <header id={id} className={classNames(wrapperClassnames)}>
      <div
        className={classNames(
          innerClassnames,
          showFauxSearch
            ? "gap-2.5 pb-3"
            : uhSearchGradientStyle
              ? "py-3 md:pt-3"
              : "pb-2.5",
          (!isHiddenByDefault || hasScrolled) &&
            "md:top-0 md:shadow-[0_4px_8px_0_rgba(0,0,0,0.1),0_0_1px_0_rgba(0,0,0,0.1)]",
          isHiddenByDefault && !hasScrolled && "lg:top-[-75px] lg:opacity-0", // handles slimheader elements clickable on page
        )}
      >
        <div className="mx-auto inline-flex w-full max-w-[1440px] items-center justify-around lg:max-w-[1504px] lg:px-8">
          <SideNav isBot={isBot} site={site} />
          <Logo
            lang={lang}
            partner="none"
            site={site}
            isExperimentalHeader={true}
            enableLogoBackNav={isModal}
          />
          <Search
            crumb={crumb}
            setIsFullPageSearchOpen={setIsFullPageSearchOpen}
            isFullPageSearchOpen={isFullPageSearchOpen}
            site={site}
            isStickyHeader={true}
            onQueryChange={altQueryChangeHandler || handleQueryChange}
            query={altQuery || query}
            noSearchAssistFetch={altNoSearchAssistFetch || noSearchAssistFetch}
            animateSearchBar={animateSearchBar}
            isModal={isModal}
            uhSearchGradientStyle={uhSearchGradientStyle}
            hasSearchGradientStyle={
              altHasSearchGradientStyle !== undefined
                ? altHasSearchGradientStyle
                : hasSearchGradientStyle
            }
            onSearchGradientStyleChange={
              altSearchGradientStyleChange !== undefined
                ? altSearchGradientStyleChange
                : handleSearchGradientStyleChange
            }
            initialSearchInputFocus={true}
          />
          <HeaderContext.Provider value={{ lang, region, site, spaceId }}>
            <PrimaryNav
              lang={lang}
              site={site}
              isBot={isBot}
              isExperimentalHeader={true}
              isStickyHeader={true}
              uhRolloverMenus={uhRolloverMenus}
            />
            <Toolbar className="flex min-h-8 min-w-min shrink grow basis-0 items-center justify-end gap-3 md:mr-1 md:min-h-10 lg:mr-0">
              <Mail
                crumb={crumb}
                handleSetMailCount={handleSetMailCount}
                isComplete={isComplete}
                lang={lang}
                mailAppId={mailAppId}
                mailCount={mailCount}
              />
              <Profile
                crumb={crumb}
                enablePFS={enablePFS}
                isUH4={true}
                profileData={profileData}
                setUseAlphatar={setUseAlphatar}
                showAccountPicker={showAccountPicker}
                showSignInButton={showSignInButton}
                useAlphatar={useAlphatar}
                handleShowAccountPicker={handleShowAccountPicker}
                mailCount={mailCount}
              />
            </Toolbar>
          </HeaderContext.Provider>
        </div>
        <FauxSearch
          setIsFullPageSearchOpen={setIsFullPageSearchOpen}
          isFullPageSearchOpen={isFullPageSearchOpen}
          showFauxSearch={showFauxSearch}
          setAnimationRunning={setAnimationRunning}
          site={site}
        />
      </div>
    </header>
  );
};
