import { I13nButton } from "@yahoo-creators/i13n";
import classNames from "classnames";
import { type FC } from "react";
import { type SideNavChild } from "../../lib/sidenav-cdsData";
import SectionChevron from "../../svgs/section-chevron.svg";
import { MenuListItemLink } from "./MenuListItemLink";

export const SubSectionListItem: FC<SideNavChild> = ({
  idx,
  kids,
  menuLevel,
  name,
  setMenuLevel,
  setPanelOpen,
  setSubNavOpenKey,
  subNavOpenKey,
  subsectionId,
  url,
}) => {
  const getTertiaryListItem = (items: SideNavChild[]) => {
    return items.map((item: SideNavChild, idx: number) => {
      return (
        <li
          key={`tertiaryListItem${idx}_${item.url}`}
          className="inline-flex h-11 w-[311px] items-center justify-start gap-2 px-5 py-3"
        >
          <MenuListItemLink
            name={item.name}
            setMenuLevel={setMenuLevel}
            setPanelOpen={setPanelOpen}
            setSubNavOpenKey={setSubNavOpenKey}
            url={item.url}
          />
        </li>
      );
    });
  };

  return (
    <li className="inline-flex h-11 w-[311px] items-center justify-start gap-2 px-5 py-3">
      <MenuListItemLink
        name={name}
        url={url}
        setPanelOpen={setPanelOpen}
        setSubNavOpenKey={setSubNavOpenKey}
      />
      {kids && (
        <I13nButton
          dataYlk={{
            elm: "expand",
            itc: 1,
            pkgt: "side-nav",
            sec: "ybar",
            slk: name,
            subsec: "navrail",
          }}
          className={classNames(
            "flex h-11 w-12 items-center justify-end focus-visible:rounded-[1px] focus-visible:text-grape-jelly focus-visible:outline focus-visible:outline-4 focus-visible:outline-offset-4 focus-visible:outline-[rgb(96_1_210/50%)]",
          )}
          aria-label={name}
          aria-expanded={subNavOpenKey === `uh_sidenav_${subsectionId}${idx}!`}
          aria-controls={`uh_sidenav_${subsectionId}${idx}!`}
          id={`uh_sidenav_btn_${subsectionId}${idx}!`}
          onClick={() => {
            setSubNavOpenKey &&
              setSubNavOpenKey(`uh_sidenav_${subsectionId}${idx}!`);
            menuLevel && setMenuLevel && setMenuLevel(menuLevel + 1);
          }}
        >
          <SectionChevron height={16} width={16} className="-rotate-90" />
        </I13nButton>
      )}
      {kids && (
        <ul
          aria-labelledby={`uh_sidenav_btn_${subsectionId}${idx}!`}
          className={classNames(
            subNavOpenKey &&
              subNavOpenKey === `uh_sidenav_${subsectionId}${idx}!` &&
              menuLevel !== 2
              ? "absolute bottom-0 left-0 top-[45px] z-10 w-[311px] bg-white"
              : "hidden",
          )}
          id={`uh_sidenav_${subsectionId}${idx}!`}
        >
          {getTertiaryListItem(kids)}
        </ul>
      )}
    </li>
  );
};
