import { I13nAnchor } from "@yahoo-creators/i13n";
import { type FC, useCallback, useEffect, useState } from "react";

interface Props {
  url: string;
  name: string;
  setMenuLevel?: React.Dispatch<React.SetStateAction<number>>;
  setPanelOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setSubNavOpenKey?: React.Dispatch<React.SetStateAction<string>>;
}

export const MenuListItemLink: FC<Props> = ({
  name,
  setMenuLevel,
  setPanelOpen,
  setSubNavOpenKey,
  url,
}) => {
  const [isAnchorLink, setIsAnchorLink] = useState(true);
  const handleClick = useCallback(() => {
    setPanelOpen && setPanelOpen(false);
    setSubNavOpenKey && setSubNavOpenKey("");
    setMenuLevel && setMenuLevel(1);
  }, [setMenuLevel, setPanelOpen, setSubNavOpenKey]);
  useEffect(() => {
    if (url === "") {
      setIsAnchorLink(false);
    }
  }, [url]);
  const linkStyle =
    "shrink grow basis-0 text-base font-normal leading-tight text-gray-600 hover:text-grape-jelly focus-visible:rounded-[1px] focus-visible:text-grape-jelly focus-visible:outline focus-visible:outline-4 focus-visible:outline-offset-4 focus-visible:outline-[rgb(96_1_210/50%)]";
  return isAnchorLink ? (
    <I13nAnchor
      dataYlk={{
        elm: "navcat",
        itc: 0,
        pkgt: "side-nav",
        sec: "ybar",
        slk: name,
        subsec: "navrail",
      }}
      href={url}
      onClick={handleClick}
      className={linkStyle}
    >
      {name}
    </I13nAnchor>
  ) : (
    <p className={linkStyle}>{name}</p>
  );
};
