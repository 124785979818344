import { type FC } from "react";
import { type ProfileData } from "./types";

interface Props {
  hide: boolean;
  profileData: ProfileData | null;
  onLoad: () => void;
  onError: () => void;
}

export const ProfileAvatar: FC<Props> = (props) => {
  if (!props.profileData || props.hide) return null;

  const src = props.profileData.currentUser.profileImage;

  return (
    <img
      alt="" // decorative
      className="absolute left-0 top-0 size-full"
      src={src}
      onLoad={props.onLoad}
      onError={props.onError}
    />
  );
};
