"use client";
import { useRapid } from "@yahoo-creators/i13n";
import { useInView } from "@yahoo-news/util/hooks/useIntersectionObserver";
import classNames from "classnames";
import { type ReactNode, useState, useRef, type FC, useCallback } from "react";
import { HeaderContext } from "../Account/HeaderContext";
import { Profile } from "../Account/Profile";
import useUsersData from "../Account/useUsersData";
import { Mail } from "../Mail/Mail";
import { Search } from "../Search";
import { Toolbar } from "../Toolbar/Toolbar";
import { TopNavWithLogo } from "./TopNavWithLogo";
import { UH4 } from "./UH4";
interface Props {
  aboveHeaderAd?: boolean;
  animateSearchBar?: boolean;
  children: ReactNode;
  crumb: string;
  enablePFS?: boolean;
  isBot?: boolean;
  lang?: string;
  mailAppId?: string;
  pageScrollOffset?: number;
  region?: string;
  scrollThreshold?: number;
  site?: string;
  spaceId?: number;
  uhRolloverMenus?: boolean;
  uhSearchGradientStyle?: boolean;
}

/* 
  Setting

  isExperimentalHeader = true
  
  while passing it down the component tree.
  will move to a context provider in a followup PR.
*/

export const FrontpageHeader: FC<Props> = ({
  aboveHeaderAd = false,
  animateSearchBar = false,
  children,
  crumb,
  enablePFS = false,
  isBot = false,
  lang = "",
  mailAppId,
  region = "",
  site,
  spaceId = undefined,
  uhRolloverMenus = false,
  uhSearchGradientStyle = false,
}) => {
  const [query, setQuery] = useState("");
  const [noSearchAssistFetch, setNoSearchAssistFetch] = useState(false);
  const [hasSearchGradientStyle, setSearchGradientStyle] = useState(
    uhSearchGradientStyle,
  );
  const {
    isComplete,
    profileData,
    handleSetMailCount,
    setUseAlphatar,
    showAccountPicker,
    showSignInButton,
    useAlphatar,
    handleShowAccountPicker,
    mailCount,
  } = useUsersData({ crumb });
  const ExperimentalHeaderRef = useRef<HTMLDivElement>(null);
  const isVisible = useInView(ExperimentalHeaderRef, { threshold: 0.001 });

  const handleQueryChange = useCallback(
    (newquery: string, noFetch: boolean = false) => {
      setNoSearchAssistFetch(noFetch);
      setQuery(newquery);
    },
    [],
  );

  const handleSearchGradientStyleChange = useCallback(
    (useGradient: boolean = false) => {
      setSearchGradientStyle(useGradient);
    },
    [],
  );

  const id = "module-uh-rocket";
  useRapid(id);

  return (
    <>
      <header
        id={id}
        className={classNames(
          "hidden w-full bg-white font-centra md:flex lg:h-[175px]",
        )}
      >
        <div
          className={classNames(
            "w-full flex-col items-center justify-center gap-6 border-b border-zinc-200 py-3 [transition:opacity_.1s_ease-in] lg:inline-flex",
            "top-0 h-44 opacity-100",
            "sm:hidden",
          )}
          ref={ExperimentalHeaderRef}
        >
          <div className="mx-auto inline-flex w-full max-w-[1440px] items-center justify-center self-stretch px-5 lg:max-w-[1504px] lg:px-8">
            {/* weather widget */}
            <div className="flex h-[18px] w-52 max-w-[200px] items-center justify-start gap-3 font-yahoobeta">
              {children}
            </div>

            <TopNavWithLogo
              isBot={isBot}
              lang={lang}
              site={site}
              uhRolloverMenus={uhRolloverMenus}
            />
            <HeaderContext.Provider
              value={{
                isExperimentalHeader: true,
                lang,
                region,
                site,
                spaceId,
              }}
            >
              <Toolbar className="inline-flex w-52 items-center justify-end gap-2">
                <Mail
                  crumb={crumb}
                  handleSetMailCount={handleSetMailCount}
                  isExperimentalHeader={true}
                  isComplete={isComplete}
                  lang={lang}
                  mailAppId={mailAppId}
                  mailCount={mailCount}
                />
                <Profile
                  crumb={crumb}
                  enablePFS={enablePFS}
                  handleShowAccountPicker={handleShowAccountPicker}
                  isExperimentalHeader={true}
                  isUH4={false}
                  mailCount={mailCount}
                  profileData={profileData}
                  setUseAlphatar={setUseAlphatar}
                  showAccountPicker={showAccountPicker}
                  showSignInButton={showSignInButton}
                  useAlphatar={useAlphatar}
                />
              </Toolbar>
            </HeaderContext.Provider>
          </div>

          <Search
            animateSearchBar={animateSearchBar}
            crumb={crumb}
            hasSearchGradientStyle={hasSearchGradientStyle}
            initialSearchInputFocus={true}
            isFullPageSearchOpen={false}
            isStickyHeader={false}
            noSearchAssistFetch={noSearchAssistFetch}
            onQueryChange={handleQueryChange}
            onSearchGradientStyleChange={handleSearchGradientStyleChange}
            query={query}
            site={site}
            uhSearchGradientStyle={uhSearchGradientStyle}
          />
        </div>
      </header>

      <UH4
        aboveHeaderAd={aboveHeaderAd}
        altHasSearchGradientStyle={hasSearchGradientStyle}
        altNoSearchAssistFetch={noSearchAssistFetch}
        altQuery={query}
        altQueryChangeHandler={handleQueryChange}
        altSearchGradientStyleChange={handleSearchGradientStyleChange}
        animateSearchBar={animateSearchBar}
        crumb={crumb}
        enablePFS={enablePFS}
        isBot={isBot}
        isHiddenByDefault={true}
        isVisible={isVisible}
        lang={lang}
        mailAppId={mailAppId}
        site={site}
        uhRolloverMenus={uhRolloverMenus}
        uhSearchGradientStyle={uhSearchGradientStyle}
      />
    </>
  );
};
