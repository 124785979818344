import classNames from "classnames";
import { type FC } from "react";
import { useIntl } from "react-intl";
import { Item } from "./Item";
import {
  type SearchAssistDropdownItem,
  type SearchAssistDropdownSection,
} from "./types";

interface Props {
  activeDescendantId?: string;
  isFullPageSearchOpen?: boolean;
  isSearchAssistOpen: boolean;
  listItemRef: React.MutableRefObject<HTMLLIElement[]>;
  noFetch: boolean;
  onKeyDown: (e: React.KeyboardEvent<HTMLLIElement>) => void;
  onRemove?: (title: string) => void;
  onSuggestionSelect: (query: string, marker: number) => void;
  query: string;
  removed?: Set<string>;
  searchAssitItems: SearchAssistDropdownSection[];
}

const wrapperSectionHeadingClassnames = [
  "flex",
  "items-center",
  "pb-2",
  "pl-[45px]",
  "pr-[60px]",
  "pt-3",
  "text-sm",
  "font-semibold",
  "md:ml-4",
  "md:px-0",
  "md:py-[5px]",
  "md:font-normal",
  "md:text-dolphin",
];

export const Tray: FC<Props> = ({
  activeDescendantId,
  isFullPageSearchOpen = false,
  isSearchAssistOpen,
  listItemRef,
  noFetch,
  onKeyDown,
  onRemove,
  onSuggestionSelect,
  query,
  removed,
  searchAssitItems,
}) => {
  const intl = useIntl();

  const getSearchAssistsItems = (
    itemsArray: SearchAssistDropdownItem[],
    idx: number,
  ) => {
    let array = [];
    for (const { id, imageUrl, subtitle, title, ykid, marker } of itemsArray) {
      if (removed && removed.has(title)) {
        continue;
      }
      const key = ykid || `${idx}_${title}_${subtitle ? subtitle : ""}`;

      array.push(
        <Item
          id={id}
          imageUrl={imageUrl}
          isFullPageSearchOpen={isFullPageSearchOpen}
          isSearchAssistOpen={isSearchAssistOpen}
          itemIndex={idx}
          key={key}
          listItemRef={listItemRef}
          marker={marker}
          noFetch={noFetch}
          onClick={() => onSuggestionSelect(title, marker)}
          onKeyDown={(e: React.KeyboardEvent<HTMLLIElement>) => onKeyDown(e)}
          onRemove={() => {
            onRemove && onRemove(title);
          }}
          query={query}
          selected={id === activeDescendantId}
          subtitle={subtitle}
          title={title}
        />,
      );
    }
    return array;
  };

  const getTray = (sectionsArray: SearchAssistDropdownSection[]) => {
    let sections: JSX.Element[] = [];
    sectionsArray.forEach((section, idx) => {
      if (section.sectionName === "trending") {
        sections.push(
          <span
            className={classNames(wrapperSectionHeadingClassnames)}
            key={section.sectionName}
          >
            {intl.formatMessage({ id: "uh.SEARCH_ASSIST_TRENDING" })}
          </span>,
        );
      } else if (section.title !== "") {
        /* Change this to set a hidden field for screen readers when empty */
        sections.push(
          <span
            className={classNames(wrapperSectionHeadingClassnames)}
            key={section.sectionName}
          >
            {section.title}
          </span>,
        );
      }
      sections.push(
        <ul
          className={classNames(!isSearchAssistOpen && "pointer-events-none")}
          key={`${idx}_list_for_${section.sectionName}`}
          role="listbox"
        >
          {getSearchAssistsItems(section.items, idx)}
        </ul>,
      );
    });
    return sections;
  };
  return <>{getTray(searchAssitItems)}</>;
};
