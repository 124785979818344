// const GOSSIP_MARKER_ANSWERS = 79;
// const GOSSIP_MARKER_COMPANY = 30;
// const GOSSIP_MARKER_CURRENCYCONV = 53;
// const GOSSIP_MARKER_ENTERTAINMENTS = 27;
// const GOSSIP_MARKER_FINANCE = 46;
// const GOSSIP_MARKER_FLIGHT = 32;
// const GOSSIP_MARKER_MEMBERSHIP_NO_MATCH = 441;
// const GOSSIP_MARKER_PEOPLE_ALSO_ASK = 72;
// const GOSSIP_MARKER_PEOPLE_ALSO_SEARCH_FOR = 42;
// const GOSSIP_MARKER_RELATED_STOCKS = 73;
export const GOSSIP_MARKER_SEARCH_HISTORY = 13;
// const GOSSIP_MARKER_SHOPPING_REFINEMENT = 76;
// const GOSSIP_MARKER_SPORTS = 48;
export const GOSSIP_MARKER_TRENDING = 9;
// const GOSSIP_MARKER_UNITCONV = 54;
// const GOSSIP_MARKER_WEATHER = 41;
// const GOSSIP_MARKER_WHAT_TO_WATCH = 75;
export const NO_GOSSIP_MARKER = 0;

export interface ResponseItem {
  d?: {
    ykid: string;
  };
  fd?: {
    imageUrl?: string;
    subtitle?: string;
  };
  k: string;
  m: number;
}

export interface SearchAssistDropdownItem {
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  iconHeight?: number;
  iconWidth?: number;
  id: string;
  imageIcon?: string;
  imageIconBgColor?: string;
  imageUrl?: string;
  itemIconColor?: string;
  link?: string;
  marker: number;
  noIconBg?: boolean;
  subtitle?: string;
  timestamp?: string | number;
  title: string;
  viewBox?: string;
  ykid?: string;
}

export interface SearchAssistDropdownSection {
  items: SearchAssistDropdownItem[];
  sectionName: string;
  title?: string;
  type: string;
}

export type SuggestionsDataFetcherParams = {
  crumb: string;
  query: string;
  lang?: string;
};

export type SuggestionsDataFetcher = ({
  crumb,
  query,
  lang,
}: SuggestionsDataFetcherParams) => {
  abort: () => void;
  fetch: Promise<
    {
      items: SearchAssistDropdownItem[];
      sectionName: string;
      title: string;
      type: string;
    }[]
  >;
};

/**
 * Sports data API
 */
export interface SportsDataApiSportsResponseItem {
  k: string; // only on League type
  fd?: {
    display_name: string;
    id: string;
    image?: string; // url to remote png
    isLeague?: "true"; // only on League type
    league?: string; // only on Team type
    primary_color?: string;
    subtitle: string;
    url?: string;
  };
}

export interface SportsDataApiNewsResponseItem {
  publishDate: number;
  sourceNameShort: string;
  sourceUrl?: string;
  title: string;
}

export interface SportsDataApiResponse {
  response?: {
    search?: {
      results?: {
        "sports.assist"?: {
          data: SportsDataApiSportsResponseItem[];
        };
        "sports.assist.news"?: {
          data: SportsDataApiNewsResponseItem[];
        };
      };
    };
  };
}

/**
 * Sports headlines API
 */
export interface SportsHeadlinesApiResponseItem {
  content?: {
    title: string;
    clickThroughUrl?: {
      url?: string;
    };
  };
}
export interface SportsHeadlinesApiResponse {
  data: {
    mainStream: {
      stream: SportsHeadlinesApiResponseItem[];
    };
  };
}
