/* Jedi Webservice Deco */
type JwsDeco = {
  id: string;
  type: string;
  counts?: Array<{
    accountId: string;
    unseen: number;
  }>;
};

export const getYmreqid = () => {
  let d = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-xxxx-09xx-xxxxxxxxxx00".replace(
    new RegExp("x", "g"),
    () => {
      const r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return r.toString(16);
    },
  );
  return uuid;
};

export const fetchMailCount = async (
  abortController: AbortController,
  mailAppId = "YahooFrontPage",
) => {
  const requestDetails = {
    requests: [
      {
        filters: {
          select: {
            mailboxId: "$..mailboxes[?(@.isPrimary==true)].id",
          },
        },
        id: "GetMailboxId",
        method: "GET",

        requests: [
          {
            id: "ListDecos",
            method: "GET",
            uri: "/ws/v3/mailboxes/@.id==$(mailboxId)/decos",
          },
        ],
        suppressResponse: true,
        uri: "/ws/v3/mailboxes/",
      },
    ],
    responseType: "json",
  };
  const api = "https://apis.mail.yahoo.com/ws/v3/batch?appId=" + mailAppId;
  const init: RequestInit = {
    body: JSON.stringify(requestDetails),
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      "X-Oath-YmReqId": getYmreqid(),
    },
    method: "POST",
    signal: abortController.signal,
  };
  try {
    const response = await fetch(api, init);
    const data = await response.json();

    if (!data) {
      return 0;
    }
    const folderID = "FTI";
    const decos: Array<JwsDeco> =
      data?.result?.responses?.[0]?.response?.result?.decos || [];

    const folder = decos.find((deco) => deco?.id === folderID);
    if (!folder) {
      return 0;
    }

    const numUnread = folder?.counts?.[0]?.unseen ?? 0;
    return numUnread;
  } catch (err: any) {
    if (err.name !== "AbortError") {
      console.error("Error in fetching mailcount data:", err);
    }
    return 0;
  }
};
