import { type UrlMapping } from "./types";

export const creatorsUrls: UrlMapping = {
  yahoo: {
    default: "https://creators.yahoo.com/",
    "en-au": "https://creators.yahoo.com/",
    "en-ca": "https://creators.yahoo.com/",
    "en-gb": "https://creators.yahoo.com/",
    "en-sg": "https://creators.yahoo.com/",
    "en-us": "https://creators.yahoo.com",
    "fr-fr": "https://creators.yahoo.com/",
  },
};
