import { type UrlMapping } from "./types";

export const newsUrls: UrlMapping = {
  yahoo: {
    "ar-jo": "https://uk.news.yahoo.com/",
    "de-de": "https://de.nachrichten.yahoo.com/",
    default: "https://www.yahoo.com/news/",
    "el-gr": "https://uk.news.yahoo.com/",
    "en-ae": "https://uk.news.yahoo.com/",
    "en-au": "https://au.news.yahoo.com/",
    "en-ca": "https://ca.news.yahoo.com/",
    "en-gb": "https://uk.news.yahoo.com/",
    "en-ie": "https://uk.news.yahoo.com/",
    "en-in": "https://in.news.yahoo.com/",
    "en-jo": "https://uk.news.yahoo.com/",
    "en-my": "https://malaysia.news.yahoo.com/",
    "en-nz": "https://nz.news.yahoo.com/",
    "en-ph": "https://ph.news.yahoo.com/",
    "en-sg": "https://sg.news.yahoo.com/",
    "en-us": "https://www.yahoo.com/news/",
    "en-za": "https://uk.news.yahoo.com/",
    "es-es": "https://es.noticias.yahoo.com/",
    "es-us": "https://es-us.noticias.yahoo.com/",
    "fr-be": "https://uk.news.yahoo.com/",
    "fr-ca": "https://fr.news.yahoo.com/",
    "fr-fr": "https://fr.news.yahoo.com/",
    "id-id": "https://id.berita.yahoo.com/",
    "it-it": "https://it.notizie.yahoo.com/",
    "nl-be": "https://uk.news.yahoo.com/",
    "pt-br": "https://br.noticias.yahoo.com/",
    "ro-ro": "https://uk.news.yahoo.com/",
    "sv-se": "https://uk.news.yahoo.com/",
    "zh-hant-hk": "https://hk.news.yahoo.com/",
    "zh-hant-tw": "https://tw.news.yahoo.com/",
  },
};
