export interface SideNavChild {
  endTime?: string;
  name: string;
  startTime?: string;
  trailingIcon?: string;
  url: string;
  useTiming?: boolean;
  kids?: SideNavChild[];
  idx?: number;
  subsectionId?: string;
  subNavOpenKey?: string;
  setSubNavOpenKey?: React.Dispatch<React.SetStateAction<string>>;
  setPanelOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  panelOpen?: boolean;
  menuLevel?: number;
  setMenuLevel?: React.Dispatch<React.SetStateAction<number>>;
}

export interface SideNavData {
  kids: SideNavChild[];
  name: string;
  url: string;
  sectionId?: string;
  subNavOpenKey?: string;
  setSubNavOpenKey?: React.Dispatch<React.SetStateAction<string>>;
  setPanelOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  panelOpen?: boolean;
  menuLevel?: number;
  setMenuLevel?: React.Dispatch<React.SetStateAction<number>>;
  defaultOpen?: boolean;
}
interface SidenavCdsResponseData {
  cds: {
    error: null | { code: string; description: string };
    result: {
      cdsData: SideNavData[];
      context: {
        lang: string;
        region: string;
      };
      schemaId: string;
    }[];
  };
}

export const cdsData: SidenavCdsResponseData = {
  cds: {
    error: null,
    result: [
      {
        cdsData: [
          {
            kids: [
              {
                name: "Today's news",
                trailingIcon: "none",
                url: "https://www.yahoo.com/news/",
                useTiming: false,
              },
              {
                name: "US",
                trailingIcon: "none",
                url: "https://www.yahoo.com/news/us/",
                useTiming: false,
              },
              {
                name: "Politics",
                trailingIcon: "none",
                url: "https://www.yahoo.com/news/politics/",
                useTiming: false,
              },
              {
                name: "World",
                trailingIcon: "none",
                url: "https://www.yahoo.com/news/world/",
                useTiming: false,
              },
              {
                kids: [
                  {
                    name: "Reviews and deals",
                    trailingIcon: "none",
                    url: "https://www.yahoo.com/tech/reviews-deals/",
                    useTiming: false,
                  },
                  {
                    name: "Audio",
                    trailingIcon: "none",
                    url: "https://www.yahoo.com/tech/audio/",
                    useTiming: false,
                  },
                  {
                    name: "Computing",
                    trailingIcon: "none",
                    url: "https://www.yahoo.com/tech/computing/",
                    useTiming: false,
                  },
                  {
                    name: "Gaming",
                    trailingIcon: "none",
                    url: "https://www.yahoo.com/tech/gaming/",
                    useTiming: false,
                  },
                  {
                    name: "Health",
                    trailingIcon: "none",
                    url: "https://www.yahoo.com/tech/health/ ",
                    useTiming: false,
                  },
                  {
                    name: "Home",
                    trailingIcon: "none",
                    url: "https://www.yahoo.com/tech/home/ ",
                    useTiming: false,
                  },
                  {
                    name: "Phones",
                    trailingIcon: "none",
                    url: "https://www.yahoo.com/tech/phones/ ",
                    useTiming: false,
                  },
                  {
                    name: "Science",
                    trailingIcon: "none",
                    url: "https://www.yahoo.com/tech/science/ ",
                    useTiming: false,
                  },
                  {
                    name: "TVs",
                    trailingIcon: "none",
                    url: "https://www.yahoo.com/tech/tvs/ ",
                    useTiming: false,
                  },
                ],
                name: "Tech",
                trailingIcon: "none",
                url: "https://www.yahoo.com/tech/",
                useTiming: false,
              },
              {
                name: "Climate change",
                trailingIcon: "none",
                url: "https://www.yahoo.com/tagged/climate-change/",
                useTiming: false,
              },
              {
                name: "Health",
                trailingIcon: "none",
                url: "https://www.yahoo.com/news/health/",
                useTiming: false,
              },
              {
                name: "Science",
                trailingIcon: "none",
                url: "https://www.yahoo.com/news/science/",
                useTiming: false,
              },
              {
                name: "2024 election",
                trailingIcon: "none",
                url: "https://www.yahoo.com/elections/",
                useTiming: false,
              },
              {
                kids: [
                  {
                    name: "The 360",
                    trailingIcon: "none",
                    url: "https://www.yahoo.com/news/tagged/360/",
                    useTiming: false,
                  },
                ],
                name: "Originals",
                trailingIcon: "none",
                url: "https://news.yahoo.com/originals/",
                useTiming: false,
              },
              {
                name: "Newsletters",
                trailingIcon: "none",
                url: "https://news.yahoo.com/newsletters/",
                useTiming: false,
              },
            ],
            name: "News",
            url: "https://www.yahoo.com/",
          },
          {
            kids: [
              {
                kids: [
                  {
                    name: "COVID-19",
                    trailingIcon: "none",
                    url: "https://www.yahoo.com/lifestyle/tagged/covid/",
                    useTiming: false,
                  },
                  {
                    name: "Fall allergies",
                    trailingIcon: "none",
                    url: "https://www.yahoo.com/lifestyle/fall-allergies/",
                    useTiming: false,
                  },
                  {
                    name: "Health news",
                    trailingIcon: "none",
                    url: "https://www.yahoo.com/lifestyle/tagged/do-i-need-to-worry/",
                    useTiming: false,
                  },
                  {
                    name: "Mental health",
                    trailingIcon: "none",
                    url: "https://www.yahoo.com/lifestyle/tagged/mental-health/",
                    useTiming: false,
                  },
                  {
                    name: "Relax",
                    trailingIcon: "none",
                    url: "https://www.yahoo.com/lifestyle/relax/",
                    useTiming: false,
                  },
                  {
                    name: "Sexual health",
                    trailingIcon: "none",
                    url: "https://www.yahoo.com/lifestyle/tagged/sexual-health/",
                    useTiming: false,
                  },
                  {
                    name: "Studies",
                    trailingIcon: "none",
                    url: "https://www.yahoo.com/lifestyle/tagged/study/",
                    useTiming: false,
                  },
                  {
                    name: "The Unwind",
                    trailingIcon: "none",
                    url: "https://www.yahoo.com/lifestyle/tagged/the-unwind/",
                    useTiming: false,
                  },
                ],
                name: "Health",
                trailingIcon: "none",
                url: "https://www.yahoo.com/lifestyle/tagged/health/",
                useTiming: false,
              },
              {
                kids: [
                  {
                    name: "Family health",
                    trailingIcon: "none",
                    url: "https://www.yahoo.com/lifestyle/tagged/family-health/",
                    useTiming: false,
                  },
                  {
                    name: "So mini ways",
                    trailingIcon: "none",
                    url: "https://www.yahoo.com/lifestyle/tagged/so-mini-ways/",
                    useTiming: false,
                  },
                ],
                name: "Parenting",
                trailingIcon: "none",
                url: "https://www.yahoo.com/lifestyle/tagged/parenting/",
                useTiming: false,
              },
              {
                kids: [
                  {
                    name: "It Figures",
                    trailingIcon: "none",
                    url: "https://www.yahoo.com/lifestyle/tagged/it-figures/",
                    useTiming: false,
                  },
                  {
                    name: "Unapologetically",
                    trailingIcon: "none",
                    url: "https://www.yahoo.com/lifestyle/tagged/unapologetically/",
                    useTiming: false,
                  },
                ],
                name: "Style and beauty",
                trailingIcon: "none",
                url: "https://www.yahoo.com/lifestyle/style-beauty/",
                useTiming: false,
              },
              {
                kids: [
                  {
                    kids: [
                      {
                        name: "Animal and pet supplies",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/deals/category/1/animals-and-pet-supplies/",
                        useTiming: false,
                      },
                      {
                        name: "Apparel and accessories",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/deals/category/166/apparel-and-accessories/",
                        useTiming: false,
                      },
                      {
                        name: "Arts and entertainment",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/deals/category/8/arts-and-entertainment/",
                        useTiming: false,
                      },
                      {
                        name: "Baby and kids",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/deals/category/537/baby-and-kids/",
                        useTiming: false,
                      },
                      {
                        name: "Cameras",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/deals/category/141/cameras/",
                        useTiming: false,
                      },
                      {
                        name: "Electronics",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/deals/category/222/electronics/",
                        useTiming: false,
                      },
                      {
                        name: "Furniture",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/deals/category/436/furniture/",
                        useTiming: false,
                      },
                      {
                        name: "Hardware",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/deals/category/632/hardware/",
                        useTiming: false,
                      },
                      {
                        name: "Health and beauty",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/deals/category/469/health-and-beauty/",
                        useTiming: false,
                      },
                      {
                        name: "Home essentials",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/deals/category/536/home-and-garden/",
                        useTiming: false,
                      },
                      {
                        name: "Local services",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/deals/category/606000/local-services/",
                        useTiming: false,
                      },
                      {
                        name: "Luggage and bags",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/deals/category/5181/luggage-and-bags/",
                        useTiming: false,
                      },
                      {
                        name: "Office supplies",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/deals/category/922/office-supplies/",
                        useTiming: false,
                      },
                      {
                        name: "Software",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/deals/category/2092/software/",
                        useTiming: false,
                      },
                      {
                        name: "Things to do",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/deals/category/607000/things-to-do/",
                        useTiming: false,
                      },
                      {
                        name: "Toys and games",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/deals/category/1239/toys-and-games/",
                        useTiming: false,
                      },
                      {
                        name: "Vehicles and parts",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/deals/category/888/vehicles-and-part/",
                        useTiming: false,
                      },
                    ],
                    name: "Deals",
                    trailingIcon: "none",
                    url: "https://shopping.yahoo.com/popular-deals/",
                    useTiming: false,
                  },
                  {
                    name: "Stores",
                    trailingIcon: "none",
                    url: "https://shopping.yahoo.com/stores/",
                    useTiming: false,
                  },
                  {
                    kids: [
                      {
                        name: "Automative",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/category/888/Automotive?orderBy=DESC&sortBy=popularity",
                        useTiming: false,
                      },
                      {
                        name: "Babies and kids",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/category/537/Babies-and-Kids?orderBy=DESC&sortBy=popularity",
                        useTiming: false,
                      },
                      {
                        name: "Cameras",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/category/141/Cameras?orderBy=DESC&sortBy=popularity",
                        useTiming: false,
                      },
                      {
                        name: "Clothing",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/category/2271/Clothing?orderBy=DESC&sortBy=popularity",
                        useTiming: false,
                      },
                      {
                        name: "Computers",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/category/278/Computers?orderBy=DESC&sortBy=popularity",
                        useTiming: false,
                      },
                      {
                        name: "DVDs and videos",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/category/839/DVDs-and-Videos?orderBy=DESC&sortBy=popularity",
                        useTiming: false,
                      },
                      {
                        name: "Electronics",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/category/222/Electronics?orderBy=DESC&sortBy=popularity",
                        useTiming: false,
                      },
                      {
                        name: "Furniture",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/category/436/Furniture?orderBy=DESC&sortBy=popularity",
                        useTiming: false,
                      },
                      {
                        name: "Food",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/category/422/Food?orderBy=DESC&sortBy=popularity",
                        useTiming: false,
                      },
                      {
                        name: "Handbags and wallets",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/category/6551/Handbags-and-Wallets?orderBy=DESC&sortBy=popularity",
                        useTiming: false,
                      },
                      {
                        name: "Health and beauty",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/category/469/Health-and-Beauty?orderBy=DESC&sortBy=popularity",
                        useTiming: false,
                      },
                      {
                        name: "Home essentials",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/category/469/Health-and-Beauty?orderBy=DESC&sortBy=popularity",
                        useTiming: false,
                      },
                      {
                        name: "Jewelry",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/category/188/Jewelry?orderBy=DESC&sortBy=popularity",
                        useTiming: false,
                      },
                      {
                        name: "Household appliances",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/category/604/Household-Appliances?orderBy=DESC&sortBy=popularity",
                        useTiming: false,
                      },
                      {
                        name: "Kitchen appliances",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/category/730/Kitchen-Appliances?orderBy=DESC&sortBy=popularity",
                        useTiming: false,
                      },
                      {
                        name: "Music",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/category/855/Music?orderBy=DESC&sortBy=popularity",
                        useTiming: false,
                      },
                      {
                        name: "Office supplies",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/category/922/Office-Supplies?orderBy=DESC&sortBy=popularity",
                        useTiming: false,
                      },
                      {
                        name: "Pets",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/category/1/Pets?orderBy=DESC&sortBy=popularity",
                        useTiming: false,
                      },
                      {
                        name: "Sports and outdoors",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/category/988/Sports-and-Outdoor?orderBy=DESC&sortBy=popularity",
                        useTiming: false,
                      },
                      {
                        name: "Shoes",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/category/187/Shoes?orderBy=DESC&sortBy=popularity",
                        useTiming: false,
                      },
                      {
                        name: "Toys and games",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/category/1239/Toys-and-Games?orderBy=DESC&sortBy=popularity",
                        useTiming: false,
                      },
                      {
                        name: "Video game consoles",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/category/1294/Video-Game-Consoles?orderBy=DESC&sortBy=popularity",
                        useTiming: false,
                      },
                      {
                        name: "Video game console accessories",
                        trailingIcon: "none",
                        url: "https://shopping.yahoo.com/category/1270/Video-Game-Console-Accessories?orderBy=DESC&sortBy=popularity",
                        useTiming: false,
                      },
                    ],
                    name: "Categories",
                    trailingIcon: "none",
                    url: "",
                    useTiming: false,
                  },
                ],
                name: "Shopping",
                trailingIcon: "none",
                url: "https://shopping.yahoo.com/",
                useTiming: false,
              },
              {
                name: "Food",
                trailingIcon: "none",
                url: "https://www.yahoo.com/lifestyle/tagged/food/",
                useTiming: false,
              },
              {
                name: "Travel",
                trailingIcon: "none",
                url: "https://www.yahoo.com/lifestyle/tagged/travel/",
                useTiming: false,
              },
              {
                name: "Autos",
                trailingIcon: "none",
                url: "https://autos.yahoo.com/",
                useTiming: false,
              },
              {
                name: "Black Friday",
                trailingIcon: "none",
                url: "https://www.yahoo.com/lifestyle/black-friday-2024-everything-you-need-to-know-and-the-best-early-deals-to-shop-144212873.html",
                useTiming: false,
              },
              {
                name: "Cyber Monday",
                trailingIcon: "none",
                url: "https://www.yahoo.com/topics/cyber-monday/",
                useTiming: false,
              },
              {
                name: "Gift ideas",
                trailingIcon: "none",
                url: "https://www.yahoo.com/topics/gift-ideas/",
                useTiming: false,
              },
              {
                name: "Buying guides",
                trailingIcon: "none",
                url: "https://www.yahoo.com/topics/buying-guides/",
                useTiming: false,
              },
            ],
            name: "Life",
            url: "https://www.yahoo.com/lifestyle/",
          },
          {
            kids: [
              {
                name: "Celebrity",
                trailingIcon: "none",
                url: "https://www.yahoo.com/entertainment/celebrity/",
                useTiming: false,
              },
              {
                name: "TV",
                trailingIcon: "none",
                url: "https://www.yahoo.com/entertainment/tv/",
                useTiming: false,
              },
              {
                name: "Movies",
                trailingIcon: "none",
                url: "https://www.yahoo.com/entertainment/movies/",
                useTiming: false,
              },
              {
                name: "Music",
                trailingIcon: "none",
                url: "https://www.yahoo.com/entertainment/music/",
                useTiming: false,
              },
              {
                name: "How to Watch",
                trailingIcon: "none",
                url: "https://www.yahoo.com/entertainment/tagged/how-to-watch/",
                useTiming: false,
              },
              {
                name: "Interviews",
                trailingIcon: "none",
                url: "https://www.yahoo.com/entertainment/tagged/interviews/",
                useTiming: false,
              },
              {
                name: "Videos",
                trailingIcon: "none",
                url: "https://www.yahoo.com/entertainment/tagged/videos/",
                useTiming: false,
              },
            ],
            name: "Entertainment",
            url: "https://www.yahoo.com/entertainment/",
          },
          {
            kids: [
              {
                kids: [
                  {
                    name: "My watchlist",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/portfolio/p_1/view/v1",
                    useTiming: false,
                  },
                ],
                name: "My portfolio",
                trailingIcon: "none",
                url: "https://finance.yahoo.com/portfolios/",
                useTiming: false,
              },
              {
                kids: [
                  {
                    name: "Stock market",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/topic/stock-market-news/",
                    useTiming: false,
                  },
                  {
                    name: "Economics",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/topic/economic-news/",
                    useTiming: false,
                  },
                  {
                    name: "Earnings",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/topic/earnings/",
                    useTiming: false,
                  },
                  {
                    name: "Crypto",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/topic/crypto/",
                    useTiming: false,
                  },
                  {
                    name: "Politics",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/live/politics/",
                    useTiming: false,
                  },
                  {
                    name: "Biden economy",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/bidenomics/",
                    useTiming: false,
                  },
                  {
                    name: "Personal finance",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/topic/personal-finance-news/",
                    useTiming: false,
                  },
                ],
                name: "News",
                trailingIcon: "none",
                url: "https://finance.yahoo.com/news/",
                useTiming: false,
              },
              {
                kids: [
                  {
                    name: "Stocks: most active",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/most-active/",
                    useTiming: false,
                  },
                  {
                    name: "Stocks: gainers",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/gainers/",
                    useTiming: false,
                  },
                  {
                    name: "Stocks: losers",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/losers/",
                    useTiming: false,
                  },
                  {
                    name: "Trending tickers",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/trending-tickers/",
                    useTiming: false,
                  },
                  {
                    name: "Futures",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/commodities/",
                    useTiming: false,
                  },
                  {
                    name: "World indices",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/world-indices/",
                    useTiming: false,
                  },
                  {
                    name: "US Treasury bonds",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/bonds/",
                    useTiming: false,
                  },
                  {
                    name: "Currencies",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/currencies/",
                    useTiming: false,
                  },
                  {
                    name: "Crypto",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/crypto/",
                    useTiming: false,
                  },
                  {
                    name: "Top ETFs",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/etfs/",
                    useTiming: false,
                  },
                  {
                    name: "Top mutual funds",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/mutualfunds",
                    useTiming: false,
                  },
                  {
                    name: "Highest open interest",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/options/highest-open-interest/",
                    useTiming: false,
                  },
                  {
                    name: "Highest implied volatility",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/options/highest-implied-volatility/",
                    useTiming: false,
                  },
                  {
                    name: "Currency converter",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/currency-converter/",
                    useTiming: false,
                  },
                ],
                name: "Markets",
                trailingIcon: "none",
                url: "https://finance.yahoo.com/calendar/",
                useTiming: false,
              },
              {
                kids: [
                  {
                    name: "Basic materials",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/sectors/basic-materials/",
                    useTiming: false,
                  },
                  {
                    name: "Communication services",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/sectors/communication-services/",
                    useTiming: false,
                  },
                  {
                    name: "Consumer cyclical",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/sectors/consumer-cyclical/",
                    useTiming: false,
                  },
                  {
                    name: "Consumer defensive",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/sectors/consumer-defensive/",
                    useTiming: false,
                  },
                  {
                    name: "Energy",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/sectors/energy/",
                    useTiming: false,
                  },
                  {
                    name: "Financial services",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/sectors/financial-services/",
                    useTiming: false,
                  },
                  {
                    name: "Healthcare",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/sectors/healthcare/",
                    useTiming: false,
                  },
                  {
                    name: "Industrials",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/sectors/industrials/",
                    useTiming: false,
                  },
                  {
                    name: "Real estate",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/sectors/real-estate/",
                    useTiming: false,
                  },
                  {
                    name: "Technology",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/sectors/technology/",
                    useTiming: false,
                  },
                  {
                    name: "Utilities",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/sectors/utilities/",
                    useTiming: false,
                  },
                ],
                name: "Sectors",
                trailingIcon: "none",
                url: "https://finance.yahoo.com/sectors/",
                useTiming: false,
              },
              {
                kids: [
                  {
                    name: "Watchlists",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/watchlists/",
                    useTiming: false,
                  },
                  {
                    name: "Equities",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/screener/new/",
                    useTiming: false,
                  },
                  {
                    name: "ETFs",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/screener/etf/new/",
                    useTiming: false,
                  },
                  {
                    name: "Futures",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/screener/future/new/",
                    useTiming: false,
                  },
                  {
                    name: "Index",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/screener/index/new/",
                    useTiming: false,
                  },
                  {
                    name: "Mutual funds",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/screener/mutualfund/new/",
                    useTiming: false,
                  },
                  {
                    name: "Analyst rating screener ",
                    trailingIcon: "y+",
                    url: "https://finance.yahoo.com/screener/analyst_ratings/new?ncid=dcm_320339942_490172245_127172993",
                    useTiming: false,
                  },
                  {
                    name: "Technical events screener",
                    trailingIcon: "y+",
                    url: "https://finance.yahoo.com/screener/tradingcentral_event/new?ncid=dcm_320544712_490172245_127172993",
                    useTiming: false,
                  },
                  {
                    name: "Smart money screener",
                    trailingIcon: "y+",
                    url: "https://finance.yahoo.com/screener/institutional_interest/new?ncid=dcm_320344326_490172245_127172993",
                    useTiming: false,
                  },
                  {
                    name: "Top holdings screener",
                    trailingIcon: "y+",
                    url: "https://finance.yahoo.com/screener/institutional_holdings/new?ncid=dcm_320545006_490172245_127172993",
                    useTiming: false,
                  },
                ],
                name: "Screeners",
                trailingIcon: "none",
                url: "https://finance.yahoo.com/screener/",
                useTiming: false,
              },
              {
                kids: [
                  {
                    name: "Credit cards",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/personal-finance/credit-cards/",
                    useTiming: false,
                  },
                  {
                    name: "Credit card rates",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/personal-finance/best-credit-card-interest-rates-193519877.html",
                    useTiming: false,
                  },
                  {
                    name: "Balance transfer credit cards",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/personal-finance/best-credit-card-for-balance-transfer-213356438.html",
                    useTiming: false,
                  },
                  {
                    name: "Business credit cards",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/personal-finance/best-business-credit-cards-181530428.html",
                    useTiming: false,
                  },
                  {
                    name: "Cash back credit cards",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/personal-finance/best-cash-back-credit-cards-220845639.html",
                    useTiming: false,
                  },
                  {
                    name: "Rewards credit cards",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/personal-finance/best-rewards-credit-card-203736692.html",
                    useTiming: false,
                  },
                  {
                    name: "Travel credit cards",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/personal-finance/best-travel-credit-card-203950032.html",
                    useTiming: false,
                  },
                  {
                    name: "CD rates",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/personal-finance/find-best-cd-rates-165749654.html",
                    useTiming: false,
                  },
                  {
                    name: "Checking accounts",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/personal-finance/find-best-checking-accounts-234120614.html",
                    useTiming: false,
                  },
                  {
                    name: "Online checking accounts",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/personal-finance/best-online-checking-account-180614848.html",
                    useTiming: false,
                  },
                  {
                    name: "High-yield savings accounts",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/personal-finance/best-high-yield-savings-account-rates-203648059.html",
                    useTiming: false,
                  },
                  {
                    name: "Money market accounts",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/personal-finance/high-yield-money-market-accounts-170614474.html",
                    useTiming: false,
                  },
                  {
                    name: "Personal loans",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/personal-finance/best-personal-loans-202034980.html",
                    useTiming: false,
                  },
                  {
                    name: "Student loans",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/personal-finance/are-federal-or-private-loans-better-175200294.html",
                    useTiming: false,
                  },
                  {
                    name: "Car insurance",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/personal-finance/best-personal-loans-202034980.html",
                    useTiming: false,
                  },
                  {
                    name: "Home buying",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/personal-finance/how-to-buy-a-house-182936386.html",
                    useTiming: false,
                  },
                  {
                    name: "Taxes",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/taxes/",
                    useTiming: false,
                  },
                ],
                name: "Personal finance",
                trailingIcon: "none",
                url: "https://finance.yahoo.com/topic/personal-finance/",
                useTiming: false,
              },
              {
                kids: [
                  {
                    name: "ETF report",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/topic/etf-report/",
                    useTiming: false,
                  },
                  {
                    name: "FA corner",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/topic/fa-corner/",
                    useTiming: false,
                  },
                  {
                    name: "Options pit",
                    trailingIcon: "none",
                    url: "https://finance.yahoo.com/topic/options-pit/",
                    useTiming: false,
                  },
                ],
                name: "Videos",
                trailingIcon: "none",
                url: "https://finance.yahoo.com/videos/",
                useTiming: false,
              },
              {
                name: "Crypto",
                trailingIcon: "none",
                url: "https://finance.yahoo.com/crypto/",
                useTiming: false,
              },
              {
                name: "Industries",
                trailingIcon: "none",
                url: "https://finance.yahoo.com/screener/predefined/ms_basic_materials/",
                useTiming: false,
              },
            ],
            name: "Finance",
            url: "https://finance.yahoo.com/",
          },
          {
            kids: [
              {
                kids: [
                  {
                    name: "News",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/fantasy/news/",
                    useTiming: false,
                  },
                  {
                    name: "Fantasy football",
                    trailingIcon: "none",
                    url: "https://football.fantasysports.yahoo.com/",
                    useTiming: false,
                  },
                  {
                    name: "Best ball",
                    trailingIcon: "none",
                    url: "https://bestball.fantasysports.yahoo.com/",
                    useTiming: false,
                  },
                  {
                    name: "Pro Pick 'Em",
                    trailingIcon: "none",
                    url: "https://football.fantasysports.yahoo.com/pickem",
                    useTiming: false,
                  },
                  {
                    name: "College Pick 'Em",
                    trailingIcon: "none",
                    url: "https://football.fantasysports.yahoo.com/college",
                    useTiming: false,
                  },
                  {
                    name: "Fantasy baseball",
                    trailingIcon: "none",
                    url: "https://baseball.fantasysports.yahoo.com/",
                    useTiming: false,
                  },
                  {
                    name: "Fantasy hockey",
                    trailingIcon: "none",
                    url: "https://hockey.fantasysports.yahoo.com/",
                    useTiming: false,
                  },
                  {
                    name: "Fantasy basketball",
                    trailingIcon: "none",
                    url: "https://basketball.fantasysports.yahoo.com/",
                    useTiming: false,
                  },
                  {
                    name: "Download the app",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/fantasy/mobile/",
                    useTiming: false,
                  },
                ],
                name: "Fantasy",
                trailingIcon: "none",
                url: "https://sports.yahoo.com/fantasy/",
                useTiming: false,
              },
              {
                name: "Daily fantasy",
                trailingIcon: "none",
                url: "https://sports.yahoo.com/dailyfantasy/",
                useTiming: false,
              },
              {
                kids: [
                  {
                    name: "News",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nfl/news/",
                    useTiming: false,
                  },
                  {
                    name: "Scores and schedules",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nfl/scoreboard/",
                    useTiming: false,
                  },
                  {
                    name: "Standings",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nfl/standings/",
                    useTiming: false,
                  },
                  {
                    name: "Stats",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nfl/stats/",
                    useTiming: false,
                  },
                  {
                    name: "Teams",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nfl/teams/",
                    useTiming: false,
                  },
                  {
                    name: "Players",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nfl/players/",
                    useTiming: false,
                  },
                  {
                    name: "Drafts",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nfl/draft/",
                    useTiming: false,
                  },
                  {
                    name: "Injuries",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nfl/injuries/",
                    useTiming: false,
                  },
                  {
                    name: "Odds",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nfl/odds/",
                    useTiming: false,
                  },
                  {
                    name: "Super Bowl",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nfl/topic/super-bowl/",
                    useTiming: false,
                  },
                  {
                    name: "GameChannel",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nfl/gamechannel/",
                    useTiming: false,
                  },
                  {
                    name: "Videos",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/videos/nfl/",
                    useTiming: false,
                  },
                ],
                name: "NFL",
                trailingIcon: "none",
                url: "https://sports.yahoo.com/nfl/",
                useTiming: false,
              },
              {
                kids: [
                  {
                    name: "News",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/mlb/news/",
                    useTiming: false,
                  },
                  {
                    name: "Scores and schedules",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/mlb/scoreboard/",
                    useTiming: false,
                  },
                  {
                    name: "Standings",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/mlb/standings/",
                    useTiming: false,
                  },
                  {
                    name: "Stats",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/mlb/stats/",
                    useTiming: false,
                  },
                  {
                    name: "Teams",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/mlb/teams/",
                    useTiming: false,
                  },
                  {
                    name: "Players",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/mlb/players/",
                    useTiming: false,
                  },
                  {
                    name: "Odds",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/mlb/odds/",
                    useTiming: false,
                  },
                  {
                    name: "Videos",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/videos/mlb/",
                    useTiming: false,
                  },
                  {
                    name: "World Baseball Classic",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/mlb/world-baseball-classic/",
                    useTiming: false,
                  },
                ],
                name: "MLB",
                trailingIcon: "none",
                url: "https://sports.yahoo.com/mlb/",
                useTiming: false,
              },
              {
                kids: [
                  {
                    name: "News",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nba/news/",
                    useTiming: false,
                  },
                  {
                    name: "Draft",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nba/draft/",
                    useTiming: false,
                  },
                  {
                    name: "Scores and schedules",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nba/scoreboard/",
                    useTiming: false,
                  },
                  {
                    name: "Standings",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nba/standings/",
                    useTiming: false,
                  },
                  {
                    name: "Stats",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nba/stats/",
                    useTiming: false,
                  },
                  {
                    name: "Teams",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nba/teams/",
                    useTiming: false,
                  },
                  {
                    name: "Players",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nba/players/",
                    useTiming: false,
                  },
                  {
                    name: "Inuries",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nba/injuries/",
                    useTiming: false,
                  },
                  {
                    name: "Videos",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/videos/nba/",
                    useTiming: false,
                  },
                  {
                    name: "Odds",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nba/odds/",
                    useTiming: false,
                  },
                  {
                    name: "Playoffs",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nba/playoffs/",
                    useTiming: false,
                  },
                ],
                name: "NBA",
                trailingIcon: "none",
                url: "https://sports.yahoo.com/nba/",
                useTiming: false,
              },
              {
                kids: [
                  {
                    name: "News",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nhl/news/",
                    useTiming: false,
                  },
                  {
                    name: "Scores and schedules",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nhl/scoreboard/",
                    useTiming: false,
                  },
                  {
                    name: "Standings",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nhl/standings/",
                    useTiming: false,
                  },
                  {
                    name: "Stats",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nhl/stats/",
                    useTiming: false,
                  },
                  {
                    name: "Teams",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nhl/teams/",
                    useTiming: false,
                  },
                  {
                    name: "Players",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nhl/players/",
                    useTiming: false,
                  },
                  {
                    name: "Odds",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nhl/odds/",
                    useTiming: false,
                  },
                  {
                    name: "Playoffs",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/nhl/stanley-cup-playoffs/",
                    useTiming: false,
                  },
                ],
                name: "NHL",
                trailingIcon: "none",
                url: "https://sports.yahoo.com/nhl/",
                useTiming: false,
              },
              {
                kids: [
                  {
                    name: "News",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/soccer/news/",
                    useTiming: false,
                  },
                  {
                    name: "Scores and schedules",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/soccer/scoreboard/",
                    useTiming: false,
                  },
                  {
                    name: "Premier League",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/soccer/premier-league/",
                    useTiming: false,
                  },
                  {
                    name: "MLS",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/soccer/mls/",
                    useTiming: false,
                  },
                  {
                    name: "NWSL",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/soccer/nwsl/",
                    useTiming: false,
                  },
                  {
                    name: "Liga MX",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/soccer/ligamx-clausura/",
                    useTiming: false,
                  },
                  {
                    name: "CONCACAF League",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/soccer/concacaf-league/",
                    useTiming: false,
                  },
                  {
                    name: "Champions League",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/soccer/champions-league/",
                    useTiming: false,
                  },
                  {
                    name: "La Liga",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/soccer/la-liga/",
                    useTiming: false,
                  },
                  {
                    name: "Serie A",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/soccer/serie-a/",
                    useTiming: false,
                  },
                  {
                    name: "Bundesliga",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/soccer/bundesliga/",
                    useTiming: false,
                  },
                  {
                    name: "Ligue 1",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/soccer/ligue-1/",
                    useTiming: false,
                  },
                  {
                    name: "World Cup",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/soccer/world-cup/",
                    useTiming: false,
                  },
                ],
                name: "Soccer",
                trailingIcon: "none",
                url: "https://sports.yahoo.com/soccer/",
                useTiming: false,
              },
              {
                kids: [
                  {
                    name: "News",
                    trailingIcon: "none",
                    url: "https://https://sports.yahoo.com/college-football/news/",
                    useTiming: false,
                  },
                  {
                    name: "Scores and schedules",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/college-football/scoreboard/",
                    useTiming: false,
                  },
                  {
                    name: "Standings",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/college-football/standings/",
                    useTiming: false,
                  },
                  {
                    name: "Rankings",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/college-football/rankings/",
                    useTiming: false,
                  },
                  {
                    name: "Stats",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/college-football/stats/",
                    useTiming: false,
                  },
                  {
                    name: "Teams",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/college-football/teams/",
                    useTiming: false,
                  },
                ],
                name: "College football",
                trailingIcon: "none",
                url: "https://sports.yahoo.com/college-football/",
                useTiming: false,
              },
              {
                kids: [
                  {
                    name: "MMA",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/mma/",
                    useTiming: false,
                  },
                  {
                    name: "WNBA",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/wnba/",
                    useTiming: false,
                  },
                  {
                    name: "Sportsbook",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/sportsbook/",
                    useTiming: false,
                  },
                  {
                    name: "NCAAF",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/college-football/",
                    useTiming: false,
                  },
                  {
                    name: "Tennis",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/tennis/",
                    useTiming: false,
                  },
                  {
                    name: "Golf",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/golf/",
                    useTiming: false,
                  },
                  {
                    name: "NASCAR",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/motorsports/nascar/",
                    useTiming: false,
                  },
                  {
                    name: "NCAAB",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/college-basketball/",
                    useTiming: false,
                  },
                  {
                    name: "NCAAW",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/college-womens-basketball/",
                    useTiming: false,
                  },
                  {
                    name: "Boxing",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/boxing/",
                    useTiming: false,
                  },
                  {
                    name: "USFL",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/usfl/",
                    useTiming: false,
                  },
                  {
                    name: "Cycling",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/cycling/",
                    useTiming: false,
                  },
                  {
                    name: "Motorsports",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/motorsports/",
                    useTiming: false,
                  },
                  {
                    name: "Olympics",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/olympics/beijing-2022/",
                    useTiming: false,
                  },
                  {
                    name: "Horse racing",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/horse-racing/",
                    useTiming: false,
                  },
                  {
                    name: "GameChannel",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/mlb/gamechannel/",
                    useTiming: false,
                  },
                  {
                    name: "Rivals",
                    trailingIcon: "none",
                    url: "https://n.rivals.com/",
                    useTiming: false,
                  },
                  {
                    name: "Newsletters",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/newsletters/",
                    useTiming: false,
                  },
                  {
                    name: "Podcasts",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/podcasts/",
                    useTiming: false,
                  },
                  {
                    name: "Videos",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/videos/",
                    useTiming: false,
                  },
                  {
                    name: "RSS",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/syndication/",
                    useTiming: false,
                  },
                  {
                    name: "Jobs",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/jobs/",
                    useTiming: false,
                  },
                  {
                    name: "Help",
                    trailingIcon: "none",
                    url: "https://help.yahoo.com/kb/sports-news",
                    useTiming: false,
                  },
                  {
                    name: "World Cup",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/soccer/world-cup/",
                    useTiming: false,
                  },
                  {
                    name: "More news",
                    trailingIcon: "none",
                    url: "https://sports.yahoo.com/news/",
                    useTiming: false,
                  },
                ],
                name: "Show all",
                trailingIcon: "none",
                url: "https://sports.yahoo.com/",
                useTiming: false,
              },
            ],
            name: "Sports",
            url: "https://sports.yahoo.com/",
          },
          {
            kids: [
              {
                name: "Creators",
                trailingIcon: "none",
                url: "https://creators.yahoo.com/",
                useTiming: false,
              },
              {
                name: "Games",
                trailingIcon: "none",
                url: "https://www.yahoo.com/games",
                useTiming: false,
              },
              {
                name: "Tech",
                trailingIcon: "none",
                url: "https://www.yahoo.com/tech",
                useTiming: false,
              },
            ],
            name: "New on Yahoo",
            url: "",
          },
        ],
        context: {
          lang: "en-US",
          region: "US",
        },
        schemaId: "uh:sidenav",
      },
    ],
  },
};
