import { creatorsUrls } from "./creators-urls";
import { entertainmentUrls } from "./entertainment-urls";
import { frontpageUrls } from "./frontpage-urls";
import { lifestyleUrls } from "./lifestyle-urls";
import { localUrls } from "./local-urls";
import { mailUrls } from "./mail-urls";
import { newsUrls } from "./news-urls";
import { shoppingUrls } from "./shopping-urls";
import { sportsUrls } from "./sports-urls";
import { techUrls } from "./tech-urls";
import { type UrlMapping } from "./types";

// TODO: Add partner support. Defaulting to yahoo for now
const findUrl = (
  urls: UrlMapping,
  site: string,
  lang: string,
): string | undefined => {
  if (urls["yahoo"]) {
    return urls["yahoo"][lang] || urls["yahoo"]["default"] || undefined;
  }
  return undefined;
};

export function getSiteUrl(
  site: string,
  lang: string,
  partner: string,
): string {
  lang = lang.toLowerCase();
  const defaultUrl = "https://www.yahoo.com/news/";
  let url;
  if (!partner) {
    partner = "none";
  }
  if (partner !== "none") {
    switch (partner) {
      case "att":
        url =
          lang === "es-us"
            ? "https://envivo.att.yahoo.com/"
            : "https://currently.att.yahoo.com/";
        break;
    }
  } else {
    switch (site) {
      case "creators":
        url = findUrl(creatorsUrls, site, lang);
        break;
      case "entertainment":
        url = findUrl(entertainmentUrls, site, lang);
        break;
      case "frontpage":
        url = findUrl(frontpageUrls, site, lang);
        break;
      case "lifestyle":
        url = findUrl(lifestyleUrls, site, lang);
        break;
      case "local":
        url = findUrl(localUrls, site, lang);
        break;
      case "mail":
        url = findUrl(mailUrls, site, lang);
        break;
      case "news":
        url = findUrl(newsUrls, site, lang);
        break;
      case "shopping":
        url = findUrl(shoppingUrls, site, lang);
        break;
      case "sports":
        url = findUrl(sportsUrls, site, lang);
        break;
      case "tech":
        url = findUrl(techUrls, site, lang);
        break;
    }
  }

  return url || defaultUrl;
}
