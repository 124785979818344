import { I13nAnchor } from "@yahoo-creators/i13n";
import classNames from "classnames";
import { type FC } from "react";

interface Props {
  links?: Array<object>;
  name?: string;
  url?: string;
}

export const MoreMenuColumn: FC<Props> = (props) => {
  const isNewOnYahoo = props.name !== "New on Yahoo" ? false : true;
  return (
    <li
      role="group"
      className={classNames(
        "w-[156px] text-sm font-bold leading-5 xl:w-[184px]",
        { "row-span-full": !isNewOnYahoo },
      )}
    >
      {!isNewOnYahoo && (
        <I13nAnchor
          dataYlk={{
            elm: "navcat",
            itc: 0,
            pkgt: "top",
            sec: "ybar",
            slk: props.name,
            subsec: "more",
          }}
          href={props.url}
          className="mb-1 flex border-b-2 border-transparent px-1 pb-[7px] font-bold text-batcave hover:border-b-grape-jelly hover:text-grape-jelly focus-visible:rounded-sm focus-visible:border-b-grape-jelly focus-visible:text-grape-jelly focus-visible:outline focus-visible:outline-4 focus-visible:outline-offset-4 focus-visible:outline-[rgb(96_1_210/50%)]"
          role="menuitem"
        >
          {props.name}
        </I13nAnchor>
      )}

      {isNewOnYahoo && (
        <div className="mb-1 flex cursor-default border-b-2 border-transparent px-1 pb-[7px] font-bold text-batcave">
          {props.name}
        </div>
      )}

      <ul className="flex list-none flex-col items-start gap-2 p-0">
        {props.links?.map((link: any, idx: number) => {
          return (
            <li
              key={idx}
              role="menuitem"
              className="group/itemlink h-7 w-full hover:bg-marshmallow has-[:focus-visible]:rounded-sm has-[:focus-visible]:bg-marshmallow has-[:focus-visible]:outline has-[:focus-visible]:outline-4 has-[:focus-visible]:outline-[rgb(96_1_210/50%)]"
            >
              <I13nAnchor
                dataYlk={{
                  elm: "navcat",
                  itc: 0,
                  pkgt: "top",
                  sec: "ybar",
                  slk: link.name,
                  subsec: "more",
                }}
                href={link.url}
                className="flex items-center whitespace-nowrap p-1 text-sm font-normal text-batcave outline-none focus-visible:text-grape-jelly group-hover/itemlink:text-grape-jelly"
              >
                {link.name}
              </I13nAnchor>
            </li>
          );
        })}
      </ul>
    </li>
  );
};
